import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import PDF from "../../assets/img/theme/pdf.png";
import XLS from "../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useState } from "react";
import CustomTable from "components/table/CustomTable";
const CandidateReports = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  const theading = [
    "ID",
    "CENTER NAME",
    "CODE",
    "STATE",
    "CITY",
    "EXAM",
    "EXAM DATE",
    "ISSUE TYPE",
    "LATITUDE",
    "LONGITUDE",
    "COMMENTS",
    "OBSERVER NAME",
    "VALIDATED DATE",
  ];
  const tdata = [
    [
      457,
      "NATIONAL ENGLISH SCHOOL",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Date",
    ],
  ];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Dashboard
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Candidate Reports
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button className="p-0">
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button className="p-0">
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* filter */}
        <Card className="shadow p-3 mb-3">
          <Row>
            <Col xl="2">
              <FormGroup className="mb-0">
                <Label>Exam Center</Label>
                <Input name="select" type="select">
                  <option>Select Exam Name</option>
                  <option>Examination Venue Lucknow - SICP</option>
                  <option>Examination Venue Lucknow - ASI</option>
                  <option>UPPRB </option>
                  <option>Demo</option>
                  <option>Aptech - CBSE</option>
                  <option>RRC </option>
                  <option> CBSE-Aptech-KVS</option>
                  <option>NWDA</option>
                  <option>FTII</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-0">
                <Label>Observer</Label>
                <Input name="select" type="select"></Input>
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-0">
                <Label>Exam</Label>
                <Input name="select" type="select"></Input>
              </FormGroup>
            </Col>
            <Col xl="3 ">
              <FormGroup className="mb-0">
                <Label>Incedent</Label>
                <Input name="select" type="select"></Input>
              </FormGroup>
            </Col>
            <Col xl="3">
              <div className="btn-container d-flex align-items-end justify-content-end h-100">
                <Button>filter</Button>
                <Button>Clear</Button>
              </div>
            </Col>
          </Row>
        </Card>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0">
                <h3 className="mb-0">Card tables</h3>
              </CardHeader>
              <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
                showComment
              />
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Form>
          <FormGroup>
            <Label>User Role*</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>First Name*</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Last Name</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Mobile*</Label>
            <Input type="number" />
          </FormGroup>
          <FormGroup>
            <Label>Password*</Label>
            <Input type="password" />
          </FormGroup>
          <FormGroup>
            <Label>Status</Label>
            <Input type="switch" />
          </FormGroup>
          <FormGroup>
            <Label>User Profile Image</Label>
            <Input
              type="file"
              onChange={(e) => handleFile(e.target?.files[0])}
            />
            {data && <img src={data} width={200} className="p-3" alt="" />}
          </FormGroup>
        </Form>
      </CustomModal>
    </>
  );
};

export default CandidateReports;
