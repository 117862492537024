import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  Table,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// core components

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";
import CustomTable from "components/table/CustomTable";
import DataService from "../../../services/requestApi";
import PaginationData from "../Pagination";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";

const InvigilatorValidation = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const [rowValue, setRowValue] = useState();
  const [viewData, setViewData] = useState(false);
  const [isEditData, setIsEditData] = useState(false);
  const [users, setUsers] = useState([]);
  const [examCenter, setExamCenter] = useState([]);
  const [examDepartment, setExamDepartment] = useState([]);
  const [allInvigilatorValidation, setAllInvigilatorValidation] = useState([]);
  const [selectUser, setSelectUser] = useState("");
  const [selectExamCenter, setSelectExamCenter] = useState("");
  const [selectExamCategory, setSelectExamCategory] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showPrePage] = useState(10);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  const theading = ["ID", "	USER", "EXAM LIST", "EXAM CENTER"];

  useEffect(() => {
    getUsers();
    getExamCenter();
    getExamDepartment();
    getAllInvigilatorValidation();
  }, []);

  const LoginSchema = Yup.object().shape({
    user_id: Yup.string().required("required"),
    exam_center_id: Yup.string().required("required"),
    exam_category_id: Yup.string().required("required"),
    mobile: Yup.number().required("required"),
    aadhaar_id: Yup.string().required("required"),
    pan: Yup.string().required("required"),
    lab_number: Yup.string().required("required"),
    comments: Yup.string().required("required"),
    location_address: Yup.string().required("required"),
    location_latitude: Yup.string().required("required"),
    location_longitude: Yup.string().required("required"),
    location_longitude: Yup.string().required("required"),
  });
  const getUsers = async () => {
    const { data } = await DataService.GetUsers();
    setUsers(data.users);
  };
  const getExamCenter = async () => {
    const { data } = await DataService.GetExamCenter();
    setExamCenter(data.examCenter);
  };
  const getExamDepartment = async () => {
    const { data } = await DataService.GetExamDepartment();
    setExamDepartment(data.examDepartment);
  };
  const handleSubmit = async (data) => {
    console.log("data::: ", data);
    const res = await DataService.CreateInvigilatorValidation(data);
    console.log("res::: ", res);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getAllInvigilatorValidation();
    } else {
      alert("False");
    }
  };
  const getAllInvigilatorValidation = async () => {
    const { data } = await DataService.GetAllInvigilatorValidation();
    setAllInvigilatorValidation(data.invigilatorValidation);
    setFilteredData(data.invigilatorValidation);
  };
  const userName = (data) => {
    const result = users?.filter((item) => item._id == data);
    return result[0]?.first_name;
  };
  const exam_category = (data) => {
    const result = examDepartment?.filter((item) => item._id == data);
    return result[0]?.title;
  };
  const examCenterData = (data) => {
    const result = examCenter?.filter((item) => item._id == data);
    return result[0]?.name;
  };
  const updateUserRole = (item) => {
    setRowValue(item);
    setModal(true);
  };
  const updateUserData = async (data) => {
    console.log("data::: ", data);
    const res = await DataService.UpdateInvigilatorValidation(
      rowValue._id,
      data
    );
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getAllInvigilatorValidation();
    } else {
      alert("False");
    }
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteInvigilatorValidation(item._id);
    if (res.data.message == "Success") {
      alert("True");
      getAllInvigilatorValidation();
    } else {
      alert("False");
    }
  };
  useEffect(() => {
    applyFilter();
  }, [selectUser, selectExamCategory, selectExamCenter]);
  const applyFilter = () => {
    const result = allInvigilatorValidation?.filter((item) => {
      console.log(
        "selectUser::: ",
        selectUser,
        selectExamCenter,
        selectExamCategory
      );
      return (
        (selectUser === "" ||
          item.user_id?.toLowerCase().includes(selectUser?.toLowerCase())) &&
        (selectExamCenter === "" ||
          item.exam_center_id
            ?.toLowerCase()
            .includes(selectExamCenter?.toLowerCase())) &&
        (selectExamCategory === "" ||
          item.exam_category_id
            ?.toLowerCase()
            .includes(selectExamCategory?.toLowerCase()))
      );
    });
    console.log("filtered::: ", result);

    setFilteredData(result);
  };
  const downloadData = filteredData?.map((elt, i) => [
    i + 1,
    userName(elt?.user_id) || "-",
    exam_category(elt?.exam_category_id) || "-",
    examCenterData(elt?.exam_center_id) || "-",
  ]);
  const downloadHeaders = [["ID", "USER", "EXAM CATEGORY", "EXAM CENTER"]];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Invigilator Validation Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setRowValue();
                setViewData(false);
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                tablePdf(
                  "Invigilator Validation",
                  downloadHeaders,
                  downloadData
                )
              }
            >
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                exportExcel(
                  "Invigilator Validation",
                  downloadHeaders,
                  downloadData
                )
              }
            >
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <Row style={{ width: "100%" }}>
                  <Col xl="3">
                    <FormGroup className="mb-0">
                      <Label>User</Label>
                      <Input
                        name="select"
                        type="select"
                        value={selectUser}
                        onChange={(e) => setSelectUser(e.target.value)}
                      >
                        <option value="">Select ...</option>
                        {allInvigilatorValidation?.map((item) => (
                          <>
                            <option value={item.user_id}>
                              {userName(item?.user_id)}
                            </option>
                          </>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xl="3">
                    <FormGroup className="mb-0">
                      <Label>Exam List</Label>
                      <Input
                        name="select"
                        type="select"
                        value={selectExamCategory}
                        onChange={(e) => setSelectExamCategory(e.target.value)}
                      >
                        <option value="">Select ...</option>
                        {allInvigilatorValidation?.map((item) => (
                          <>
                            <option value={item.exam_category_id}>
                              {exam_category(item?.exam_category_id)}
                            </option>
                          </>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xl="3">
                    <FormGroup className="mb-0">
                      <Label>Exam Center</Label>
                      <Input
                        name="select"
                        type="select"
                        value={selectExamCenter}
                        onChange={(e) => setSelectExamCenter(e.target.value)}
                      >
                        {" "}
                        <option value="">Select ...</option>
                        {allInvigilatorValidation?.map((item) => (
                          <>
                            <option value={item.exam_center_id}>
                              {examCenterData(item?.exam_center_id)}
                            </option>
                          </>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    {/* <th scope="col" style={{ width: "70%" }}>
              Status
            </th> */}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    filteredData
                      ?.slice(pagination.start, pagination.end)
                      ?.map((data, idx) => (
                        <tr key={idx}>
                          <th scope="row">
                            <FormGroup check inline>
                              <Input type="checkbox" />
                            </FormGroup>
                          </th>
                          <td>{idx + 1}</td>
                          <td>{userName(data?.user_id) || "-"}</td>
                          <td>
                            {exam_category(data?.exam_category_id) || "-"}
                          </td>

                          <td>{examCenterData(data?.exam_center_id) || "-"}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    updateUserRole(data);
                                    setViewData(false);
                                    setIsEditData(false);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt" />
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  onClick={() => {
                                    setModal(!modal);
                                    updateUserRole(data);
                                    setViewData(true);
                                  }}
                                >
                                  <i className="	fas fa-eye" />
                                  View
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  // onClick={(e) => e.preventDefault()}
                                  onClick={() => deleteUserRole(data)}
                                >
                                  <i className="fas fa-trash " />
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <h5 className="text-center">No Data Available</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <PaginationData
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Formik
          initialValues={{
            user_id: rowValue ? rowValue?.user_id : "",
            exam_center_id: rowValue ? rowValue?.exam_center_id : "",
            exam_category_id: rowValue ? rowValue?.exam_category_id : "",
            mobile: rowValue ? rowValue?.mobile : "",
            lab_number: rowValue ? rowValue?.lab_number : parseInt(""),
            aadhaar_id: rowValue ? rowValue?.aadhaar_id : "",
            pan: rowValue ? rowValue?.pan : "",
            comments: rowValue ? rowValue?.comments : "",
            location_address: rowValue ? rowValue?.location_address : "",
            location_latitude: rowValue ? rowValue?.location_latitude : "",
            location_longitude: rowValue ? rowValue?.location_longitude : "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const merged = {
              ...values,
              lab_number: parseInt(values.lab_number),
            };
            console.log("merged::: ", merged);
            isEditData ? handleSubmit(merged) : updateUserData(merged);
          }}
        >
          {({ touched, errors, setFieldValue }) => (
            <div>
              <Form>
                <Row>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        User<span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="user_id"
                        className={` form-control
                          ${
                            touched.user_id && errors.user_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select role...</option>
                        {users?.map((item) => (
                          <>
                            <option value={item._id}>{item.mobile}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="user_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Exam Center<span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="exam_center_id"
                        className={` form-control
                          ${
                            touched.exam_center_id && errors.exam_center_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select role...</option>
                        {examCenter?.map((item) => (
                          <>
                            <option value={item._id}>{item.name}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="exam_center_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Exam List<span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="exam_category_id"
                        className={` form-control
                          ${
                            touched.exam_category_id && errors.exam_category_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select role...</option>
                        {examDepartment?.map((item) => (
                          <>
                            <option value={item._id}>{item.title}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="exam_category_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Lab Number</Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="lab_number"
                        className={` form-control
                          ${
                            touched.lab_number && errors.lab_number
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select Lab...</option>
                        <option value={parseInt(1)}>1</option>
                        <option value={parseInt(2)}>2</option>
                        <option value={parseInt(3)}>3</option>
                        <option value={parseInt(4)}>4</option>
                        <option value={parseInt(5)}>5</option>
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="lab_number"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Mobile</Label>
                      <Field
                        disabled={viewData}
                        type="number"
                        name="mobile"
                        className={` form-control
                          ${
                            touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="mobile"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Aadhaar</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="aadhaar_id"
                        className={` form-control
                          ${
                            touched.aadhaar_id && errors.aadhaar_id
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="aadhaar_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label className="d-block">Pan</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="pan"
                        className={` form-control
                          ${touched.pan && errors.pan ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="pan"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        {" "}
                        Invigilator Photo<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Image 1</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Image 2</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Image 3</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Image 4</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Image 5</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Video</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Comments<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="comments"
                        className={` form-control
                          ${
                            touched.comments && errors.comments
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="comments"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Location Address<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="location_address"
                        className={`form-control
                          ${
                            touched.location_address && errors.location_address
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="location_address"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Location Latitude<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="location_latitude"
                        className={`form-control
                          ${
                            touched.location_latitude &&
                            errors.location_latitude
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="location_latitude"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Location Longitude<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="location_longitude"
                        className={`form-control
                          ${
                            touched.location_longitude &&
                            errors.location_longitude
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="location_longitude"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {!viewData && (
                  <div className="btn-container">
                    <Button
                      type="submit"
                      className="w-100 bg-primary text-white"
                    >
                      {isEditData ? "Create" : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default InvigilatorValidation;
