import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useState } from "react";
import CustomTable from "components/table/CustomTable";
import { useQuery } from "react-query";
import DataService from "../../../services/requestApi";
const Settings = () => {
  // const { data, isLoading, error, isError } = useQuery(
  //   "getRoles",
  //   DataService.GetRoles()
  // );
  // console.log(data, isLoading, error);

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Dashboard
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  User Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow p-3">
              <Form>
                <Row>
                  <Col xl="12">
                    <FormGroup>
                      <Label>Audie text in English</Label>
                      <Input style={{ height: 150 }} />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <div className="mb-3">
                      <Label>Is Video Required?</Label>
                      <FormGroup className="ml-1" check>
                        <Input name="radio" type="radio" />{" "}
                        <Label check>Yes</Label>
                      </FormGroup>
                      <FormGroup className="ml-1" check>
                        <Input name="radio" type="radio" />{" "}
                        <Label check>No</Label>
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xl="6">
                    <div className="mb-3">
                      <Label>Is Audio Required?</Label>
                      <FormGroup className="ml-1" check>
                        <Input name="radio1" type="radio" />{" "}
                        <Label check>Yes</Label>
                      </FormGroup>
                      <FormGroup className="ml-1" check>
                        <Input name="radio1" type="radio" />{" "}
                        <Label check>No</Label>
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xl="6">
                    <div className="mb-3">
                      <Label>Is BIO Metric Required?</Label>
                      <FormGroup className="ml-1" check>
                        <Input name="radio2" type="radio" />{" "}
                        <Label check>Yes</Label>
                      </FormGroup>
                      <FormGroup className="ml-1" check>
                        <Input name="radio2" type="radio" />{" "}
                        <Label check>No</Label>
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xl="6">
                    <div className="mb-3">
                      <Label>Is IRIS Required?</Label>
                      <FormGroup className="ml-1" check>
                        <Input name="radio3" type="radio" />{" "}
                        <Label check>Yes</Label>
                      </FormGroup>
                      <FormGroup className="ml-1" check>
                        <Input name="radio3" type="radio" />{" "}
                        <Label check>No</Label>
                      </FormGroup>
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="btn-container d-flex align-items-end justify-content-end h-100">
                      <Button>Save</Button>
                      <Button>Cancel</Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
