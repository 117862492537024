import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";
import CustomTable from "components/table/CustomTable";
import DataService from "../../../services/requestApi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PaginationData from "../Pagination";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";

const Incidents = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const [allIncident, setAllIncident] = useState([]);
  const [rowValue, setRowValue] = useState();
  const [viewData, setViewData] = useState(false);
  const [isEditData, setIsEditData] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [parentIncident, setParentIncident] = useState("");
  const [incidentName, setIncidentName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showPrePage] = useState(10);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  const theading = ["ID", "PARENT INCIDENT", "INCIDENT NAME"];

  useEffect(() => {
    getAllIncident();
  }, []);

  const getAllIncident = async () => {
    const { data } = await DataService.GetAllIncident();
    setAllIncident(data.incident);
    setFilteredData(data.incident);
  };
  const LoginSchema = Yup.object().shape({
    parent_id: Yup.string().required("required"),
    incident_name: Yup.string().required("required"),
  });
  const updateUserRole = (item) => {
    setRowValue(item);
    setModal(true);
  };
  const handleSubmit = async (data) => {
    console.log("data::: ", data);
    const res = await DataService.CreateIncident(data);
    console.log("res::: ", res);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getAllIncident();
    } else {
      alert("False");
    }
  };
  const updateUserData = async (data) => {
    console.log("data::: ", data);
    const res = await DataService.UpdateIncident(rowValue._id, data);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getAllIncident();
    } else {
      alert("False");
    }
  };
  const deleteIncident = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteIncident(item._id);
    if (res.data.message == "Success") {
      alert("True");
      getAllIncident();
    } else {
      alert("False");
    }
  };
  useEffect(() => {
    applyFilter();
  }, [incidentName, parentIncident]);

  const applyFilter = () => {
    const filtered = allIncident?.filter((item) => {
      return (
        (parentIncident === "" ||
          item.parent_id
            ?.toLowerCase()
            .includes(parentIncident?.toLowerCase())) &&
        (incidentName === "" ||
          item.incident_name
            ?.toLowerCase()
            .includes(incidentName?.toLowerCase()))
      );
    });
    console.log("filtered::: ", filtered);

    setFilteredData(filtered);
  };
  const downloadData = filteredData?.map((elt, i) => [
    i + 1,
    elt?.parent_id,
    elt?.incident_name,
  ]);
  const downloadHeaders = [["ID", "PARENT INCIDENT	", "INCIDENT NAME"]];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Incidents Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setRowValue();
                setToggle(false);
                setViewData(false);
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                tablePdf("Incidents", downloadHeaders, downloadData)
              }
            >
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                exportExcel("Incidents", downloadHeaders, downloadData)
              }
            >
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0">
                <Row style={{ width: "100%" }}>
                  <Col xl="3">
                    <FormGroup className="mb-0">
                      <Label>Parent Incident</Label>
                      <Input
                        name="select"
                        type="select"
                        value={parentIncident}
                        onChange={(e) => setParentIncident(e.target.value)}
                      >
                        <option value="">Select Parent Incident</option>
                        <option value="Generator issue">Generator issue</option>
                        <option value="Man Power Issues">
                          Man Power Issues
                        </option>
                        <option value="Technical issue">Technical issue</option>
                        <option value="Power issue">Power issue</option>
                        <option value="Cheating issues">Cheating issues</option>
                        <option value="IT Related issues">
                          IT Related issues
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xl="3">
                    <FormGroup className="mb-0">
                      <Label>Incident Name</Label>
                      <Input
                        value={incidentName}
                        onChange={(e) => setIncidentName(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    {/* <th scope="col" style={{ width: "70%" }}>
              Status
            </th> */}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    filteredData
                      ?.slice(pagination.start, pagination.end)
                      ?.map((data, idx) => (
                        <tr key={idx}>
                          <th scope="row">
                            <FormGroup check inline>
                              <Input type="checkbox" />
                            </FormGroup>
                          </th>
                          <td>{idx + 1}</td>
                          <td>{data?.parent_id}</td>
                          <td>{data?.incident_name}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    updateUserRole(data);
                                    setViewData(false);
                                    setIsEditData(false);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt" />
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  onClick={() => {
                                    setModal(!modal);
                                    updateUserRole(data);
                                    setViewData(true);
                                  }}
                                >
                                  <i className="	fas fa-eye" />
                                  View
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  // onClick={(e) => e.preventDefault()}
                                  onClick={() => deleteIncident(data)}
                                >
                                  <i className="fas fa-trash " />
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <h5 className="text-center">No Data Available</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <PaginationData
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Formik
          initialValues={{
            parent_id: rowValue ? rowValue?.parent_id : "",
            incident_name: rowValue ? rowValue?.incident_name : "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const onOffToggle = { status: toggle };
            let merged = {
              ...values,
              ...onOffToggle,
            };
            console.log("merged::: ", merged);
            isEditData ? handleSubmit(merged) : updateUserData(merged);
          }}
        >
          {({ touched, errors, setFieldValue }) => (
            <div>
              <Form>
                <Row>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Parent Incident<span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="parent_id"
                        className={` form-control
                          ${
                            touched.parent_id && errors.parent_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select Parent Incident</option>
                        <option value="Power issue">Power issue</option>
                        <option value="Generator issue">Generator issue</option>
                        <option value="Man Power Issues">
                          Man Power Issues
                        </option>
                        <option value="Technical issue">Technical issue</option>
                        <option value="Cheating issues">Cheating issues</option>
                        <option value="IT Related issues">
                          IT Related issues
                        </option>
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="parent_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Incident Name<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="incident_name"
                        className={`form-control
                          ${
                            touched.incident_name && errors.incident_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="incident_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <Label className="d-block">Status</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="status"
                        onChange={(e) => {
                          setToggle(e.target.checked);
                        }}
                        defaultChecked={rowValue?.status}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                </Row>
                {!viewData && (
                  <div className="btn-container">
                    <Button
                      type="submit"
                      className="w-100 bg-primary text-white"
                    >
                      {isEditData ? "Create" : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default Incidents;
