import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomTable from "components/table/CustomTable";
import DataService from "../../../services/requestApi";
import { STATECITIES } from "../master/StateCitiesObject";
import PaginationData from "../Pagination";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";

const Candidate = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const [allCandidate, setAllCandidate] = useState([]);
  const [rowValue, setRowValue] = useState();
  const [viewData, setViewData] = useState(false);
  const [isEditData, setIsEditData] = useState(false);
  const [allState, setAllState] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [isMinorAge, setIsMinorAge] = useState(false);
  const [isBiometric, setIsBiometric] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [guardianAddressSameAsCandidate, setGuardianAddressSameAsCandidate] =
    useState(false);
  const [guardianAddressCities, setGuardianAddressCities] = useState([]);
  const [status, setStatus] = useState(false);
  const [showPrePage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const LoginSchema = Yup.object().shape({
    first_name: Yup.string().required("required"),
    last_name: Yup.string().required("required"),
    middle_name: Yup.string().required("required"),
    gender: Yup.string().required("required"),
    dob: Yup.string().required("required"),
    age: Yup.string().required("required"),
    email: Yup.string().required("required"),
    mobile: Yup.string().required("required"),
    state_name: Yup.string().required("required"),
    city_name: Yup.string().required("required"),
    village_id: Yup.string().required("required"),
    address: Yup.string().required("required"),
    landmark: Yup.string().required("required"),
    pincode: Yup.string().required("required"),
    father_name: Yup.string().required("required"),
    mother_name: Yup.string().required("required"),
    candidate_location_address: Yup.string().required("required"),
    candidate_location_latitude: Yup.string().required("required"),
    candidate_location_longitude: Yup.string().required("required"),
    candidate_aadhaar_id: Yup.string().required("required"),
    specially_abled: Yup.string().required("required"),
    specially_abled_text: Yup.string().required("required"),
    cast: Yup.string().required("required"),
    alternate_contact_number: Yup.string().required("required"),
    locality: Yup.string().required("required"),
    place_of_residence: Yup.string().required("required"),
    state_from_where_appering: Yup.string().required("required"),
    guardian_relation: Yup.string().required("required"),
    guardian_name: Yup.string().required("required"),
    guardian_address_state: Yup.string().required("required"),
    guardian_address_city: Yup.string().required("required"),
    guardian_address: Yup.string().required("required"),
    guardian_address_landmark: Yup.string().required("required"),
    guardian_address_pincode: Yup.string().required("required"),
  });

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  useEffect(() => {
    getAllCandidate();
    state();
  }, []);
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const getAllCandidate = async () => {
    const { data } = await DataService.GetAllCandidate();
    setAllCandidate(data.candidate);
    setFilteredData(data.candidate);
  };
  const theading = ["ID", "FIRST NAME", "LAST NAME", "EMAIL"];
  const tdata = [
    [
      10133,
      "Invigilator",
      "Nitesh",
      "Kumar",
      "nekolkata@gmail.com",
      "9143060898",
    ],
  ];
  const state = () => {
    const allKeys = Object.keys(STATECITIES[0]);
    setAllState(allKeys);
  };
  const citiesValue = (e) => {
    setCitiesArray(STATECITIES[0][e.target.value]);
  };
  const guardian_address_cities = (e) => {
    setGuardianAddressCities(STATECITIES[0][e.target.value]);
  };
  const updateUserRole = (item) => {
    setRowValue(item);
    setModal(true);
  };
  const handleSubmit = async (data) => {
    const res = await DataService.CreateCandidate(data);
    console.log("res:::", res);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getAllCandidate();
    } else {
      alert("False");
    }
  };
  const updateUserData = async (data) => {
    console.log("data::: ", data);
    const res = await DataService.UpdateCandidate(rowValue._id, data);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getAllCandidate();
    } else {
      alert("False");
    }
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteCandidate(item._id);
    if (res.data.message == "Success") {
      alert("True");
      getAllCandidate();
    } else {
      alert("False");
    }
  };
  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = allCandidate?.filter((item) => {
      return item.email.includes(searchQuery);
    });
    setFilteredData(filtered);
  };
  const downloadData = filteredData?.map((elt, i) => [
    i + 1,
    elt?.first_name || "-",
    elt?.last_name || "-",
    elt?.email || "-",
  ]);
  const downloadHeaders = [["ID", "FIRST NAME	", "LAST NAME", "EMAIL"]];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Candidate
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Candidate Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setRowValue();
                setViewData(false);
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                tablePdf("Candidate", downloadHeaders, downloadData)
              }
            >
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                exportExcel("Candidate", downloadHeaders, downloadData)
              }
            >
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Card tables</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search email..."
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
                showStatus
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    filteredData
                      ?.slice(pagination.start, pagination.end)
                      ?.map((data, idx) => (
                        <tr key={idx}>
                          <th scope="row">
                            <FormGroup check inline>
                              <Input type="checkbox" />
                            </FormGroup>
                          </th>
                          <td>{idx + 1}</td>
                          <td>{data?.first_name || "-"}</td>
                          <td>{data?.last_name || "-"}</td>
                          <td>{data?.email || "-"}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    updateUserRole(data);
                                    setViewData(false);
                                    setIsEditData(false);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt" />
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  onClick={() => {
                                    setModal(!modal);
                                    updateUserRole(data);
                                    setViewData(true);
                                  }}
                                >
                                  <i className="	fas fa-eye" />
                                  View
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  // onClick={(e) => e.preventDefault()}
                                  onClick={() => deleteUserRole(data)}
                                >
                                  <i className="fas fa-trash " />
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <h5 className="text-center">No Data Available</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <PaginationData
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Formik
          initialValues={{
            first_name: rowValue?.first_name || "",
            last_name: rowValue?.last_name || "",
            middle_name: rowValue?.middle_name || "",
            gender: rowValue?.gender || "",
            dob: rowValue?.dob || "",
            age: rowValue?.age || "",
            email: rowValue?.email || "",
            mobile: rowValue?.mobile || "",
            state_name: rowValue?.state_name || "",
            city_name: rowValue?.city_name || "",
            village_id: rowValue?.village_id || "",
            address: rowValue?.address || "",
            landmark: rowValue?.landmark || "",
            pincode: rowValue?.pincode || "",
            father_name: rowValue?.father_name || "",
            mother_name: rowValue?.mother_name || "",
            candidate_location_address:
              rowValue?.candidate_location_address || "",
            candidate_location_latitude:
              rowValue?.candidate_location_latitude || "",
            candidate_location_longitude:
              rowValue?.candidate_location_longitude || "",
            candidate_aadhaar_id: rowValue?.candidate_aadhaar_id || "",
            specially_abled: rowValue?.specially_abled || "",
            specially_abled_text: rowValue?.specially_abled_text || "",
            cast: rowValue?.cast || "",
            alternate_contact_number: rowValue?.alternate_contact_number || "",
            locality: rowValue?.locality || "",
            place_of_residence: rowValue?.place_of_residence || "",
            state_from_where_appering:
              rowValue?.state_from_where_appering || "",
            guardian_relation: rowValue?.guardian_relation || "",
            guardian_name: rowValue?.guardian_name || "",
            guardian_address_state: rowValue?.guardian_address_state || "",
            guardian_address_city: rowValue?.guardian_address_city || "",
            guardian_address: rowValue?.guardian_address || "",
            guardian_address_landmark:
              rowValue?.guardian_address_landmark || "",
            guardian_address_pincode: rowValue?.guardian_address_pincode || "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const onOffToggle = {
              is_minor_age: isMinorAge,
              is_biometric: isBiometric,
              is_subscribe: isSubscribe,
              guardian_address_same_as_candidate:
                guardianAddressSameAsCandidate,
              status: status,
            };
            const merged = {
              ...values,
              ...onOffToggle,
            };
            console.log("merged::: ", merged);
            isEditData ? handleSubmit(merged) : updateUserData(merged);
          }}
        >
          {({ touched, errors, setFieldValue }) => (
            <div>
              <Form>
                <Row>
                  <Col xl="6">
                    <FormGroup>
                      <Label>First Name</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="first_name"
                        className={` form-control
                          ${
                            touched.first_name && errors.first_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="first_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="last_name"
                        className={` form-control
                          ${
                            touched.last_name && errors.last_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="last_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Middle Name</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="middle_name"
                        className={` form-control
                          ${
                            touched.middle_name && errors.middle_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="middle_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Gender</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="gender"
                        className={` form-control
                          ${
                            touched.gender && errors.gender ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="gender"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Date Of Birth</Label>
                      <Field
                        disabled={viewData}
                        type="date"
                        name="dob"
                        className={` form-control
                          ${touched.dob && errors.dob ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="dob"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Age</Label>
                      <Field
                        disabled={viewData}
                        type="number"
                        name="age"
                        className={` form-control
                          ${touched.age && errors.age ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="age"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Email</Label>
                      <Field
                        disabled={viewData}
                        type="email"
                        name="email"
                        className={` form-control
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Mobile</Label>
                      <Field
                        disabled={viewData}
                        type="number"
                        name="mobile"
                        className={` form-control
                          ${
                            touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="mobile"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>State Name</Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="state_name"
                        onChange={(e) => {
                          citiesValue(e);
                          setFieldValue("state_name", e.target.value);
                        }}
                        className={` form-control
                          ${
                            touched.state_name && errors.state_name
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        {allState?.map((item, index) => (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="state_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>City</Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name={`city_name`}
                        className={` form-control`}
                      >
                        <option value="">Select cities ... </option>
                        {citiesArray?.map((item) => (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        ))}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Village Id</Label>
                      <Field
                        disabled={viewData}
                        type="number"
                        name="village_id"
                        className={` form-control
                          ${
                            touched.village_id && errors.village_id
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="village_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Address</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="address"
                        className={` form-control
                          ${
                            touched.address && errors.address
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="address"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Landmark</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="landmark"
                        className={` form-control
                          ${
                            touched.landmark && errors.landmark
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="landmark"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Pincode</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="pincode"
                        className={` form-control
                          ${
                            touched.pincode && errors.pincode
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="pincode"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <Label className="d-block">Is Minor Age</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="is_minor_age"
                        onChange={(e) => {
                          setIsMinorAge(e.target.checked);
                        }}
                        defaultChecked={rowValue?.is_minor_age}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                  <Col xl="6">
                    <Label className="d-block">Is Biometric</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="is_biometric"
                        onChange={(e) => {
                          setIsBiometric(e.target.checked);
                        }}
                        defaultChecked={rowValue?.is_biometric}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Profile Image</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Candidate Video</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Candidate Audio</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Father Name</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="father_name"
                        className={` form-control
                          ${
                            touched.father_name && errors.father_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="father_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Mother Name</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="mother_name"
                        className={` form-control
                          ${
                            touched.mother_name && errors.mother_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="mother_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Candidate Location address</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="candidate_location_address"
                        className={` form-control
                          ${
                            touched.candidate_location_address &&
                            errors.candidate_location_address
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="candidate_location_address"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Candidate Location Latitude</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="candidate_location_latitude"
                        className={` form-control
                          ${
                            touched.candidate_location_latitude &&
                            errors.candidate_location_latitude
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="candidate_location_latitude"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Candidate Longitude</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="candidate_location_longitude"
                        className={` form-control
                          ${
                            touched.candidate_location_longitude &&
                            errors.candidate_location_longitude
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="candidate_location_longitude"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Candidate Aadhaar Id</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="candidate_aadhaar_id"
                        className={` form-control
                          ${
                            touched.candidate_aadhaar_id &&
                            errors.candidate_aadhaar_id
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="candidate_aadhaar_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Specially Abled</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="specially_abled"
                        className={` form-control
                          ${
                            touched.specially_abled && errors.specially_abled
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="specially_abled"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Specially Abled Text</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="specially_abled_text"
                        className={` form-control
                          ${
                            touched.specially_abled_text &&
                            errors.specially_abled_text
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="specially_abled_text"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Cast</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="cast"
                        className={` form-control
                          ${touched.cast && errors.cast ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="cast"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <Label className="d-block">Is Subscribe</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="is_subscribe"
                        onChange={(e) => {
                          setIsSubscribe(e.target.checked);
                        }}
                        defaultChecked={rowValue?.is_subscribe}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Alternate Contact Number</Label>
                      <Field
                        disabled={viewData}
                        type="number"
                        name="alternate_contact_number"
                        className={` form-control
                          ${
                            touched.alternate_contact_number &&
                            errors.alternate_contact_number
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="alternate_contact_number"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Locality</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="locality"
                        className={` form-control
                          ${
                            touched.locality && errors.locality
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="locality"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Place Of Residence</Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="place_of_residence"
                        onChange={(e) => {
                          citiesValue(e);
                          setFieldValue("place_of_residence", e.target.value);
                        }}
                        className={` form-control
                          ${
                            touched.place_of_residence &&
                            errors.place_of_residence
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value={""}>
                          {"Select Place Of Residence ..."}
                        </option>
                        <option value={"urban"}>{"urban"}</option>
                        <option value={"rural"}>{"rural"}</option>
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="place_of_residence"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>State From Where Appering</Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="state_from_where_appering"
                        onChange={(e) => {
                          setFieldValue(
                            "state_from_where_appering",
                            e.target.value
                          );
                        }}
                        className={` form-control
                          ${
                            touched.state_from_where_appering &&
                            errors.state_from_where_appering
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        {allState?.map((item, index) => (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="state_from_where_appering"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Guardian Relation</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="guardian_relation"
                        className={` form-control
                          ${
                            touched.guardian_relation &&
                            errors.guardian_relation
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="guardian_relation"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Guardian Name</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="guardian_name"
                        className={` form-control
                          ${
                            touched.guardian_name && errors.guardian_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="guardian_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Guardian ID Proof</Label>
                      <Input
                        type="file"
                        onChange={(e) => handleFile(e.target?.files[0])}
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <Label className="d-block">
                      Guardian Address Same As Candidate
                    </Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="guardian_address_same_as_candidate"
                        onChange={(e) => {
                          setGuardianAddressSameAsCandidate(e.target.checked);
                        }}
                        defaultChecked={
                          rowValue?.guardian_address_same_as_candidate
                        }
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Guardian Address State</Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="guardian_address_state"
                        onChange={(e) => {
                          guardian_address_cities(e);
                          setFieldValue(
                            "guardian_address_state",
                            e.target.value
                          );
                        }}
                        className={` form-control
                          ${
                            touched.guardian_address_state &&
                            errors.guardian_address_state
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value={""}>
                          {"Select Guardian Address State ... "}
                        </option>
                        {allState?.map((item, index) => (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="guardian_address_state"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>City</Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name={`guardian_address_city`}
                        className={` form-control`}
                      >
                        <option value="">Select cities ... </option>
                        {guardianAddressCities?.map((item) => (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        ))}
                      </Field>
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Guardian Address</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="guardian_address"
                        className={` form-control
                          ${
                            touched.guardian_address && errors.guardian_address
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="guardian_address"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Guardian Address Landmark</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="guardian_address_landmark"
                        className={` form-control
                          ${
                            touched.guardian_address_landmark &&
                            errors.guardian_address_landmark
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="guardian_address_landmark"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Guardian Address Pincode</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="guardian_address_pincode"
                        className={` form-control
                          ${
                            touched.guardian_address_pincode &&
                            errors.guardian_address_pincode
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="guardian_address_pincode"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <Label className="d-block">Status</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="status"
                        onChange={(e) => {
                          setStatus(e.target.checked);
                        }}
                        defaultChecked={rowValue?.status}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                </Row>
                {!viewData && (
                  <div className="btn-container">
                    <Button
                      type="submit"
                      className="w-100 bg-primary text-white"
                    >
                      {isEditData ? "Create" : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default Candidate;
