import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";
import CustomTable from "components/table/CustomTable";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DataService from "../../../services/requestApi";
import * as Yup from "yup";
import Pagination from "../Pagination";
import XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";
const ExamDepartment = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const [isEditData, setIsEditData] = useState(false);
  const [rowValue, setRowValue] = useState();
  const [examDepartment, setExamDepartment] = useState([]);
  const [toggle, setToggle] = useState();
  const [viewData, setViewData] = useState(false);
  const [showPrePage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  const theading = ["ID", "TITLE", "CODE", "STATUS"];
  const tdata = [[3, "SG-3", "	SG3"]];
  const LoginSchema = Yup.object().shape({
    title: Yup.string().required("required"),
    code: Yup.string().required("required"),
  });

  useEffect(() => {
    getExamDepartment();
  }, []);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const getExamDepartment = async () => {
    const { data } = await DataService.GetExamDepartment();
    setExamDepartment(data.examDepartment);
    setFilteredData(data.examDepartment);
  };
  const handleSubmit = async (data) => {
    const res = await DataService.CreateExamDepartment(data);
    console.log("res.data::: ", res.data);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getExamDepartment();
    } else {
      alert("False");
    }
  };
  const updateExamDepartment = (item) => {
    setRowValue(item);
    setModal(true);
  };
  const updateExamDepartmentData = async (data) => {
    const res = await DataService.UpdateExamDepartment(rowValue._id, data);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getExamDepartment();
    } else {
      alert("False");
    }
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteExamDepartment(item._id);
    if (res.data.message == "Success") {
      alert("True");
      getExamDepartment();
    } else {
      alert("False");
    }
  };
  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = examDepartment.filter((item) => {
      return item.title.includes(searchQuery);
    });
    setFilteredData(filtered);
  };
  const downloadData = filteredData?.map((elt, i) => [
    i,
    elt.title,
    elt.code,
    elt.status,
  ]);
  const downloadHeaders = [["ID", "TITLE", "CODE", "STATUS"]];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Exam Department Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setRowValue();
                setViewData(false);
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                tablePdf("Exam Department", downloadHeaders, downloadData)
              }
            >
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                exportExcel("Exam Department", downloadHeaders, downloadData)
              }
            >
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Card tables</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    {/* <th scope="col" style={{ width: "70%" }}>
              Status
            </th> */}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    <>
                      {" "}
                      {filteredData
                        ?.slice(pagination.start, pagination.end)
                        ?.map((data, idx) => (
                          <tr key={idx}>
                            <th scope="row">
                              <FormGroup check inline>
                                <Input type="checkbox" />
                              </FormGroup>
                            </th>
                            <td>{idx + 1}</td>
                            {/* <td>{userRole(data?.role_id)}</td> */}
                            <td>{data?.title || "-"}</td>

                            <td>{data?.code || "-"}</td>
                            <td>{data.status ? "ON" : "OFF" || "-"}</td>

                            {/* <td>
                        <Label className="custom-toggle custom-toggle-warning">
                          <Input
                            type="checkbox"
                            defaultChecked={data?.status}
                            disabled
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Off"
                            data-label-on="On"
                          />
                        </Label>
                      </td> */}

                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() => {
                                      updateExamDepartment(data);
                                      setIsEditData(false);
                                      setViewData(false);
                                    }}
                                  >
                                    <i className="fas fa-pencil-alt" />
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href=""
                                    onClick={() => {
                                      setModal(!modal);
                                      updateExamDepartment(data);
                                      setViewData(true);
                                    }}
                                  >
                                    <i className="	fas fa-eye" />
                                    View
                                  </DropdownItem>
                                  <DropdownItem
                                    href=""
                                    // onClick={(e) => e.preventDefault()}
                                    onClick={() => deleteUserRole(data)}
                                  >
                                    <i className="fas fa-trash " />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <>
                      {" "}
                      <tr>
                        <td colSpan={6}>
                          <h5 className="text-center">No Data Available</h5>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <Pagination
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        {/* <Form>
          <FormGroup>
            <Label>
              Title<span className="text-danger">*</span>
            </Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>
              Code<span className="text-danger">*</span>
            </Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label className="d-block">Status</Label>
            <Label className="custom-toggle custom-toggle-warning">
              <Input type="checkbox" />
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="Off"
                data-label-on="On"
              />
            </Label>
          </FormGroup>
          <div className="btn-container">
            <Button className="w-100 bg-primary text-white">Create</Button>
          </div>
        </Form> */}
        <Formik
          initialValues={{
            title: rowValue ? rowValue?.title : "",
            code: rowValue ? rowValue?.code : "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const onOffToggle = { status: toggle };
            let merged = {
              ...values,
              ...onOffToggle,
            };
            console.log("merged::: ", merged);
            isEditData
              ? handleSubmit(merged)
              : updateExamDepartmentData(merged);
          }}
        >
          {({ touched, errors }) => (
            <div>
              <Form>
                <Row>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Title<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="title"
                        className={` form-control
                          ${touched.title && errors.title ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="title"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Code<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="code"
                        className={` form-control
                          ${touched.code && errors.code ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="code"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  <Col xl="6">
                    <Label className="d-block">Status</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="status"
                        onChange={(e) => {
                          setToggle(e.target.checked);
                        }}
                        defaultChecked={rowValue?.status}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                </Row>
                {!viewData && (
                  <div className="btn-container">
                    <Button
                      type="submit"
                      className="w-100 bg-primary text-white"
                    >
                      {isEditData ? "Create" : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default ExamDepartment;
