import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { toast } from "react-toastify";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";
import CustomTable from "components/table/CustomTable";
import DataService from "../../../services/requestApi";

const RestAPI = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isEditData, setIsEditData] = useState(false);
  useEffect(() => {
    getRestApi();
  }, []);

  const getRestApi = async () => {
    const { data } = await DataService.GetRestApi();
    setTableData(data.restApi);

    console.log("data::: ", data);
  };

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  const theading = [
    "ID",
    "API NAME",
    "API TYPE",
    "API URL",
    "API METHOD",
    "PAGING",
    "LIMIT",
    "TOKEN REQUIRED?",
    "ACTIVE",
  ];
  const tdata = [
    [
      10133,
      "Invigilator",
      "Nitesh",
      "Kumar",
      "nekolkata@gmail.com",
      "9143060898",
    ],
  ];

  const deleteRestApi = async (item) => {
    const res = await DataService.DeleteRestApi(item._id);
    if (res.data.status) {
      toast.success(res.data.message);
      getRestApi();
    } else {
      toast.error(res.data.message);
      getRestApi();
    }
  };

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Mobile Rest API
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Rest API Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Link
              className="btn btn-secondary p-1 d-inline-flex justify-content-center align-items-center"
              to="/admin/create-rest-api"
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Link>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button className="p-0">
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button className="p-0">
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* filter */}
        <Card className="shadow p-3 mb-3">
          <Row>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">API NAME</Label>
                <Input placeholder="Name" />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">API TYPE</Label>
                <Input placeholder="Name" />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">API URL</Label>
                <Input placeholder="url" />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">API METHOD</Label>
                <Input placeholder="Name" />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">PAGING</Label>
                <Input placeholder="Name" />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">LIMIT</Label>
                <Input placeholder="Name" />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">TOKEN REQUIRED?</Label>
                <Input placeholder="Name" />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">Status</Label>
                <Input placeholder="Name" />
              </FormGroup>
            </Col>
            <Col xl="8">
              <div className="btn-container d-flex align-items-end justify-content-end h-100">
                <Button>filter</Button>
                <Button>Clear</Button>
              </div>
            </Col>
          </Row>
        </Card>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0">
                <h3 className="mb-0">Card tables</h3>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
                showStatus
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                {/* tableData */}
                <tbody>
                  {tableData?.length > 0 ? (
                    <>
                      {tableData
                        // ?.slice(pagination.start, pagination.end)
                        ?.map((data, idx) => (
                          <tr key={idx}>
                            <th scope="row">
                              <FormGroup check inline>
                                <Input type="checkbox" />
                              </FormGroup>
                            </th>

                            <td>{idx + 1}</td>
                            <td>{data?.api_name || "-"}</td>
                            <td>{data.api_type}</td>
                            <td>{data.api_url}</td>
                            <td>{data.api_method}</td>
                            <td>{data.paging == true ? "On" : "Off"}</td>
                            <td>{data.page_limit}</td>
                            <td>
                              {data.token_required == true ? "On" : "Off"}
                            </td>
                            <td>{data.status == true ? "On" : "Off"}</td>

                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <Link
                                    to={{
                                      pathname: "/admin/create-rest-api",
                                      state: { data: data },
                                    }}
                                  >
                                    <DropdownItem href="#">
                                      {" "}
                                      <i className="fas fa-pencil-alt" />
                                      Edit
                                    </DropdownItem>
                                  </Link>

                                  <DropdownItem
                                    href="#"
                                    // onClick={(e) => e.preventDefault()}
                                    onClick={() => deleteRestApi(data)}
                                  >
                                    <i className="fas fa-trash " />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={5}>
                          <h5 className="text-center">No Data Available</h5>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Form>
          <FormGroup>
            <Label>User Role*</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>First Name*</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Last Name</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Mobile*</Label>
            <Input type="number" />
          </FormGroup>
          <FormGroup>
            <Label>Password*</Label>
            <Input type="password" />
          </FormGroup>
          <FormGroup>
            <Label>Status</Label>
            <Input type="switch" />
          </FormGroup>
          <FormGroup>
            <Label>User Profile Image</Label>
            <Input
              type="file"
              onChange={(e) => handleFile(e.target?.files[0])}
            />
            {data && <img src={data} width={200} className="p-3" alt="" />}
          </FormGroup>
        </Form>
      </CustomModal>
    </>
  );
};

export default RestAPI;
