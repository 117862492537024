import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataService from "../../../../services/requestApi";

export default function AllDistList({ showData }) {
  const [dataLoader, setDataLoader] = useState(false);
  const [allVarificationData, setAllVarificationData] = useState([]);

  useEffect(() => {
    getAllDistrict();
  }, [showData?._id]);

  function groupByUser(dataArray) {
    const userMap = new Map();

    dataArray.forEach((item) => {
      const userId = item?.user?._id;
      if (!userMap.has(userId)) {
        userMap.set(userId, {
          user: item?.user,
          rooms: [],
        });
      }
      console.log("item::: ", item);
      const roomInfo = {
        ...item?.roomData,
        room: item?.room,
        images_url: item?.images_url,
      };
      userMap.get(userId)?.rooms?.push(roomInfo);
    });

    return Array.from(userMap.values());
  }

  const getAllDistrict = async () => {
    console.log("showData", showData);
    if (showData?._id) {
      setDataLoader(true);
      try {
        const { data } = await DataService.GetDistrictVarificationData(
          showData?._id
        );
        console.log("GetDistrictVarificationData data::: ", data);
        if (data.message == "Success") {
          const groupedData = groupByUser(data.data);
          console.log("groupedData::: ", groupedData);
          setAllVarificationData(groupedData);
        }
      } catch (error) {
        console.log("error::: ", error);
      }

      setDataLoader(false);
    }
  };

  return (
    <div>
      <Container fluid>
        {dataLoader ? (
          "Loading..."
        ) : allVarificationData.length > 0 ? (
          allVarificationData?.map((row) => (
            <>
              {console.log("row::: ", row)}
              <Card className="mt-4">
                <CardHeader>
                  {row?.user?.role?.title} : {row?.user?.userId}
                </CardHeader>

                {row?.rooms?.map((item) => (
                  <>
                    <Card className="shadow p-3">
                      {console.log("item::: ", item)}
                      <Card className="p-4 mt-2">
                        <p>
                          <b>Room {item?.room}</b>
                        </p>
                        <Row className="mt-2">
                          {Object.entries(item).map(([key, value], i) => (
                            <>
                              {console.log("value::: ", typeof value)}
                              {typeof value === "object" &&
                              !Array.isArray(value)
                                ? Object.entries(value).map(
                                    ([key2, value2], i) => (
                                      <>
                                        <Col xl="2">
                                          <FormGroup className="mb-2">
                                            <Label for="exampleSelect">
                                              {key2}
                                            </Label>
                                            <Input
                                              type="text"
                                              value={value2}
                                              disabled
                                            ></Input>
                                          </FormGroup>
                                        </Col>
                                      </>
                                    )
                                  )
                                : key != "images_url" && (
                                    <Col xl="2">
                                      <FormGroup className="mb-2">
                                        <Label for="exampleSelect">{key}</Label>
                                        <Input
                                          type="text"
                                          value={value}
                                          disabled
                                        ></Input>
                                      </FormGroup>
                                    </Col>
                                  )}
                            </>
                          ))}

                          {item?.images_url?.map((item) => (
                            <>
                              <Col xl="2">
                                <Label for="exampleSelect">Images</Label>
                                <FormGroup
                                  className="border mb-2 p-4 "
                                  style={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={item}
                                    height={100}
                                    width={100}
                                    style={{ borderRadius: "10px" }}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          ))}
                        </Row>
                      </Card>
                    </Card>
                  </>
                ))}
              </Card>
            </>
          ))
        ) : (
          <>No data capture</>
        )}
      </Container>
    </div>
  );
}
