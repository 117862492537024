import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
  CardBody,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// core components
import { Link } from "react-router-dom";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useRef, useState } from "react";
import DataService from "../../../services/requestApi";
import Pagination from "../Pagination";
import SampleFile from "../../../assets/samplefile/DISTRICT CODES.xlsx";
import { toast } from "react-toastify";
import qs from "qs";

const District = () => {
  const [modal, setModal] = useState(false);
  const [allDistData, setAllDistData] = useState([]);
  const [rowValue, setRowValue] = useState();
  const [isEditData, setIsEditData] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [showPrePage] = useState(10);
  const [viewData, setViewData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [loaderUpload, setLoaderUpload] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const fileInputRef = useRef(null);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const data = allDistData?.map((elt, i) => [i, elt.role_name]);

  const downloadFile = () => {
    const url = SampleFile;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "district.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getAllDistrict();
  }, []);

  const getAllDistrict = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllDistrict();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllDistData(data.data);
        setFilteredData(data.data);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const handleSubmit = async (data) => {
    console.log("data", data);
    setLoading(true);
    try {
      const { data: res } = await DataService.AddNewDistrict(data);
      console.log("res::: ", res);
      if (res?.status_code == 200) {
        toast.success(res?.message);
        setModal(false);
        getAllDistrict();
      } else {
        setModal(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setLoading(false);
  };

  const updateUserRole = (item) => {
    setRowValue(item);
    setToggle(item?.status);
    setModal(true);
  };
  const updateUserData = async (item) => {
    console.log("toggle", toggle);
    console.log("rowValue._id", rowValue._id);
    const dto = { status: toggle };
    console.log("data", data);
    setLoading(true);
    const formDataEncoded = qs.stringify(dto);
    try {
      const { data: res } = await DataService.UpdateDistrictData(
        rowValue._id,
        item
      );
      console.log("res::: ", res);
      if (res?.status_code == 200) {
        toast.success(res?.message);
        setModal(false);
      } else {
        setModal(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getAllDistrict();
    setLoading(false);
  };

  const distSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    code: Yup.string().required("required"),
  });
  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = allDistData?.filter((item) => {
      return item?.name?.toLowerCase().includes(searchQuery?.toLowerCase());
    });
    setFilteredData(filtered);
  };

  const handelUploadExcel = async () => {
    console.log("file", file);
    setLoaderUpload(true);
    const formData = new FormData();
    formData.append("excel", file);
    try {
      if (file) {
        const { data: res } = await DataService.UploadDistrict(formData);
        console.log("res::: ", res);
        if (res?.status_code == 200) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
        getAllDistrict();
        setLoaderUpload(false);
        setLoading(false);
        fileInputRef.current.value = null;
      } else {
        toast.error("Please select file!");
        setLoaderUpload(false);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
    setLoaderUpload(false);
  };

  const deleteDistrict = async (item) => {
    setLoading(true);
    try {
      const res = await DataService.DeleteDistrict(item._id);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getAllDistrict();
    setLoading(false);
  };

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  District
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setViewData(false);
                setRowValue();
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />{" "}
              <span className="px-2">Add new</span>
            </Button>
            {/* <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button
              className="p-0"
              onClick={() => exportExcel("UserRole", downloadHeaders, data)}
            >
              <img src={XLS} width={20} alt="" />
            </Button> */}
          </Col>
        </Row>
      </Container>

      {/* upload */}

      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="py-2 px-3 mb-3 bg-light">
                <h3 className="mb-0">Import Csv Data</h3>
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <Col xl="6">
                    <FormGroup className="">
                      <Label>Upload file</Label>
                      <div
                        className="border border-2 py-1 px-2 rounded"
                        style={{ width: 300 }}
                      >
                        <Input
                          type="file"
                          name="upload"
                          accept=".xlsx, .xls, .csv"
                          id="upload"
                          innerRef={fileInputRef}
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                    </FormGroup>
                    <Button onClick={downloadFile}>
                      {/* <a href={SampleFile} className="mt-4"> */}
                      Download Sample file
                      {/* </a> */}
                    </Button>
                  </Col>
                  <Col xl="6">
                    <div className="btn-container d-flex align-items-end h-100">
                      <Button
                        onClick={() => handelUploadExcel()}
                        disabled={loaderUpload}
                      >
                        {loaderUpload ? "Loading..." : "Upload"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">District</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>

              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Dist Name</th>
                    <th scope="col">Dist Code</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                {dataLoader ? (
                  <tbody>
                    <tr>
                      <td colSpan={5}>
                        <h5 className="text-center">Loading...</h5>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {filteredData?.length > 0 ? (
                      <>
                        {filteredData
                          ?.slice(pagination.start, pagination.end)
                          ?.map((data, idx) => (
                            <tr key={idx}>
                              <td>{pagination.start + idx + 1}</td>
                              <td>{data?.name || "-"}</td>
                              <td>{data?.code || "-"}</td>
                              <td>{data.status ? "ON" : "OFF" || "-"}</td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        updateUserRole(data);
                                        setIsEditData(false);
                                        setViewData(false);
                                      }}
                                    >
                                      <i className="fas fa-pencil-alt" />
                                      Edit
                                    </DropdownItem>
                                    {/* <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      setModal(!modal);
                                      updateUserRole(data);
                                      setViewData(true);
                                    }}
                                  >
                                    <i className="	fas fa-eye" />
                                    View
                                  </DropdownItem> */}
                                    <DropdownItem
                                      href="#"
                                      // onClick={(e) => e.preventDefault()}
                                      onClick={() => deleteDistrict(data)}
                                    >
                                      <i className="fas fa-trash " />
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={5}>
                            <h5 className="text-center">No Data Available</h5>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                )}
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <Pagination
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add District"
      >
        {!isEditData ? (
          <div>
            {/* <Label className="d-block">Status</Label>
            <Label className="custom-toggle custom-toggle-warning">
              <input
                disabled={viewData}
                type="checkbox"
                name="status"
                onChange={(e) => {
                  setToggle(e.target.checked);
                }}
                // checked={rowValue?.status == "true" ? true : false}
                checked={toggle}
              />
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="Off"
                data-label-on="On"
              />
            </Label>

            <div className="btn-container">
              <Button
                onClick={() => updateUserData()}
                className="w-100 bg-primary text-white"
                disabled={loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </div> */}

            <Formik
              initialValues={{
                name: rowValue?.name || "",
                code: rowValue?.code || "",
              }}
              validationSchema={distSchema}
              onSubmit={(values) => {
                const dto = {
                  name: values.name,
                  code: values.code,
                  status: toggle,
                };
                updateUserData(dto);
              }}
            >
              {({ touched, errors }) => (
                <div>
                  {/* ["ID", "DISTRICT NAME", "DISTRICT CODE", "STATUS"]; */}
                  <Form>
                    <FormGroup>
                      <Label>District Name</Label>
                      <span className="text-danger">*</span>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="name"
                        className={`mt-2 form-control
                          ${touched.name && errors.name ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>District Code</Label>
                      <span className="text-danger">*</span>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="code"
                        className={`mt-2 form-control
                          ${touched.code && errors.code ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="code"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <Label className="d-block">Status</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="status"
                        onChange={(e) => {
                          setToggle(e.target.checked);
                        }}
                        // checked={rowValue?.status == "true" ? true : false}
                        checked={toggle}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                    {!viewData && (
                      <div className="btn-container">
                        <Button
                          type="submit"
                          className="w-100 bg-primary text-white"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Create"}
                        </Button>
                      </div>
                    )}
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        ) : (
          <Formik
            initialValues={{
              name: "",
              code: "",
            }}
            validationSchema={distSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ touched, errors }) => (
              <div>
                {/* ["ID", "DISTRICT NAME", "DISTRICT CODE", "STATUS"]; */}
                <Form>
                  <FormGroup>
                    <Label>District Name</Label>
                    <span className="text-danger">*</span>
                    <Field
                      disabled={viewData}
                      type="text"
                      name="name"
                      className={`mt-2 form-control
                          ${touched.name && errors.name ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage
                      component="div"
                      name="name"
                      className="invalid-feedback"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>District Code</Label>
                    <span className="text-danger">*</span>
                    <Field
                      disabled={viewData}
                      type="text"
                      name="code"
                      className={`mt-2 form-control
                          ${touched.code && errors.code ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage
                      component="div"
                      name="code"
                      className="invalid-feedback"
                    />
                  </FormGroup>
                  {/* <Label className="d-block">Status</Label>
                <Label className="custom-toggle custom-toggle-warning">
                  <input
                    disabled={viewData}
                    type="checkbox"
                    name="status"
                    onChange={(e) => {
                      setToggle(e.target.checked);
                    }}
                    // checked={rowValue?.status == "true" ? true : false}
                    checked={toggle}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="Off"
                    data-label-on="On"
                  />
                </Label> */}
                  {!viewData && (
                    <div className="btn-container">
                      <Button
                        type="submit"
                        className="w-100 bg-primary text-white"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Create"}
                      </Button>
                    </div>
                  )}
                </Form>
              </div>
            )}
          </Formik>
        )}
      </CustomModal>
    </>
  );
};

export default District;
