import {
  Card,
  CardHeader,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
  CardBody,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as FileSaver from "file-saver";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useRef, useState } from "react";
import CustomTable from "components/table/CustomTable";
import { useQuery } from "react-query";
import DataService from "../../../services/requestApi";
import Pagination from "../Pagination";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";
import SampleFile from "../../../assets/samplefile/importuser_sample.xlsx";
import { toast } from "react-toastify";
const UserRole = () => {
  const [modal, setModal] = useState(false);
  const [imgUrl, setImgUrl] = useState(false);
  const [allUserData, setAllUserData] = useState([]);
  const [rowValue, setRowValue] = useState();
  const [isEditData, setIsEditData] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [showPrePage] = useState(10);
  const [viewData, setViewData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [loaderUpload, setLoaderUpload] = useState(false);
  const [file, setFile] = useState();

  const fileInputRef = useRef(null);

  useEffect(() => {
    getUserRole();
  }, []);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const getUserRole = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllRole();
      if (data.message == "Success") {
        setAllUserData(data.data);
        setFilteredData(data.data);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";
  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setImgUrl(img);
  };
  const theading = ["ID", "DEPARTMENT", "TITLE", "STATUS"];
  const tdata = [[8, "Agency Staff"]];
  const data = allUserData?.map((elt, i) => [i, elt.role_name]);
  const downloadHeaders = [["id", "ROLE TITLE"]];
  // const { data, isLoading, error, isError } = useQuery(
  //   "getRoles",
  //   DataService.GetRoles()
  // );
  // console.log(data, isLoading, error);

  const handleSubmit = async (data) => {
    console.log("data", data);
    if (data?.department && data?.title) {
      setLoading(true);
      try {
        const { data: res } = await DataService.AddUserRole(data);
        console.log("res::: ", res);
        if (res?.status_code == 200) {
          toast.success(res?.message);
          setModal(false);
          getUserRole();
        } else {
          setModal(false);
          toast.error(res?.message);
        }
      } catch (error) {
        console.log("error::: ", error);
      }

      setLoading(false);
    } else {
      toast.error("Fill all data!");
    }
  };

  const updateUserRole = (item) => {
    setRowValue(item);
    setToggle(item?.status);
    setModal(true);
  };
  const updateRole = async (data) => {
    console.log("toggle", toggle);
    console.log("rowValue._id", rowValue._id);
    const dto = { status: toggle };
    setLoading(true);
    try {
      const { data: res } = await DataService.UpdateRoleData(
        rowValue._id,
        data
      );
      console.log("res::: ", res);
      if (res?.status_code == 200) {
        toast.success(res?.message);
        setModal(false);
        getUserRole();
      } else {
        setModal(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setLoading(false);
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);

    const res = await DataService.DeleteUserRoleData(item._id);
    if (res.data.message == "Success") {
      alert("True");
      // getUserRole();
    } else {
      alert("False");
    }
  };
  const roleSchema = Yup.object().shape({
    role_name: Yup.string().required("required"),
    department: Yup.string().required("required"),
  });
  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = allUserData.filter((item) => {
      return (
        item?.title?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        item?.department?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  const selectOption = [
    {
      label: "treasury verification",
      value: "treasury verification",
    },
    { label: "center verification", value: "center verification" },
  ];

  const handelUploadExcel = async () => {
    console.log("file", file);
    setLoaderUpload(true);
    const formData = new FormData();
    formData.append("excel", file);
    try {
      if (file) {
        const { data: res } = await DataService.UploadRole(formData);
        console.log("res::: ", res);
        if (res?.status_code == 200) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
        getUserRole();
        setLoaderUpload(false);
        setLoading(false);
        fileInputRef.current.value = null;
      } else {
        toast.error("Please select file!");
        setLoaderUpload(false);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
    setLoading(false);
  };

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  User Role Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setViewData(false);
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />{" "}
              <span className="px-2">Add new</span>
            </Button>
            {/* <Button className="shadow-none" disabled>
              Export to
            </Button> */}
            {/* <Button
              className="p-0"
              onClick={() => tablePdf("User Role", pdfHeaders, data)}
            >
              <img width={20} src={PDF} alt="" />
            </Button> */}
            {/* <Button
              className="p-0"
              onClick={() => exportExcel("UserRole", downloadHeaders, data)}
            >
              <img src={XLS} width={20} alt="" />
            </Button> */}
          </Col>
        </Row>
      </Container>

      {/* upload */}

      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="py-2 px-3 mb-3 bg-light">
                <h3 className="mb-0">Import Csv Data</h3>
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <Col xl="6">
                    <FormGroup className="">
                      <Label>Upload file</Label>
                      <div
                        className="border border-2 py-1 px-2 rounded"
                        style={{ width: 300 }}
                      >
                        <Input
                          type="file"
                          name="upload"
                          id="upload"
                          innerRef={fileInputRef}
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                    </FormGroup>
                    <Button>
                      <a href={SampleFile} className="mt-4">
                        Download Sample file
                      </a>
                    </Button>
                  </Col>
                  <Col xl="6">
                    <div className="btn-container d-flex align-items-end h-100">
                      <Button
                        onClick={() => handelUploadExcel()}
                        disabled={loaderUpload}
                      >
                        {loaderUpload ? "Loading..." : "Upload"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Role</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    {/* <th scope="col" style={{ width: "70%" }}>
              Status
            </th> */}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                {dataLoader ? (
                  <tbody>
                    <tr>
                      <td colSpan={5}>
                        <h5 className="text-center">Loading...</h5>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {filteredData?.length > 0 ? (
                      <>
                        {filteredData
                          ?.slice(pagination.start, pagination.end)
                          ?.map((data, idx) => (
                            <tr key={idx}>
                              <td>{pagination.start + idx + 1}</td>
                              <td>{data?.department || "-"}</td>
                              <td>{data?.title || "-"}</td>
                              <td>{data.status ? "ON" : "OFF" || "-"}</td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        updateUserRole(data);
                                        setIsEditData(false);
                                        setViewData(false);
                                      }}
                                    >
                                      <i className="fas fa-pencil-alt" />
                                      Edit
                                    </DropdownItem>
                                    {/* <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      setModal(!modal);
                                      updateUserRole(data);
                                      setViewData(true);
                                    }}
                                  >
                                    <i className="	fas fa-eye" />
                                    View
                                  </DropdownItem> */}
                                    {/* <DropdownItem
                                    href="#"
                                    // onClick={(e) => e.preventDefault()}
                                    onClick={() => deleteUserRole(data)}
                                  >
                                    <i className="fas fa-trash " />
                                    Delete
                                  </DropdownItem> */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={5}>
                            <h5 className="text-center">No Data Available</h5>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                )}
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <Pagination
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add new role"
      >
        {!isEditData ? (
          <div>
            {/* <Label className="d-block">Status</Label>
            <Label className="custom-toggle custom-toggle-warning">
              <input
                disabled={viewData}
                type="checkbox"
                name="status"
                onChange={(e) => {
                  setToggle(e.target.checked);
                }}
                // checked={rowValue?.status == "true" ? true : false}
                checked={toggle}
              />
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="Off"
                data-label-on="On"
              />
            </Label>

            <div className="btn-container">
              <Button
                onClick={() => updateRole()}
                className="w-100 bg-primary text-white"
                disabled={loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </div> */}
            {console.log("rowValue", rowValue)}

            <Formik
              initialValues={{
                title: rowValue?.title || "",
                department: rowValue?.department || "",
                resources: rowValue?.resources || [],
              }}
              // validationSchema={roleSchema}
              onSubmit={(values) => {
                const dto = {
                  title: values.title,
                  department: values.department,
                  resources: values.resources,
                  status: toggle,
                };
                console.log("dto", dto);
                updateRole(dto);
                // updateRole(merged);
              }}
            >
              {({ touched, errors, values }) => (
                <div>
                  <Form>
                    <FormGroup>
                      <Label>Role Name</Label>
                      <span className="text-danger">*</span>
                      <Field
                        type="text"
                        name="title"
                        className={`mt-2 form-control
                          ${touched.title && errors.title ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="title"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Department</Label>
                      <span className="text-danger">*</span>
                      <Field
                        as="select"
                        name={`department`}
                        className={`mt-2 form-control
                                    ${
                                      touched.department && errors.department
                                        ? "is-invalid"
                                        : ""
                                    }`}
                      >
                        <option value="">--select--</option>
                        {["Police", "Private", "Government"].map((item) => (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        ))}
                      </Field>

                      <ErrorMessage
                        component="div"
                        name="department"
                        className="invalid-feedback"
                      />
                    </FormGroup>

                    <Label>Status</Label>
                    <br />
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="status"
                        onChange={(e) => {
                          setToggle(e.target.checked);
                        }}
                        // checked={rowValue?.status == "true" ? true : false}
                        checked={toggle}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>

                    <FieldArray
                      name="resources"
                      render={(arrayHelpers) => (
                        <div>
                          {values.resources.length == 0 && (
                            <button
                              className="btn btn-info m-1 float-right "
                              type="button"
                              onClick={() => arrayHelpers.insert()} // insert an empty string at a position
                            >
                              Add new resource
                            </button>
                          )}
                          {values.resources.map((resources, index) => (
                            <div key={index}>
                              {/* <Field name={`resources.${index}`} /> */}
                              <div className="form-group">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {index == 0 && (
                                    <label htmlFor="password" className="mt-3">
                                      Resources
                                    </label>
                                  )}
                                  {index == 0 && (
                                    <button
                                      className="btn btn-primary m-1 "
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      } // insert an empty string at a position
                                    >
                                      +
                                    </button>
                                  )}
                                </div>

                                <div className="d-flex">
                                  <Field
                                    as="select"
                                    name={`resources[${index}].resource`}
                                    className={`mt-2 form-control`}
                                  >
                                    <option value="">--select--</option>
                                    {selectOption.map((item) => (
                                      <>
                                        <option value={item.value}>
                                          {item.label}
                                        </option>
                                      </>
                                    ))}
                                  </Field>

                                  <div className="ml-4 mt-1">
                                    <Row>
                                      {[
                                        "create",
                                        "read",
                                        "update",
                                        "delete",
                                      ].map((action) => (
                                        <Col key={action} className=" ">
                                          <Field
                                            type="checkbox"
                                            id={`resources[${index}].actions.${action}`}
                                            name={`resources[${index}].actions`}
                                            value={action}
                                            className="form-check-input"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`resources[${index}].actions.${action}`}
                                          >
                                            {action}
                                          </label>
                                        </Col>
                                      ))}
                                    </Row>
                                  </div>

                                  <button
                                    className="btn btn-danger mx-1"
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                  >
                                    -
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />

                    {!viewData && (
                      <div className="btn-container">
                        <Button
                          type="submit"
                          disabled={loading}
                          className="w-100 bg-primary text-white"
                        >
                          {loading ? "Loading..." : "Update"}
                        </Button>
                      </div>
                    )}
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        ) : (
          <Formik
            initialValues={{
              title: "",
              department: "",
              resources: [],
            }}
            // validationSchema={roleSchema}
            onSubmit={(values) => {
              console.log("values", values);
              handleSubmit(values);
              // updateRole(merged);
            }}
          >
            {({ touched, errors, values }) => (
              <div>
                <Form>
                  <FormGroup>
                    <Label>Role Name</Label>
                    <span className="text-danger">*</span>
                    <Field
                      disabled={viewData}
                      type="text"
                      name="title"
                      className={`mt-2 form-control
                          ${touched.title && errors.title ? "is-invalid" : ""}`}
                    />
                    <ErrorMessage
                      component="div"
                      name="title"
                      className="invalid-feedback"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Department</Label>
                    <span className="text-danger">*</span>
                    {/* <Field
                      disabled={viewData}
                      type="text"
                      name="department"
                      className={`mt-2 form-control
                          ${
                            touched.department && errors.department
                              ? "is-invalid"
                              : ""
                          }`}
                    /> */}
                    <Field
                      as="select"
                      name={`department`}
                      className={`mt-2 form-control
                                    ${
                                      touched.department && errors.department
                                        ? "is-invalid"
                                        : ""
                                    }`}
                    >
                      <option value="">--select--</option>
                      {["Police", "Private", "Government"].map((item) => (
                        <>
                          <option value={item}>{item}</option>
                        </>
                      ))}
                    </Field>

                    <ErrorMessage
                      component="div"
                      name="department"
                      className="invalid-feedback"
                    />
                  </FormGroup>

                  <FieldArray
                    name="resources"
                    render={(arrayHelpers) => (
                      <div>
                        {values.resources.length == 0 && (
                          <button
                            className="btn btn-info m-1 float-right "
                            type="button"
                            onClick={() => arrayHelpers.insert()} // insert an empty string at a position
                          >
                            Add new resource
                          </button>
                        )}
                        {values.resources.map((resources, index) => (
                          <div key={index}>
                            {/* <Field name={`resources.${index}`} /> */}
                            <div className="form-group">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {index == 0 && (
                                  <label htmlFor="password" className="mt-3">
                                    Resources
                                  </label>
                                )}
                                {index == 0 && (
                                  <button
                                    className="btn btn-primary m-1 "
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.insert(index, "")
                                    } // insert an empty string at a position
                                  >
                                    +
                                  </button>
                                )}
                              </div>

                              <div className="d-flex">
                                <Field
                                  as="select"
                                  name={`resources[${index}].resource`}
                                  className={`mt-2 form-control`}
                                >
                                  <option value="">--select--</option>
                                  {selectOption.map((item) => (
                                    <>
                                      <option value={item.value}>
                                        {item.label}
                                      </option>
                                    </>
                                  ))}
                                </Field>

                                <div className="ml-4 mt-1">
                                  <Row>
                                    {["create", "read", "update", "delete"].map(
                                      (action) => (
                                        <Col key={action} className=" ">
                                          <Field
                                            type="checkbox"
                                            id={`resources[${index}].actions.${action}`}
                                            name={`resources[${index}].actions`}
                                            value={action}
                                            className="form-check-input"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`resources[${index}].actions.${action}`}
                                          >
                                            {action}
                                          </label>
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                </div>

                                <button
                                  className="btn btn-danger mx-1"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                  -
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />

                  {!viewData && (
                    <div className="btn-container">
                      <Button
                        type="submit"
                        disabled={loading}
                        className="w-100 bg-primary text-white"
                      >
                        {loading ? "Loading..." : "Create"}
                      </Button>
                    </div>
                  )}
                </Form>
              </div>
            )}
          </Formik>
        )}
      </CustomModal>
    </>
  );
};

export default UserRole;
