import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataService from "../../../../services/requestApi";

export default function ShowMaterialValidationList({ showData }) {
  const [data, setDataList] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTreasuryMaterialVerification();
  }, [showData]);

  const getTreasuryMaterialVerification = async () => {
    setLoading(true);
    try {
      const { data } = await DataService.GetTreasuryMaterialVerification(
        showData
      );
      console.log("data, error::: ", data);
      if (data) {
        setDataList(data?.data);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
    setLoading(false);
  };

  console.log("showData::: ", showData);
  return (
    <div>
      {loading ? (
        "Loading...."
      ) : (
        <Container fluid>
          <Card>
            <CardHeader>VENDOR confidential material receipt</CardHeader>
            <CardBody>
              <Row>
                <Col xl="6">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Capture Date</Label>
                    <Input
                      type="text"
                      placeholder="Capture Date"
                      readOnly
                      value={data?.materialReceiptData?.captureDate}
                    />
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Start Time</Label>
                    <Input
                      type="text"
                      placeholder="Start Time"
                      readOnly
                      value={data?.materialReceiptData?.startTime}
                    />
                  </FormGroup>
                </Col>
                {data?.materialReceiptData?.vehicleData.map((item, index) => (
                  <Col xl="6">
                    <FormGroup className="mb-2">
                      <Label for="exampleSelect">Vehicle Data</Label>

                      <>
                        <Input
                          type="text"
                          placeholder="Vehicle Data"
                          readOnly
                          value={item?.vehicle_no}
                        />
                      </>
                    </FormGroup>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xl="12">
                  <CardHeader>No. of Boxes</CardHeader>
                </Col>
              </Row>
              <Row className="mt-4">
                {data?.materialReceiptData?.seriesData.map((item) => (
                  <>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Series Name</Label>
                        <Input
                          type="text"
                          placeholder="Vehicle Data"
                          readOnly
                          value={item?.series_name}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Box Count</Label>
                        <Input
                          type="text"
                          placeholder="Vehicle Data"
                          readOnly
                          value={item?.box_count}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Stationery Box Count</Label>
                        <Input
                          type="text"
                          placeholder="Vehicle Data"
                          readOnly
                          value={item?.stationery_box_count}
                        />
                      </FormGroup>
                    </Col>
                  </>
                ))}
              </Row>
              <Row className="mt-4">
                <Col xl="4">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Total no. of Vehicle</Label>
                    <Input
                      type="text"
                      placeholder="Total no. of Vehicle"
                      readOnly
                      value={data?.materialReceiptData?.totalVehicles}
                    />
                  </FormGroup>
                </Col>
                <Col xl="4">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">TOTAL NO. OF BOXES</Label>
                    <Input
                      type="text"
                      placeholder="TOTAL NO. OF BOXES"
                      readOnly
                      value={data?.materialReceiptData?.totalBoxes}
                    />
                  </FormGroup>
                </Col>
                <Col xl="4">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Total Stationery Boxes</Label>
                    <Input
                      type="text"
                      placeholder="Total Stationery Boxes"
                      readOnly
                      value={data?.materialReceiptData?.totalStationeryBoxes}
                    />
                  </FormGroup>
                </Col>
                <Col xl="12">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Extra Comment</Label>
                    <Input
                      type="textarea"
                      placeholder="Extra Comment"
                      readOnly
                      value={data?.materialReceiptData?.extraComment}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="mt-4">
            <CardHeader>
              Dispatch of confidential material to the Examination Centre
            </CardHeader>
            <CardBody>
              {data?.materialDispatchData?.centerData?.map((item) => (
                <>
                  <Row key={item.id} className="border p-4">
                    <Col xl="12">
                      <Label for="exampleSelect">
                        Center Code :{item?.center_code}
                      </Label>
                    </Col>
                    <Col xl="3">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Date</Label>
                        <Input
                          type="text"
                          placeholder="Date"
                          readOnly
                          value={item?.date}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Shift</Label>
                        <Input
                          type="text"
                          placeholder="Shift"
                          readOnly
                          value={item?.shift}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Exam Time</Label>
                        <Input
                          type="text"
                          placeholder="Exam Time"
                          readOnly
                          value={item?.exam_time}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Center code</Label>
                        <Input
                          type="text"
                          placeholder="Center code"
                          readOnly
                          value={item?.center_code}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Selected series</Label>
                        <Input
                          type="text"
                          placeholder="Selected series"
                          readOnly
                          value={item?.selected_series}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Selected box count</Label>
                        <Input
                          type="text"
                          placeholder="Selected box count"
                          readOnly
                          value={item?.selected_box_count}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Dispatched</Label>
                        <Input
                          type="text"
                          placeholder="Dispatched"
                          readOnly
                          value={item?.dispatched}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="12">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Extra comment</Label>
                        <Input
                          type="textarea"
                          placeholder="Extra Comment"
                          readOnly
                          value={item?.extra_comment}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ))}
              <Row className="mt-4">
                <Col xl="6">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Total Centers</Label>
                    <Input
                      type="text"
                      placeholder="Total Centers"
                      readOnly
                      value={data?.materialDispatchData?.totalCenters}
                    />
                  </FormGroup>
                </Col>
                <Col xl="6">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Total Boxes</Label>
                    <Input
                      type="text"
                      placeholder="Total Boxes"
                      readOnly
                      value={data?.materialDispatchData?.totalBoxes}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="mt-4">
            <CardHeader>
              Reverse logistic from examination centre to treasury
            </CardHeader>
            <CardBody>
              {data?.materialReverseData?.centerData?.map((item) => (
                <>
                  <Row key={item.id} className="border p-4">
                    <Col xl="12">
                      <Label for="exampleSelect">
                        Center Code :{item?.center_code}
                      </Label>
                    </Col>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Center code</Label>
                        <Input
                          type="text"
                          placeholder="Center code"
                          readOnly
                          value={item?.center_code}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Box 1</Label>
                        <Input
                          type="text"
                          placeholder="Box 1"
                          readOnly
                          value={item?.box_1}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Box 2</Label>
                        <Input
                          type="text"
                          placeholder="Box 2"
                          readOnly
                          value={item?.box_2}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="12">
                      <FormGroup className="mb-2">
                        <Label for="exampleSelect">Extra comment</Label>
                        <Input
                          type="textarea"
                          placeholder="Extra Comment"
                          readOnly
                          value={item?.extra_comment}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ))}
              <Row className="mt-4">
                <Col xl="12">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Total centers</Label>
                    <Input
                      type="text"
                      placeholder="Total centers"
                      readOnly
                      value={data?.materialReverseData?.totalCenters}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
}
