import React from "react";
import { NavLink as NavLinkRRD } from "react-router-dom";
import {
  NavItem,
  NavLink,
  Nav,
  UncontrolledCollapse as Collapsed,
} from "reactstrap";
const DropMenus = ({ data }) => {
  return (
    <NavItem className="navCust mt-2">
      <NavLink
        href={"#" + data.id}
        onClick={(e) => e.preventDefault()}
        data-toggle="collapse"
        className="text-capitalize"
        id={data.id}
      >
        <i className={data.icon} />
        <span
          style={{
            width: "120px",
            whitesSpace: "nowrap",
            textWrap: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {data.name}
        </span>
      </NavLink>
      <Collapsed toggler={"#" + data.id}>
        <Nav className="nav-sm flex-column nav">
          {data.views.map((item, key) => (
            <NavItem key={key}>
              <NavLink
                to={item.layout + item.path}
                tag={NavLinkRRD}
                className="text-capitalize"
              >
                <span
                  style={{
                    width: "130px",
                    whitesSpace: "nowrap",
                    textWrap: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.name}
                </span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Collapsed>
    </NavItem>
  );
};
export default DropMenus;
