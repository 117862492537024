import React from "react";
import { Route, Redirect } from "react-router-dom";

const isAuthenticated = () => {
  return !!sessionStorage.getItem("token");
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/auth/login" />
      )
    }
  />
);

export default PrivateRoute;
