import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
  Table,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useState } from "react";
import CustomTable from "components/table/CustomTable";
const IncompleteProfile = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  const theading = [
    "ID",
    "FIRST NAME",
    "LAST NAME",
    "EMAIL",
    "MOBILE",
    "IS MINOR AGE",
    "VIDEO",
    "AUDIO",
    "BIO METRICS",
    "IRIS",
    "SPECIALLY ABLED",
    "DATE",
  ];
  const tdata = [[]];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Reports
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Candidate Incomplete Profile Reports
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button className="p-0">
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button className="p-0">
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Card className="shadow p-3 mb-3">
          <Row>
            <Col xl="3">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">Name</Label>
                <Input placeholder="Search" />
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label>State</Label>
                <Input name="select" type="select">
                  <option>Select State</option>
                  <option>Andaman and Nicobar Islands</option>
                  <option>Andhra Pradesh</option>
                  <option>Arunachal Pradesh</option>
                  <option>Assam</option>
                  <option>Bihar</option>
                  <option>Chandigarh</option>
                  <option>Chhattisgarh</option>
                  <option>Dadra and Nagar Haveoption</option>
                  <option>Daman and Diu</option>
                  <option>Delhi</option>
                  <option>Goa</option>
                  <option>Gujarat</option>
                  <option>Haryana</option>
                  <option>Himachal Pradesh</option>
                  <option>Jammu and Kashmir</option>
                  <option>Jharkhand</option>
                  <option>Karnataka</option>
                  <option>Kenmore</option>
                  <option>Kerala</option>
                  <option>Lakshadweep</option>
                  <option>Madhya Pradesh</option>
                  <option>Maharashtra</option>
                  <option>Manipur</option>
                  <option>Meghalaya</option>
                  <option>Mizoram</option>
                  <option>Nagaland</option>
                  <option>Narora</option>
                  <option>Natwar</option>
                  <option>Odisha</option>
                  <option>Paschim Medinipur</option>
                  <option>Pondicherry</option>
                  <option>Punjab</option>
                  <option>Rajasthan</option>
                  <option>Sikkim</option>
                  <option>Tamil Nadu</option>
                  <option>Telangana</option>
                  <option>Tripura</option>
                  <option>Uttar Pradesh</option>
                  <option>Uttarakhand</option>
                  <option>Vaishaoption</option>
                  <option>West Bengal</option>
                  <option>South India</option>
                  <option>North Gujrat or Western Rajasthan</option>
                  <option>West India</option>
                  <option>North India</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label>City</Label>
                <Input name="select" type="select"></Input>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label>Is Minor</Label>
                <Input name="select" type="select">
                  <option>Select Is Minor</option>
                  <option>Yes</option>
                  <option>No</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label>Gender</Label>
                <Input name="select" type="select">
                  <option>Select Gender</option>
                  <option>Yes</option>
                  <option>No</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label for="exampleSelect">Cast</Label>
                <Input placeholder="Search" />
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label>Is Specially Abled</Label>
                <Input name="select" type="select">
                  <option>Select Is Specially Abled</option>
                  <option>Yes</option>
                  <option>No</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="3">
              <div className="btn-container d-flex align-items-end justify-content-end h-100">
                <Button>filter</Button>
                <Button>Clear</Button>
              </div>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col>
            <Card className="shadow mb-5">
              <CardHeader className="border-0">
                <h3 className="mb-0">Card tables</h3>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
                showStatus
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={12}>
                      <h5 className="text-center">No Data Available</h5>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Form>
          <FormGroup>
            <Label>User Role*</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>First Name*</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Last Name</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Mobile*</Label>
            <Input type="number" />
          </FormGroup>
          <FormGroup>
            <Label>Password*</Label>
            <Input type="password" />
          </FormGroup>
          <FormGroup>
            <Label>Status</Label>
            <Input type="switch" />
          </FormGroup>
          <FormGroup>
            <Label>User Profile Image</Label>
            <Input
              type="file"
              onChange={(e) => handleFile(e.target?.files[0])}
            />
            {data && <img src={data} width={200} className="p-3" alt="" />}
          </FormGroup>
        </Form>
      </CustomModal>
    </>
  );
};

export default IncompleteProfile;
