import CustomModal from "components/modal/CustomModal";
import React, { useState } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

import * as XLSX from "xlsx";
import * as saveAs from "file-saver";

const CustomTable = ({
  tdata,
  theading,
  showStatus,
  showComment,
  modal,
  setModal,
}) => {
  const [commentModal, setCommentModal] = useState(false);

  const data = [
    {
      Segment: "Government",
      Country: "Canada",
      Product: "Carretera",
      Discount: "None",
    },
    {
      Segment: "Government",
      Country: "Germany",
      Product: "Carretera",
      Discount: "None",
    },
    {
      Segment: "Midmarket",
      Country: "France",
      Product: "Carretera",
      Discount: "None",
    },
  ];

  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";

  function downloadAsExcel() {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = {
      Sheets: {
        data: worksheet,
      },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    console.log(excelBuffer);
    saveAsExcel(excelBuffer, "data_template");
  }

  function saveAsExcel(buffer, filename) {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, filename + EXCEL_EXTENSION);
  }
  return (
    <>
      <Table
        id="mainTable"
        className="align-items-center table-flush"
        responsive
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">
              <FormGroup check inline>
                <Input type="checkbox" />
              </FormGroup>
            </th>
            {theading?.map((data, index) => (
              <th scope="col" key={index}>
                {data}
              </th>
            ))}
            {/* <th scope="col" style={{ width: "70%" }}>
              Status
            </th> */}
            <th scope="col" className="text-right">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {tdata.map((data, idx) => (
            <tr key={idx}>
              <th scope="row">
                <FormGroup check inline>
                  <Input type="checkbox" />
                </FormGroup>
              </th>
              {data.map((rowdata, ind) => (
                <td key={ind}>{rowdata}</td>
              ))}
              {showStatus && (
                <td>
                  <Label className="custom-toggle custom-toggle-warning">
                    <Input type="checkbox" />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="Off"
                      data-label-on="On"
                    />
                  </Label>
                </td>
              )}
              {showComment && (
                <td>
                  <Button
                    className="shadow-none p-0 text-primary"
                    onClick={() => setCommentModal(!commentModal)}
                  >
                    View
                  </Button>
                </td>
              )}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="fas fa-pencil-alt" />
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="	fas fa-eye" />
                      View
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-trash " />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
          {tdata.map((data, idx) => (
            <tr key={idx}>
              <th scope="row">
                <FormGroup check inline>
                  <Input type="checkbox" />
                </FormGroup>
              </th>
              {data.map((rowdata, ind) => (
                <td key={ind}>{rowdata}</td>
              ))}
              {showStatus && (
                <td>
                  <Label className="custom-toggle custom-toggle-warning">
                    <Input type="checkbox" />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="Off"
                      data-label-on="On"
                    />
                  </Label>
                </td>
              )}
              {showComment && (
                <td>
                  <Button
                    className="shadow-none p-0 text-primary"
                    onClick={() => setCommentModal(!commentModal)}
                  >
                    View
                  </Button>
                </td>
              )}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="fas fa-pencil-alt" />
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="	fas fa-eye" />
                      View
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-trash " />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
          {tdata.map((data, idx) => (
            <tr key={idx}>
              <th scope="row">
                <FormGroup check inline>
                  <Input type="checkbox" />
                </FormGroup>
              </th>
              {data.map((rowdata, ind) => (
                <td key={ind}>{rowdata}</td>
              ))}
              {showStatus && (
                <td>
                  <Label className="custom-toggle custom-toggle-warning">
                    <Input type="checkbox" />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="Off"
                      data-label-on="On"
                    />
                  </Label>
                </td>
              )}
              {showComment && (
                <td>
                  <Button
                    className="shadow-none p-0 text-primary"
                    onClick={() => setCommentModal(!commentModal)}
                  >
                    View
                  </Button>
                </td>
              )}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="fas fa-pencil-alt" />
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="	fas fa-eye" />
                      View
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-trash " />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
          {tdata.map((data, idx) => (
            <tr key={idx}>
              <th scope="row">
                <FormGroup check inline>
                  <Input type="checkbox" />
                </FormGroup>
              </th>
              {data.map((rowdata, ind) => (
                <td key={ind}>{rowdata}</td>
              ))}
              {showStatus && (
                <td>
                  <Label className="custom-toggle custom-toggle-warning">
                    <Input type="checkbox" />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="Off"
                      data-label-on="On"
                    />
                  </Label>
                </td>
              )}
              {showComment && (
                <td>
                  <Button
                    className="shadow-none p-0 text-primary"
                    onClick={() => setCommentModal(!commentModal)}
                  >
                    View
                  </Button>
                </td>
              )}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="fas fa-pencil-alt" />
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="	fas fa-eye" />
                      View
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-trash " />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
          {tdata.map((data, idx) => (
            <tr key={idx}>
              <th scope="row">
                <FormGroup check inline>
                  <Input type="checkbox" />
                </FormGroup>
              </th>
              {data.map((rowdata, ind) => (
                <td key={ind}>{rowdata}</td>
              ))}
              {showStatus && (
                <td>
                  <Label className="custom-toggle custom-toggle-warning">
                    <Input type="checkbox" />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="Off"
                      data-label-on="On"
                    />
                  </Label>
                </td>
              )}
              {showComment && (
                <td>
                  <Button
                    className="shadow-none p-0 text-primary"
                    onClick={() => setCommentModal(!commentModal)}
                  >
                    View
                  </Button>
                </td>
              )}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="fas fa-pencil-alt" />
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="	fas fa-eye" />
                      View
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-trash " />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
          {tdata.map((data, idx) => (
            <tr key={idx}>
              <th scope="row">
                <FormGroup check inline>
                  <Input type="checkbox" />
                </FormGroup>
              </th>
              {data.map((rowdata, ind) => (
                <td key={ind}>{rowdata}</td>
              ))}
              {showStatus && (
                <td>
                  <Label className="custom-toggle custom-toggle-warning">
                    <Input type="checkbox" />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="Off"
                      data-label-on="On"
                    />
                  </Label>
                </td>
              )}
              {showComment && (
                <td>
                  <Button
                    className="shadow-none p-0 text-primary"
                    onClick={() => setCommentModal(!commentModal)}
                  >
                    View
                  </Button>
                </td>
              )}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="fas fa-pencil-alt" />
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="	fas fa-eye" />
                      View
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="fas fa-trash " />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
          {tdata.map((data, idx) => (
            <tr key={idx}>
              <th scope="row">
                <FormGroup check inline>
                  <Input type="checkbox" />
                </FormGroup>
              </th>
              {data.map((rowdata, ind) => (
                <td key={ind}>{rowdata}</td>
              ))}
              {showStatus && (
                <td>
                  <Label className="custom-toggle custom-toggle-warning">
                    <Input type="checkbox" />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="Off"
                      data-label-on="On"
                    />
                  </Label>
                </td>
              )}
              {showComment && (
                <td>
                  <Button
                    className="shadow-none p-0 text-primary"
                    onClick={() => setCommentModal(!commentModal)}
                  >
                    View
                  </Button>
                </td>
              )}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="fas fa-pencil-alt" />
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => setModal(!modal)}
                    >
                      <i className="	fas fa-eye" />
                      View
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-trash " />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
          {/* <tr>
            <th scope="row">
              <FormGroup check inline>
                <Input type="checkbox" />
              </FormGroup>
            </th>
            <td>7</td>
            <td>Third Party Staff</td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <FormGroup check inline>
                <Input type="checkbox" />
              </FormGroup>
            </th>
            <td>6</td>
            <td>Center Head</td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <FormGroup check inline>
                <Input type="checkbox" />
              </FormGroup>
            </th>
            <td>5</td>
            <td>Support Staff</td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <FormGroup check inline>
                <Input type="checkbox" />
              </FormGroup>
            </th>
            <td>4</td>
            <td>Invigilator</td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <FormGroup check inline>
                <Input type="checkbox" />
              </FormGroup>
            </th>
            <td>2</td>
            <td>Observer</td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <FormGroup check inline>
                <Input type="checkbox" />
              </FormGroup>
            </th>
            <td>1</td>
            <td>Administrator</td>
            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    View
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr> */}
        </tbody>
      </Table>
      <CustomModal
        open={commentModal}
        toggleModal={() => setCommentModal(!commentModal)}
        title="Comments"
      >
        <p>No Comments Found</p>
      </CustomModal>
    </>
  );
};

export default CustomTable;
