/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Link } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

const Header = ({ show, title, breadTitle }) => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}

            <Row>
              <Col lg="12" xl="12">
                <div className="bread mb-3 d-flex flex-column">
                  <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                    {title}
                  </h6>
                  <Breadcrumb className="d-none d-md-inline-block">
                    <BreadcrumbItem>
                      <Link to="/admin/index">
                        <i className="fas fa-home"></i>
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {breadTitle}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
