import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Table,
  InputGroupText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// core components

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";
import CustomTable from "components/table/CustomTable";
import { STATECITIES } from "./StateCitiesObject";

import DataService from "../../../services/requestApi";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";

import PaginationData from "../Pagination";
const ExamCenter = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const [rowValue, setRowValue] = useState();
  const [viewData, setViewData] = useState(false);
  const [isEditData, setIsEditData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [examCategory, setExamCategory] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [allState, setAllState] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [showPrePage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  useEffect(() => {
    getUsers();
    getExamCategory();
    getStates();
    getCities();
    state();
  }, []);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const getUsers = async () => {
    const { data } = await DataService.GetExamCenter();
    setTableData(data.examCenter);
    setFilteredData(data.examCenter);
  };
  const getExamCategory = async () => {
    const { data } = await DataService.GetExamCategory();
    setExamCategory(data.examMaster);
  };
  const getStates = async () => {
    const { data } = await DataService.GetStates();
    setStates(data.states);
  };
  const getCities = async () => {
    const { data } = await DataService.GetCities();
    setCities(data.cities);
  };

  const state = () => {
    const allKeys = Object.keys(STATECITIES[0]);
    setAllState(allKeys);
  };
  const citiesValue = (e) => {
    setCitiesArray(STATECITIES[0][e.target.value]);
  };

  const theading = ["ID", "NAME", "CODE", "STATUS"];
  const tdata = [[583, "SHAILENDRA EDUCATION SOCIETY", ""]];
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    codes: Yup.string().required("required"),
    zone: Yup.number().required("required"),
    mobile: Yup.string().required("required"),
    city_name: Yup.string().required("required"),
    exam_id: Yup.string().required("required"),
    longitude: Yup.string().required("required"),
    latitude: Yup.string().required("required"),
    landmark: Yup.string().required("required"),
    pincode: Yup.string().required("required"),
    type: Yup.string().required("required"),
    property: Yup.string().required("required"),
    state_name: Yup.string().required("required"),
    address: Yup.string().required("required"),
  });
  const handleSubmit = async (data) => {
    const res = await DataService.CreateExamCenter(data);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getUsers();
    } else {
      alert("False");
    }
  };
  const updateUserRole = (item) => {
    setRowValue(item);
    setModal(true);
  };
  const updateUserData = async (data) => {
    const res = await DataService.UpdateExamCenter(rowValue._id, data);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getUsers();
    } else {
      alert("False");
    }
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteExamCenter(item._id);
    if (res.data.message == "Success") {
      alert("True");
      getUsers();
    } else {
      alert("False");
    }
  };
  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = tableData.filter((item) => {
      return item.name.includes(searchQuery);
    });
    setFilteredData(filtered);
  };
  const downloadData = filteredData?.map((elt, i) => [
    i,
    elt.name,
    elt.code,
    elt.status,
  ]);
  const downloadHeaders = [["ID", "TITLE", "CODE", "STATUS"]];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Exam Centers Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setViewData(false);
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                tablePdf("Exam Center", downloadHeaders, downloadData)
              }
            >
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                exportExcel("Exam Center", downloadHeaders, downloadData)
              }
            >
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Card tables</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    <>
                      {filteredData
                        ?.slice(pagination.start, pagination.end)
                        ?.map((data, idx) => (
                          <tr key={idx}>
                            <th scope="row">
                              <FormGroup check inline>
                                <Input type="checkbox" />
                              </FormGroup>
                            </th>

                            <td>{idx + 1}</td>
                            <td>{data?.name || "-"}</td>
                            <td>{data?.code || "-"}</td>

                            <td>{data.status ? "ON" : "OFF" || "-"}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() => {
                                      updateUserRole(data);
                                      setViewData(false);
                                      setIsEditData(false);
                                    }}
                                  >
                                    <i className="fas fa-pencil-alt" />
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    href=""
                                    onClick={() => {
                                      setModal(!modal);
                                      updateUserRole(data);
                                      setViewData(true);
                                    }}
                                  >
                                    <i className="	fas fa-eye" />
                                    View
                                  </DropdownItem>
                                  <DropdownItem
                                    href=""
                                    // onClick={(e) => e.preventDefault()}
                                    onClick={() => deleteUserRole(data)}
                                  >
                                    <i className="fas fa-trash " />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <>
                      {" "}
                      <tr>
                        <td colSpan={6}>
                          <h5 className="text-center">No Data Available</h5>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <PaginationData
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Formik
          initialValues={{
            name: rowValue ? rowValue?.name : "",
            exam_id: rowValue ? rowValue?.exam_id : "",
            codes: rowValue ? rowValue?.code : "",
            mobile: rowValue ? rowValue?.mobile : "",
            zone: rowValue ? rowValue?.zone : 0,
            city_name: rowValue ? rowValue?.city_name : "",
            state_name: rowValue ? rowValue?.state_name : "",
            address: rowValue ? rowValue?.address : "",
            latitude: rowValue ? rowValue?.latitude : "",
            longitude: rowValue ? rowValue?.longitude : "",
            landmark: rowValue ? rowValue?.landmark : "",
            pincode: rowValue ? rowValue?.pincode : "",
            type: rowValue ? rowValue?.type : "",
            property: rowValue ? rowValue?.property : "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const onOffToggle = { status: toggle };
            let merged = {
              ...values,
              ...onOffToggle,
              code: values.codes,
              zone: parseInt(values.zone),
            };
            console.log("merged::: ", merged);
            isEditData ? handleSubmit(merged) : updateUserData(merged);
          }}
        >
          {({ touched, errors, setFieldValue }) => (
            <div>
              <Form>
                <Row>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Name<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="name"
                        className={` form-control
                          ${touched.name && errors.name ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Exam Category<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="exam_id"
                        className={` form-control
                          ${
                            touched.exam_id && errors.exam_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select Exam Category .. </option>
                        {examCategory?.map((item) => (
                          <>
                            <option value={item._id}>{item.title}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="exam_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Code<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="codes"
                        className={` form-control
                          ${touched.codes && errors.codes ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="codes"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Center Mobile<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="mobile"
                        className={` form-control
                          ${
                            touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="mobile"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Zone<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="zone"
                        className={` form-control
                          ${touched.zone && errors.zone ? "is-invalid" : ""}`}
                      >
                        <option value="">Select Zone .. </option>
                        {["Central", "East", "North", "West", "South"]?.map(
                          (item, inx) => (
                            <>
                              <option value={inx + 1}>{item}</option>
                            </>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="zone"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        State<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="state_name"
                        onChange={(e) => {
                          citiesValue(e);
                          setFieldValue("state_name", e.target.value);
                        }}
                        className={`form-control
                          ${
                            touched.state_name && errors.state_name
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select states .. </option>
                        {allState?.map((item) => (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="state_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        City<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="city_name"
                        onChange={(e) =>
                          setFieldValue("city_name", e.target.value)
                        }
                        className={` form-control
                          ${
                            touched.city_name && errors.city_name
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select cities ... </option>
                        {citiesArray?.map((item) => (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="city_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Address<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="select"
                        name="address"
                        className={` form-control
                          ${
                            touched.address && errors.address
                              ? "is-invalid"
                              : ""
                          }`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="address"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Latitude<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="latitude"
                        className={` form-control
                          ${
                            touched.latitude && errors.latitude
                              ? "is-invalid"
                              : ""
                          }`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="latitude"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Longitude<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="longitude"
                        className={` form-control
                          ${
                            touched.longitude && errors.longitude
                              ? "is-invalid"
                              : ""
                          }`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="longitude"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Landmark<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="landmark"
                        className={` form-control
                          ${
                            touched.landmark && errors.landmark
                              ? "is-invalid"
                              : ""
                          }`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="landmark"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Pincode<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="number"
                        name="pincode"
                        className={` form-control
                          ${
                            touched.pincode && errors.pincode
                              ? "is-invalid"
                              : ""
                          }`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="pincode"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Type<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="type"
                        className={` form-control
                          ${touched.type && errors.type ? "is-invalid" : ""}`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="type"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Property<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="property"
                        className={` form-control
                          ${
                            touched.property && errors.property
                              ? "is-invalid"
                              : ""
                          }`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="property"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <Label className="d-block">Status</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="status"
                        onChange={(e) => {
                          setToggle(e.target.checked);
                        }}
                        defaultChecked={rowValue?.status}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>User Profile Image</Label>
                      <Field
                        disabled={viewData}
                        type="file"
                        name="code"
                        onChange={(e) => handleFile(e.target?.files[0])}
                        className={` form-control
                          ${touched.code && errors.code ? "is-invalid" : ""}`}
                      ></Field>
                      <ErrorMessage
                        component="div"
                        name="code"
                        className="invalid-feedback"
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                {!viewData && (
                  <div className="btn-container">
                    <Button
                      type="submit"
                      className="w-100 bg-primary text-white"
                    >
                      {isEditData ? "Create" : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default ExamCenter;
