import React, { useEffect, useState } from "react";
import { STATECITIES } from "./StateCitiesObject";
import { Field } from "formik";
import { Row, Col, FormGroup, Label } from "reactstrap";

export default function CitiesStateDate({
  cityShow,
  index,
  viewData,
  setFieldValue,
  values,
}) {
  useEffect(() => {
    state();
    console.log("values.::: ", values.stateCityDate[index].state_name);
    if (cityShow) {
      setCitiesArray(STATECITIES[0][values.stateCityDate[index].state_name]);
    }
  }, [values]);
  const [allState, setAllState] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);

  const state = () => {
    const allKeys = Object.keys(STATECITIES[0]);
    setAllState(allKeys);
  };
  const citiesValue = (e) => {
    setCitiesArray(STATECITIES[0][e.target.value]);
  };
  return (
    <>
      <Col xl="6">
        <FormGroup>
          <Label>
            State
            <span className="text-danger">*</span>
          </Label>

          <Field
            disabled={viewData}
            as="select"
            type="select"
            name={`stateCityDate[${index}].state_name`}
            onChange={(e) => {
              citiesValue(e);
              setFieldValue(
                `stateCityDate[${index}].state_name`,
                e.target.value
              );
            }}
            className={`form-control`}
          >
            <option value="">Select states .. </option>
            {allState?.map((item) => (
              <>
                <option value={item}>{item}</option>
              </>
            ))}
          </Field>
        </FormGroup>
      </Col>
      <Col xl="6">
        <FormGroup>
          <Label>
            City<span className="text-danger">*</span>
          </Label>
          <Field
            disabled={viewData}
            as="select"
            type="select"
            name={`stateCityDate[${index}].city_name`}
            className={` form-control`}
          >
            <option value="">Select cities ... </option>
            {citiesArray?.map((item) => (
              <>
                <option value={item}>{item}</option>
              </>
            ))}
          </Field>
        </FormGroup>
      </Col>
    </>
  );
}
