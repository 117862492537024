import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/plugins/nucleo/css/nucleo.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import "./assets/scss/custom.scss";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/index.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./PrivateRoute";
import App from "App";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ToastContainer />
    <BrowserRouter>
      {/* <Switch>
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Redirect from="*" to="/auth/login" />
      </Switch> */}
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);
