import React, { useEffect, useState } from "react";
import DataService from "../../../services/requestApi";
import {
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Button,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Input,
} from "reactstrap";
import { FieldArray, Formik, Field } from "formik";
import { Link, useParams } from "react-router-dom";
import CustomModal from "components/modal/CustomModal";
import { toast } from "react-toastify";

export default function FormJson() {
  const [fields, setFields] = useState([]);
  const productSlug = useParams();
  console.log("productSlug::: ", productSlug);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [firstIndex, setFirstIndex] = useState(0);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allForms, setAllForms] = useState([]);
  const [selectForm, setSelectForm] = useState("");
  const [newField, setNewField] = useState({
    key: "",
    titleEnglish: "",
    titleHindi: "",
  });

  // useEffect(() => {
  //   getAllForm();
  // }, []);

  const getAllForm = async (id) => {
    console.log("id::: ", id);
    setSelectForm(id);
    setLoading(true);
    try {
      const { data } = await DataService.GetAllFormJson(id);
      console.log("data::: ", data);
      if (data.message === "Success") {
        setFields(data?.data?.formData);
        setLoading(false);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const handleAddField = (arrayHelpers) => {
    const updatedFields = [...fields];
    updatedFields.push(newField);

    arrayHelpers.push(newField);
    setFields(updatedFields);
    setModal(false);
    setIsEdit(true);
    setNewField({ key: "", titleEnglish: "", titleHindi: "" });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setNewField({ ...newField, [name]: value });
  };

  const editItem = (data, i) => {
    setFirstIndex(i);
    setRowData(data);
    setIsEdit(!isEdit);
  };

  const handleAddVerificationInput = (index, secondIndex) => {
    const updatedFields = [...fields];
    updatedFields[firstIndex].verification[secondIndex].input = {
      key: "",
      title: "",
      type: "",
    };
    setFields(updatedFields);
  };

  const handleAddVerificationInputDelete = (index, secondIndex) => {
    const updatedFields = [...fields];
    delete updatedFields[index].verification[secondIndex].input;
    setFields(updatedFields);
  };

  const handelSelectType = (index, secondIndex, type) => {
    const updatedFields = [...fields];

    updatedFields[firstIndex].verification[secondIndex].input.type = type;

    if (
      updatedFields[firstIndex].verification[secondIndex].input.type ==
        "multiple_option" ||
      updatedFields[firstIndex].verification[secondIndex].input.type == "radio"
    ) {
      updatedFields[firstIndex].verification[secondIndex].input.options =
        updatedFields[firstIndex].verification[secondIndex].input.options || [
          {
            key: "",
            title: "",
          },
        ];
    }
  };

  const handelAddNewOptions = (index, secondIndex, optionIndex) => {
    const updatedFields = [...fields];

    updatedFields[index].verification[secondIndex].input.options.push({
      key: "",
      title: "",
    });
    setFields(updatedFields);
  };

  const handelDeleteOptions = (index, secondIndex, optionIndex) => {
    const updatedFields = [...fields];
    updatedFields[index].verification[secondIndex].input.options =
      updatedFields[index].verification[secondIndex].input.options.filter(
        (_, idx) => idx !== optionIndex
      );
    setFields(updatedFields);
  };

  const handelExtraptions = (firstIndex, index, type) => {
    const updatedFields = [...fields];
    updatedFields[firstIndex].extra[index].type = type;

    if (
      updatedFields[firstIndex].extra[index].type == "multiple_option" ||
      updatedFields[firstIndex].extra[index].type == "radio"
    ) {
      updatedFields[firstIndex].extra[index].options = updatedFields[firstIndex]
        .extra[index].options || [
        {
          key: "",
          title: "",
        },
      ];
    }
  };

  const handelAddNewOptionsInExtra = (firstIndex, index) => {
    const updatedFields = [...fields];

    updatedFields[firstIndex].extra[index].options.push({
      key: "",
      title: "",
    });
    setFields(updatedFields);
  };

  const handelDeleteOptionsInExtra = (firstIndex, index, optionIndex) => {
    const updatedFields = [...fields];
    updatedFields[firstIndex].extra[index].options = updatedFields[
      firstIndex
    ].extra[index].options.filter((_, idx) => idx !== optionIndex);
    setFields(updatedFields);
  };

  const handelAddNewExtra = () => {
    const updatedFields = [...fields];

    if (!updatedFields[firstIndex]?.extra) {
      updatedFields[firstIndex].extra = [];
    }
    updatedFields[firstIndex]?.extra.push({
      key: "",
      title: "",
      type: "",
      mandatory: "",
    });

    setFields(updatedFields);
  };

  const handelRemoveNewExtra = (firstIndex, index) => {
    const updatedFields = [...fields];
    updatedFields[firstIndex].extra = updatedFields[firstIndex].extra.filter(
      (_, idx) => idx !== index
    );
    setFields(updatedFields);
  };

  const handelAddNewVerification = () => {
    const updatedFields = [...fields];
    if (!updatedFields[firstIndex]?.verification) {
      updatedFields[firstIndex].verification = [];
    }
    updatedFields[firstIndex]?.verification.push({
      key: "",
      title: "",
      value: "",
      border: "",
    });
    updatedFields[firstIndex]?.verification.push({
      key: "",
      title: "",
      value: "",
      border: "",
    });

    setFields(updatedFields);
  };

  const handleDeleteField = async (arrayHelpers, index) => {
    const filter = fields.filter((item, idx) => idx !== index);
    arrayHelpers.remove(index);
    console.log("filter::: ", filter);
    console.log("selectForm handleDeleteField::: ", selectForm);
    try {
      const { data: res } = await DataService.UpdateCenterJson(selectForm, {
        formData: filter,
      });
      console.log("res::: ", res);
      if (res?.message == "Success") {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
    getAllForm(selectForm);
    setFields(filter);
  };

  useEffect(() => {
    getAllForms();
  }, []);

  const getAllForms = async () => {
    setLoading(true);
    try {
      const { data } = await DataService.GetAllForms();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllForms(data?.data);
        setSelectForm(data?.data[0]?._id);
        setFields(data?.data[0]?.formData);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setLoading(false);
  };

  console.log("fields::: ", fields);
  return (
    <Container fluid className="pt-5">
      {!loading && (
        <Formik
          initialValues={{
            fields: fields || [],
          }}
          onSubmit={async (values) => {
            console.log("Form Values:", values.fields[firstIndex]);
            values.fields[firstIndex]?.verification?.map((item, index) => {
              if (item?.input) {
                if (
                  item?.input?.type == "number" ||
                  item?.input?.type == "text" ||
                  item?.input?.type == "longtext"
                ) {
                  delete item?.input?.options;
                  delete item?.input?.qty;
                }
                if (item?.input?.type == "image") {
                  delete item?.input?.options;
                }
              }
            });

            values.fields[firstIndex]?.extra?.map((item) => {
              if (
                item?.type == "number" ||
                item?.type == "text" ||
                item?.type == "longtext"
              ) {
                delete item?.options;
                delete item?.qty;
              }
              if (item?.type == "image") {
                delete item?.options;
              }
            });

            setButtonLoader(true);
            console.log("selectForm in formik::: ", selectForm, values.fields);
            try {
              const { data: res } = await DataService.UpdateCenterJson(
                selectForm,
                {
                  formData: values.fields,
                }
              );
              console.log("res::: ", res);
              if (res?.message == "Success") {
                toast.success(res?.message);
              } else {
                toast.error(res?.message);
              }
            } catch (error) {
              console.log("error::: ", error);
            }
            setIsEdit(false);
            setButtonLoader(false);

            console.log("Form Values2:", values.fields);
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <FieldArray name="fields">
                {(arrayHelpers) => (
                  <div>
                    <Row>
                      <Col lg="8">
                        <div className="bread mb-3 d-flex flex-column">
                          <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                            Form JSON
                          </h6>
                          <Breadcrumb className="d-none d-md-inline-block">
                            <BreadcrumbItem>
                              {isEdit ? (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setIsEdit(!isEdit);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="blue"
                                    class="bi bi-box-arrow-in-left"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0z"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <Link to="/admin/index">
                                  <i className="fas fa-home"></i>
                                </Link>
                              )}
                            </BreadcrumbItem>
                            <BreadcrumbItem
                              aria-current="page"
                              className="active"
                            >
                              Form JSON
                            </BreadcrumbItem>
                          </Breadcrumb>
                        </div>
                      </Col>
                      <Col lg="4" className=" text-right">
                        {!isEdit && (
                          <Button
                            className="p-1 d-inline-flex justify-content-center align-items-center"
                            onClick={() => setModal(true)}
                          >
                            <i
                              className="ni ni-fat-add"
                              style={{ fontSize: 30 }}
                            />{" "}
                            <span className="px-2">Add new</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {!isEdit && (
                      <Row>
                        <Col md={4}>
                          <FormGroup>
                            <Label>Select Form</Label>
                            {console.log("selectForm::: ", selectForm)}
                            <Input
                              name="select"
                              type="select"
                              value={selectForm}
                              onChange={(e) => {
                                getAllForm(e?.target?.value);
                              }}
                            >
                              {allForms?.map((item, i) => (
                                <>
                                  <option value={item?._id}>
                                    {item?.title}
                                  </option>
                                </>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    {!isEdit ? (
                      <Card>
                        <Table
                          id="mainTable"
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Key</th>
                              <th scope="col">title English</th>
                              <th scope="col">Title Hindi</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.fields.map((data, idx) => (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{data.key || "---"}</td>
                                <td>
                                  <div
                                    className="truncate m-0 p-0 "
                                    style={{ fontSize: "0.8125rem" }}
                                  >
                                    {data.titleEnglish || "---"}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="truncate m-0 p-0 "
                                    style={{ fontSize: "0.8125rem" }}
                                  >
                                    {data.titleHindi || "---"}
                                  </div>
                                </td>
                                <td>
                                  <Button
                                    color="primary"
                                    size="sm"
                                    outline
                                    onClick={() => editItem(data, idx)}
                                  >
                                    <i className="fas fa-pencil-alt mr-2" />
                                    Edit
                                  </Button>
                                  <Button
                                    outline
                                    size="sm"
                                    color="danger"
                                    onClick={async () => {
                                      console.log(
                                        "values.fields::: ",
                                        values.fields
                                      );
                                      handleDeleteField(arrayHelpers, idx);
                                    }}
                                  >
                                    <i className="fas fa-trash  mr-2" />
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card>
                    ) : (
                      <>
                        {/* Basic info */}
                        <Card>
                          <CardHeader>Basic Info</CardHeader>
                          <CardBody>
                            {Object.entries(rowData).map(([key, value], i) => (
                              <>
                                {typeof value !== "object" &&
                                  !Array.isArray(value) && (
                                    <>
                                      <Label
                                        for={key}
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                        className="mt-2"
                                      >
                                        {key?.replace(
                                          /([a-z])([A-Z])/g,
                                          "$1 $2"
                                        )}
                                      </Label>
                                      <Field
                                        name={`fields[${firstIndex}].${key}`}
                                        className="form-control"
                                        type="text"
                                        style={{ color: "black" }}
                                      />
                                    </>
                                  )}
                              </>
                            ))}
                          </CardBody>
                        </Card>

                        <Row>
                          {/* Verification */}
                          <Col lg={6}>
                            <Card className="mt-2">
                              <CardHeader>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Verification</span>
                                  {fields[firstIndex]?.verification?.length !=
                                    2 && (
                                    <Button
                                      outline
                                      color="primary"
                                      onClick={() => handelAddNewVerification()}
                                    >
                                      <i className="ni ni-fat-add  mr-2" />
                                      Add new
                                    </Button>
                                  )}
                                </div>
                              </CardHeader>
                              {fields[firstIndex].verification &&
                                fields[firstIndex].verification.length > 0 && (
                                  <CardBody>
                                    {Object.entries(fields[firstIndex]).map(
                                      ([keys, value], i) => (
                                        <>
                                          {Array.isArray(value) && (
                                            <>
                                              {keys === "verification" && (
                                                <div>
                                                  {value?.map((data, index) => (
                                                    <div
                                                      key={index}
                                                      className="border p-4 shadow mt-2"
                                                    >
                                                      <Row className="mb-2">
                                                        <Col lg={6}>
                                                          <Label>Key</Label>

                                                          <Field
                                                            name={`fields[${firstIndex}].verification[${index}].key`}
                                                            className="form-control"
                                                            type="text"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          />
                                                        </Col>
                                                        <Col lg={6}>
                                                          <Label
                                                            for={`verification[${index}].title`}
                                                          >
                                                            Title
                                                          </Label>
                                                          <Field
                                                            name={`fields[${firstIndex}].verification[${index}].title`}
                                                            className="form-control"
                                                            type="text"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>

                                                      <Row className="mb-2">
                                                        <Col lg={6}>
                                                          <Label
                                                            for={`verification[${index}].value`}
                                                          >
                                                            Value
                                                          </Label>
                                                          <Field
                                                            name={`fields[${firstIndex}].verification[${index}].value`}
                                                            className="form-control"
                                                            type="text"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          />
                                                        </Col>
                                                        <Col lg={6}>
                                                          <Label
                                                            for={`verification[${index}].border`}
                                                          >
                                                            Border
                                                          </Label>
                                                          <Field
                                                            name={`fields[${firstIndex}].verification[${index}].border`}
                                                            className="form-control"
                                                            type="text"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <div
                                                        className={`${
                                                          data?.input
                                                            ? "border p-4 shadow"
                                                            : ""
                                                        }`}
                                                      >
                                                        {data?.input && (
                                                          <Row className="mb-2">
                                                            {Object.entries(
                                                              data?.input
                                                            ).map(
                                                              (
                                                                [
                                                                  inputKey,
                                                                  value,
                                                                ],
                                                                i
                                                              ) => (
                                                                <>
                                                                  <>
                                                                    {Array.isArray(
                                                                      value
                                                                    ) &&
                                                                      (fields[
                                                                        firstIndex
                                                                      ]
                                                                        ?.verification[
                                                                        index
                                                                      ]?.input
                                                                        ?.type ===
                                                                        "multiple_option" ||
                                                                        fields[
                                                                          firstIndex
                                                                        ]
                                                                          ?.verification[
                                                                          index
                                                                        ].input
                                                                          ?.type ===
                                                                          "radio") &&
                                                                      value?.map(
                                                                        (
                                                                          item,
                                                                          optionIndex
                                                                        ) => (
                                                                          <>
                                                                            {optionIndex ==
                                                                              0 && (
                                                                              <Col
                                                                                lg={
                                                                                  12
                                                                                }
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  justifyContent:
                                                                                    "end",
                                                                                }}
                                                                              >
                                                                                <Button
                                                                                  outline
                                                                                  size="sm"
                                                                                  color="primary"
                                                                                  onClick={() => {
                                                                                    handelAddNewOptions(
                                                                                      firstIndex,
                                                                                      index,
                                                                                      optionIndex
                                                                                    );
                                                                                  }}
                                                                                  className="mt-2"
                                                                                >
                                                                                  <i className="ni ni-fat-add  mr-2" />
                                                                                  Add
                                                                                  new
                                                                                </Button>
                                                                              </Col>
                                                                            )}

                                                                            <Col
                                                                              lg={
                                                                                5
                                                                              }
                                                                            >
                                                                              <Label className="mt-2">
                                                                                key
                                                                              </Label>
                                                                              <Field
                                                                                name={`fields[${firstIndex}].verification[${index}].input.${inputKey}[${optionIndex}].key`}
                                                                                className="form-control"
                                                                                type="text"
                                                                                style={{
                                                                                  color:
                                                                                    "black",
                                                                                }}
                                                                              />
                                                                            </Col>
                                                                            <Col
                                                                              lg={
                                                                                6
                                                                              }
                                                                            >
                                                                              <Label className="mt-2">
                                                                                Title
                                                                              </Label>
                                                                              <Field
                                                                                name={`fields[${firstIndex}].verification[${index}].input.${inputKey}[${optionIndex}].title`}
                                                                                className="form-control"
                                                                                type="text"
                                                                                style={{
                                                                                  color:
                                                                                    "black",
                                                                                }}
                                                                              />
                                                                            </Col>
                                                                            <Col
                                                                              lg={
                                                                                1
                                                                              }
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "end",
                                                                              }}
                                                                            >
                                                                              <Button
                                                                                outline
                                                                                color="danger"
                                                                                size="sm"
                                                                                disabled={
                                                                                  value.length ==
                                                                                  1
                                                                                }
                                                                                className="mt-2 "
                                                                                onClick={() => {
                                                                                  handelDeleteOptions(
                                                                                    firstIndex,
                                                                                    index,
                                                                                    optionIndex
                                                                                  );
                                                                                }}
                                                                              >
                                                                                <i className="fas fa-trash " />
                                                                              </Button>
                                                                            </Col>
                                                                          </>
                                                                        )
                                                                      )}
                                                                  </>
                                                                  <>
                                                                    {inputKey ==
                                                                      "type" && (
                                                                      <Col
                                                                        lg={12}
                                                                      >
                                                                        <Label
                                                                          for={`verification[${index}].input.${inputKey}`}
                                                                          className="mt-2"
                                                                        >
                                                                          {
                                                                            inputKey
                                                                          }
                                                                        </Label>
                                                                        <Field
                                                                          name={`fields[${firstIndex}].verification[${index}].input.${inputKey}`}
                                                                          type="select"
                                                                          style={{
                                                                            color:
                                                                              "black",
                                                                          }}
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `fields[${firstIndex}].verification[${index}].input.${inputKey}`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            handelSelectType(
                                                                              firstIndex,
                                                                              index,
                                                                              e
                                                                                ?.target
                                                                                ?.value
                                                                            );
                                                                          }}
                                                                          as="select"
                                                                          className="form-control"
                                                                        >
                                                                          <option value="">
                                                                            Select
                                                                            ...
                                                                          </option>

                                                                          {[
                                                                            "text",
                                                                            "number",
                                                                            "radio",
                                                                            "multiple_option",
                                                                            "image",
                                                                            "longtext",
                                                                          ]?.map(
                                                                            (
                                                                              item
                                                                            ) => (
                                                                              <>
                                                                                <option
                                                                                  value={
                                                                                    item
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    item
                                                                                  }
                                                                                </option>
                                                                              </>
                                                                            )
                                                                          )}
                                                                        </Field>
                                                                      </Col>
                                                                    )}
                                                                    {inputKey !=
                                                                      "type" &&
                                                                      inputKey !=
                                                                        "options" && (
                                                                        <Col
                                                                          lg={6}
                                                                        >
                                                                          <Label
                                                                            for={`verification[${index}].input.${inputKey}`}
                                                                            className="mt-2"
                                                                          >
                                                                            {
                                                                              inputKey
                                                                            }
                                                                          </Label>
                                                                          <Field
                                                                            name={`fields[${firstIndex}].verification[${index}].input.${inputKey}`}
                                                                            className="form-control"
                                                                            type="text"
                                                                            style={{
                                                                              color:
                                                                                "black",
                                                                            }}
                                                                          />
                                                                        </Col>
                                                                      )}
                                                                  </>
                                                                </>
                                                              )
                                                            )}
                                                            {console.log(
                                                              "fields::: ",
                                                              fields
                                                            )}
                                                            {fields[firstIndex]
                                                              ?.verification[
                                                              index
                                                            ]?.input?.type ==
                                                              "image" && (
                                                              <Col lg={6}>
                                                                <Label className="mt-2">
                                                                  Quantity
                                                                </Label>
                                                                <Field
                                                                  name={`fields[${firstIndex}].verification[${index}].input.qty`}
                                                                  className="form-control"
                                                                  type="number"
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                  }}
                                                                />
                                                              </Col>
                                                            )}
                                                          </Row>
                                                        )}
                                                        <hr />
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "end",
                                                          }}
                                                        >
                                                          {!data?.input ? (
                                                            <Button
                                                              outline
                                                              color="primary"
                                                              onClick={() =>
                                                                handleAddVerificationInput(
                                                                  firstIndex,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <i className="ni ni-fat-add  mr-2" />
                                                              Add new
                                                            </Button>
                                                          ) : (
                                                            <>
                                                              <Button
                                                                outline
                                                                color="danger"
                                                                onClick={() =>
                                                                  handleAddVerificationInputDelete(
                                                                    firstIndex,
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                <i className="fas fa-trash  mr-2" />
                                                                Delete
                                                              </Button>
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )
                                    )}
                                  </CardBody>
                                )}
                            </Card>
                          </Col>
                          {/* Extra */}
                          <Col lg={6}>
                            <Card className="mt-2">
                              <CardHeader>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>Extra</span>
                                  <Button
                                    outline
                                    color="primary"
                                    onClick={() => handelAddNewExtra()}
                                  >
                                    <i className="ni ni-fat-add  mr-2" />
                                    Add new
                                  </Button>
                                </div>
                              </CardHeader>

                              {fields[firstIndex]?.extra &&
                                fields[firstIndex]?.extra?.length > 0 && (
                                  <CardBody>
                                    {Object.entries(fields[firstIndex]).map(
                                      ([keys, value], i) => (
                                        <>
                                          {Array.isArray(value) && (
                                            <>
                                              {keys === "extra" && (
                                                <div>
                                                  {value.length > 0 &&
                                                    value?.map(
                                                      (data, index) => (
                                                        <div
                                                          key={index}
                                                          className="border p-4 shadow mt-2"
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "end",
                                                            }}
                                                          >
                                                            <Button
                                                              outline
                                                              size="sm"
                                                              color="danger"
                                                              onClick={() =>
                                                                handelRemoveNewExtra(
                                                                  firstIndex,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <i className="fas fa-trash  mr-2" />
                                                              Delete
                                                            </Button>
                                                          </div>
                                                          <Row>
                                                            {Object.entries(
                                                              data
                                                            ).map(
                                                              (
                                                                [
                                                                  keysInner,
                                                                  valueInner,
                                                                ],
                                                                i
                                                              ) => (
                                                                <>
                                                                  {(keysInner ==
                                                                    "key" ||
                                                                    keysInner ==
                                                                      "title") && (
                                                                    <Col
                                                                      lg={6}
                                                                      className="mt-2"
                                                                    >
                                                                      <Label
                                                                        className="mt=2"
                                                                        for={
                                                                          keysInner
                                                                        }
                                                                        style={{
                                                                          textTransform:
                                                                            "capitalize",
                                                                        }}
                                                                      >
                                                                        {keysInner.replace(
                                                                          /_/g,
                                                                          " "
                                                                        )}
                                                                      </Label>
                                                                      <Field
                                                                        name={`fields[${firstIndex}].extra[${index}].${keysInner}`}
                                                                        className="form-control"
                                                                        type="text"
                                                                        style={{
                                                                          color:
                                                                            "black",
                                                                        }}
                                                                      />
                                                                    </Col>
                                                                  )}
                                                                  {keysInner ==
                                                                    "type" && (
                                                                    <Col
                                                                      lg={6}
                                                                      className="mt-2"
                                                                    >
                                                                      <Label
                                                                        className="mt=2"
                                                                        for={
                                                                          keysInner
                                                                        }
                                                                        style={{
                                                                          textTransform:
                                                                            "capitalize",
                                                                        }}
                                                                      >
                                                                        {keysInner.replace(
                                                                          /_/g,
                                                                          " "
                                                                        )}
                                                                      </Label>
                                                                      <Field
                                                                        name={`fields[${firstIndex}].extra[${index}].${keysInner}`}
                                                                        type="select"
                                                                        style={{
                                                                          color:
                                                                            "black",
                                                                        }}
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          setFieldValue(
                                                                            `fields[${firstIndex}].extra[${index}].${keysInner}`,
                                                                            e
                                                                              .target
                                                                              .value
                                                                          );
                                                                          handelExtraptions(
                                                                            firstIndex,
                                                                            index,
                                                                            e
                                                                              .target
                                                                              .value
                                                                          );
                                                                        }}
                                                                        as="select"
                                                                        className="form-control"
                                                                      >
                                                                        <option value="">
                                                                          Select
                                                                          ...
                                                                        </option>

                                                                        {[
                                                                          "text",
                                                                          "number",
                                                                          "radio",
                                                                          "multiple_option",
                                                                          "image",
                                                                          "longtext",
                                                                        ]?.map(
                                                                          (
                                                                            item
                                                                          ) => (
                                                                            <>
                                                                              <option
                                                                                value={
                                                                                  item
                                                                                }
                                                                              >
                                                                                {
                                                                                  item
                                                                                }
                                                                              </option>
                                                                            </>
                                                                          )
                                                                        )}
                                                                      </Field>
                                                                    </Col>
                                                                  )}
                                                                  {keysInner ==
                                                                    "mandatory" && (
                                                                    <Col
                                                                      lg={6}
                                                                      className="mt-2"
                                                                    >
                                                                      <Label
                                                                        className="mt=2"
                                                                        for={
                                                                          keysInner
                                                                        }
                                                                        style={{
                                                                          textTransform:
                                                                            "capitalize",
                                                                        }}
                                                                      >
                                                                        {keysInner.replace(
                                                                          /_/g,
                                                                          " "
                                                                        )}
                                                                      </Label>
                                                                      <Field
                                                                        type="select"
                                                                        as="select"
                                                                        name={`fields[${firstIndex}].extra[${index}].${keysInner}`}
                                                                        className="form-control"
                                                                        style={{
                                                                          color:
                                                                            "black",
                                                                        }}
                                                                      >
                                                                        <option value="">
                                                                          select
                                                                          type
                                                                        </option>
                                                                        {[
                                                                          true,
                                                                          false,
                                                                        ]?.map(
                                                                          (
                                                                            item
                                                                          ) => (
                                                                            <>
                                                                              <option
                                                                                value={
                                                                                  item
                                                                                }
                                                                              >
                                                                                {item.toString()}
                                                                              </option>
                                                                            </>
                                                                          )
                                                                        )}
                                                                      </Field>
                                                                    </Col>
                                                                  )}

                                                                  {valueInner ==
                                                                    "image" &&
                                                                    values
                                                                      .fields[
                                                                      firstIndex
                                                                    ].extra[
                                                                      index
                                                                    ]?.type ==
                                                                      "image" && (
                                                                      <Col
                                                                        lg={6}
                                                                      >
                                                                        <Label className="mt-2">
                                                                          Quantity
                                                                        </Label>
                                                                        <Field
                                                                          name={`fields[${firstIndex}].extra[${index}].qty`}
                                                                          className="form-control"
                                                                          type="text"
                                                                          style={{
                                                                            color:
                                                                              "black",
                                                                          }}
                                                                        />
                                                                      </Col>
                                                                    )}
                                                                  {Array.isArray(
                                                                    valueInner
                                                                  ) &&
                                                                    (values
                                                                      .fields[
                                                                      firstIndex
                                                                    ].extra[
                                                                      index
                                                                    ]?.type ==
                                                                      "multiple_option" ||
                                                                      values
                                                                        .fields[
                                                                        firstIndex
                                                                      ].extra[
                                                                        index
                                                                      ]?.type ==
                                                                        "radio") &&
                                                                    valueInner?.map(
                                                                      (
                                                                        valueInnerItem,
                                                                        optionIndex
                                                                      ) => (
                                                                        <>
                                                                          {optionIndex ==
                                                                            0 && (
                                                                            <Col
                                                                              lg={
                                                                                12
                                                                              }
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                justifyContent:
                                                                                  "end",
                                                                              }}
                                                                            >
                                                                              <Button
                                                                                outline
                                                                                color="primary"
                                                                                size="sm"
                                                                                onClick={() => {
                                                                                  handelAddNewOptionsInExtra(
                                                                                    firstIndex,
                                                                                    index
                                                                                  );
                                                                                }}
                                                                                className="mt-2"
                                                                              >
                                                                                <i className="ni ni-fat-add  mr-2" />
                                                                                Add
                                                                                new
                                                                              </Button>
                                                                            </Col>
                                                                          )}

                                                                          <Col
                                                                            lg={
                                                                              5
                                                                            }
                                                                          >
                                                                            <Label className="mt-2">
                                                                              key
                                                                            </Label>
                                                                            <Field
                                                                              name={`fields[${firstIndex}].extra[${index}].${keysInner}[${optionIndex}].key`}
                                                                              className="form-control"
                                                                              type="text"
                                                                              style={{
                                                                                color:
                                                                                  "black",
                                                                              }}
                                                                            />
                                                                          </Col>
                                                                          <Col
                                                                            lg={
                                                                              6
                                                                            }
                                                                          >
                                                                            <Label className="mt-2">
                                                                              Title
                                                                            </Label>
                                                                            <Field
                                                                              name={`fields[${firstIndex}].extra[${index}].${keysInner}[${optionIndex}].title`}
                                                                              className="form-control"
                                                                              type="text"
                                                                              style={{
                                                                                color:
                                                                                  "black",
                                                                              }}
                                                                            />
                                                                          </Col>
                                                                          <Col
                                                                            lg={
                                                                              1
                                                                            }
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "end",
                                                                            }}
                                                                          >
                                                                            <Button
                                                                              outline
                                                                              color="danger"
                                                                              size="sm"
                                                                              disabled={
                                                                                valueInner.length ==
                                                                                1
                                                                              }
                                                                              className="mt-2 "
                                                                              onClick={() => {
                                                                                handelDeleteOptionsInExtra(
                                                                                  firstIndex,
                                                                                  index,
                                                                                  optionIndex
                                                                                );
                                                                              }}
                                                                            >
                                                                              <i className="fas fa-trash " />
                                                                            </Button>
                                                                          </Col>
                                                                        </>
                                                                      )
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </Row>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )
                                    )}
                                  </CardBody>
                                )}
                            </Card>
                          </Col>
                        </Row>
                      </>
                    )}

                    <CustomModal
                      open={modal}
                      toggleModal={() => setModal(!modal)}
                      title="Add New Field"
                    >
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleAddField(arrayHelpers);
                        }}
                      >
                        <FormGroup>
                          <Label for="key">Key</Label>
                          <input
                            type="text"
                            name="key"
                            id="key"
                            value={newField.key}
                            onChange={handleFieldChange}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="titleEnglish">Title English</Label>
                          <input
                            type="text"
                            name="titleEnglish"
                            id="titleEnglish"
                            value={newField.titleEnglish}
                            onChange={handleFieldChange}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="titleHindi">Title Hindi</Label>
                          <input
                            type="text"
                            name="titleHindi"
                            id="titleHindi"
                            value={newField.titleHindi}
                            onChange={handleFieldChange}
                            className="form-control"
                          />
                        </FormGroup>
                        <Button type="submit" color="primary">
                          Add Field
                        </Button>
                      </Form>
                    </CustomModal>
                  </div>
                )}
              </FieldArray>
              {isEdit && (
                <div className="btn-container mt-4" style={{ width: "200px" }}>
                  <Button
                    type="submit"
                    className="w-100 bg-primary text-white mb-4"
                    disabled={buttonLoader}
                  >
                    {buttonLoader ? "Loading..." : "Submit"}
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
}
