/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  Spinner,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import DataService from "../services/requestApi";
import { useEffect, useState } from "react";
import { Slide } from "react-toastify";
import uttarpradesh from "../assets/mapJson/up.json";

import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";
import { Tooltip } from "react-tooltip";
import * as XLSX from "xlsx";
import * as saveAs from "file-saver";

const Index = (props) => {
  const [dataLoader, setDataLoader] = useState(false);
  const [allDistData, setAllDistData] = useState([]);
  const [districtName, setDistrictName] = useState();
  const [tooltipContent, setTooltipContent] = useState();
  const [zoom, setZoom] = useState(6);
  const [jSONData, setJSONData] = useState([]);
  const [countDist, setCountDist] = useState(0);
  const [countMatch, setCountMatch] = useState(0);
  const [countUnMatch, setCountUnMatch] = useState(0);
  const [countPending, setCountPending] = useState(0);

  const [districtStatus, setDistrictStatus] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getAllDistrict();
    getAllJSON();
  }, []);

  const getAllDistrict = async () => {
    setDataLoader(true);
    const { data } = await DataService.GetAllDistrict();
    if (data?.message == "Success") {
      setAllDistData(data.data);
      setCountDist(data?.data.length);
    }
    setDataLoader(false);
  };

  const getAllJSON = async () => {
    setDataLoader(true);
    const { data } = await DataService.GetAllJSON();
    console.log("data::: ", data);
    if (data?.message == "Success") {
      setJSONData(data.data);
      setFilteredData(data.data);

      const districtStatus = data.data.reduce((acc, item) => {
        acc[item.name] = item.treasuryStatus;
        return acc;
      }, {});
      let matchedCount = Object.values(districtStatus).reduce(
        (count, status) => {
          if (status === "matched") {
            count++;
          }
          return count;
        },
        0
      );
      setCountMatch(matchedCount);
      let pendingCount = Object.values(districtStatus).reduce(
        (count, status) => {
          if (status === "none") {
            count++;
          }
          return count;
        },
        0
      );
      setCountPending(pendingCount);

      let unMatchedCount = Object.values(districtStatus).reduce(
        (count, status) => {
          if (status === "unmatched") {
            count++;
          }
          return count;
        },
        0
      );
      setCountUnMatch(unMatchedCount);
      console.log("matchedCount::: ", matchedCount);
      setDistrictStatus(districtStatus);
    }
    setDataLoader(false);
  };

  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = jSONData.filter((item) => {
      return (
        item?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        item?.treasuryStatus?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";

  function downloadAsExcel() {
    console.log("filteredData::: ", filteredData);
    const treasuryVerificationData = filteredData.flatMap((d) =>
      d.treasuryVerification.map((tv) => ({
        images: tv.images.join(", "),
        status: tv.status,
        _id: tv._id,
        cctv: tv.cctv,
        room: tv.room,
        length: tv.length || (tv.roomData && tv.roomData.length),
        width: tv.width || (tv.roomData && tv.roomData.width),
        area: tv.area || (tv.roomData && tv.roomData.area),
        door_count: tv.door_count || (tv.roomData && tv.roomData.door_count),
        window_count:
          tv.window_count || (tv.roomData && tv.roomData.window_count),
        feedback_comment:
          tv.feedback_comment || (tv.roomData && tv.roomData.feedback_comment),
        district: tv.district,
        user_first_name: tv.user.first_name,
        user_last_name: tv.user.last_name,
        user_role_title: tv.user.role.title,
        user_role_department: tv.user.role.department,
      }))
    );
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const worksheetTreasuryVerification = XLSX.utils.json_to_sheet(
      treasuryVerificationData
    );

    const workbook = {
      Sheets: {
        data: worksheet,
        treasuryVerification: worksheetTreasuryVerification,
      },
      SheetNames: ["data", "treasuryVerification"],
    };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAsExcel(excelBuffer, "District_data");
  }

  function saveAsExcel(buffer, filename) {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, filename + EXCEL_EXTENSION);
  }

  return (
    <>
      <Container className="" fluid style={{ paddingTop: "100px" }}>
        <Row>
          <Col lg={7}>
            <div style={{ position: "relative", height: "500px" }}>
              {dataLoader ? (
                <Card
                  style={{
                    height: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner color="light" size="md" type="grow"></Spinner>
                  <span className="mt-2"> Loading...</span>
                </Card>
              ) : (
                <Card style={{ height: "100%", width: "100%" }}>
                  <ComposableMap
                    data-tip=""
                    projection="geoMercator"
                    width={600}
                    height={560}
                    projectionConfig={{ scale: 4000, center: [80.5, 27.2] }}
                  >
                    {/* <ZoomableGroup zoom={1} center={[80, 22]} disablePanning> */}
                    <Geographies geography={uttarpradesh}>
                      {({ geographies }) =>
                        geographies.map((geo) => {
                          const { district } = geo.properties;
                          const isMatched =
                            districtStatus[district] === "matched";

                          return (
                            <Geography
                              key={geo.rsmKey}
                              geography={geo}
                              onMouseEnter={() => {
                                setTooltipContent(`${district}`);
                              }}
                              onMouseLeave={() => {
                                setTooltipContent("");
                              }}
                              onClick={() => {
                                setDistrictName(`${district}`);
                              }}
                              style={{
                                default: {
                                  fill:
                                    districtStatus[district] === "matched"
                                      ? "green"
                                      : districtStatus[district] === "none"
                                      ? "#80808059"
                                      : districtStatus[district] ===
                                          "unmatched" && "red",
                                  outline: "none",
                                },
                                hover: {
                                  fill:
                                    districtStatus[district] === "matched"
                                      ? "green"
                                      : districtStatus[district] === "none"
                                      ? "yellow"
                                      : districtStatus[district] ===
                                          "unmatched" && "red",
                                  outline: "none",
                                },
                                pressed: {
                                  fill: "#D6D6DA",
                                  outline: "none",
                                },
                              }}
                              data-tooltip-id="map-tooltip"
                              data-tooltip-content={tooltipContent}
                            />
                          );
                        })
                      }
                    </Geographies>
                    {/* </ZoomableGroup> */}
                  </ComposableMap>
                  <Tooltip id="map-tooltip" />
                </Card>
              )}
            </div>
            {/* <div
              style={{ position: "relative", height: "500px", width: "800px" }}
            >
              <Card style={{ height: "100%", width: "100%" }}>
                <ComposableMap
                  data-tip=""
                  projection="geoMercator"
                  width={600}
                  height={560}
                  projectionConfig={{ scale: 4000, center: [80, 27] }} // Adjusted projectionConfig
                >
                  <Geographies geography={uttarpradesh}>
                    {({ geographies }) =>
                      geographies.map((geo) => {
                        const { district } = geo.properties;
                        const isMatched =
                          districtStatus[district] === "matched";

                        return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            onMouseEnter={() => {
                              setTooltipContent(district);
                            }}
                            onMouseLeave={() => {
                              setTooltipContent("");
                            }}
                            onClick={() => {
                              setDistrictName(district);
                            }}
                            style={{
                              default: {
                                fill:
                                  districtStatus[district] === "matched"
                                    ? "green"
                                    : districtStatus[district] === "none"
                                    ? "#80808059"
                                    : districtStatus[district] === "unmatched"
                                    ? "red"
                                    : "#EAEAEC",
                                outline: "none",
                              },
                              hover: {
                                fill:
                                  districtStatus[district] === "matched"
                                    ? "green"
                                    : districtStatus[district] === "none"
                                    ? "#80808059"
                                    : districtStatus[district] === "unmatched"
                                    ? "red"
                                    : "#EAEAEC",
                                outline: "none",
                              },
                              pressed: {
                                fill: "#D6D6DA",
                                outline: "none",
                              },
                            }}
                            data-tooltip-id="map-tooltip"
                            data-tooltip-content={tooltipContent}
                          />
                        );
                      })
                    }
                  </Geographies>
                </ComposableMap>
                <Tooltip id="map-tooltip" />
              </Card>
            </div> */}
          </Col>
          <Col lg={5}>
            <Row>
              <Col lg={6}>
                <Card className="w-100">
                  <CardBody>
                    <Row>
                      <div
                        className="col"
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total District
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {countDist}
                          </span>
                        </div>
                      </div>
                      <Col
                        className="col-auto"
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">
                          <a href="#!">View reports</a>
                        </span>
                      </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="w-100">
                  <CardBody>
                    <Row>
                      <div
                        className="col"
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Pending
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {countPending}
                          </span>
                        </div>
                      </div>
                      <Col
                        className="col-auto"
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">
                          <a href="#!">View reports</a>
                        </span>
                      </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6} className="mt-4">
                <Card className="w-100">
                  <CardBody>
                    <Row>
                      <div
                        className="col"
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Verified
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {countMatch}
                          </span>
                        </div>
                      </div>
                      <Col
                        className="col-auto"
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">
                          <a href="#!">View reports</a>
                        </span>
                      </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6} className="mt-4">
                <Card className="w-100">
                  <CardBody>
                    <Row>
                      <div
                        className="col"
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Unmatched
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {countUnMatch}
                          </span>
                        </div>
                      </div>
                      <Col
                        className="col-auto"
                        style={{
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">
                          <a href="#!">View reports</a>
                        </span>
                      </p> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Card className="p-4 mt-4">
          <CardHeader className="d-flex justify-content-between">
            <div className="d-flex">
              <FormGroup
                className="mb-0"
                style={{ position: "relative", width: "250px" }}
              >
                <Input
                  placeholder="Search"
                  onChange={handleFilter}
                  style={{ width: 250, borderRadius: 10 }}
                />
                <i
                  className="fas fa-search"
                  style={{ position: "absolute", right: 15, top: 12 }}
                />
              </FormGroup>
              <FormGroup className="mb-2" style={{ width: "250px" }}>
                <Input
                  name="select"
                  type="select"
                  onChange={handleFilter}
                  className="mx-2"
                  style={{ width: 250, borderRadius: 10 }}
                >
                  <option value="">-- Select status --</option>
                  <option value="matched">Matched</option>
                  <option value="Unmatched">Unmatched</option>
                  <option value="none">Pending</option>
                </Input>
              </FormGroup>
            </div>
            <div>
              {!dataLoader && (
                <Button onClick={() => downloadAsExcel()}>
                  Download Excel
                </Button>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <table style={{ borderRadius: "10px" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(87 100 255 / 82%)",
                      color: "white",
                    }}
                  >
                    District Name
                  </th>
                  <th
                    style={{
                      background: "rgb(87 100 255 / 82%)",
                      color: "white",
                    }}
                  >
                    Treasury Officer
                  </th>
                  <th
                    style={{
                      background: "rgb(87 100 255 / 82%)",
                      color: "white",
                    }}
                  >
                    Nodal Officer
                  </th>
                  <th
                    style={{
                      background: "rgb(87 100 255 / 82%)",
                      color: "white",
                    }}
                  >
                    District Manager
                  </th>
                  <th
                    style={{
                      background: "rgb(87 100 255 / 82%)",
                      color: "white",
                    }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataLoader ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : (
                  <>
                    {filteredData.length > 0 ? (
                      filteredData?.map((item) => (
                        <>
                          <tr>
                            <td>{item?.name}</td>
                            <td>
                              {item?.treasuryVerification?.length == 0
                                ? "Uncaptured"
                                : ` Captured
                        ${item?.treasuryVerification?.length} Room${
                                    item?.treasuryVerification?.length > 1 &&
                                    "s"
                                  }`}
                            </td>
                            <td>Uncaptured</td>
                            <td>Uncaptured</td>
                            <td
                              style={{
                                textTransform: "capitalize",
                                background:
                                  item?.treasuryStatus == "matched"
                                    ? "green"
                                    : item?.treasuryStatus == "unmatched"
                                    ? "red"
                                    : "#FDDA0D",
                                color:
                                  item?.treasuryStatus == "matched"
                                    ? "white"
                                    : item?.treasuryStatus == "unmatched"
                                    ? "white"
                                    : "black",
                              }}
                            >
                              {item?.treasuryStatus == "matched" && "matched"}
                              {item?.treasuryStatus == "unmatched" &&
                                "unmatched"}
                              {item?.treasuryStatus == "none" && "Pending"}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No data found!
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Container>

      <style jsx>
        {`
          .table-container {
            width: 800px;
            margin: 0 auto;
          }

          table {
            width: 100%;
            // border-collapse: collapse;
            font-family: sans-serif;
            border-collapse: separate;
            border-spacing: 8px; /* Sets the gap between cells */
          }

          th {
            text-align: left;
            padding: 10px;
            border-radius: 10px;

            // border: 1px solid #ddd;
          }
          td {
            text-align: left;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #ddd;
          }

          th {
            background-color: #f2f2f2;
          }

          .positive {
            color: green;
          }
        `}
      </style>
    </>
  );
};

export default Index;
