import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  FormGroup,
  Input,
  Label,
  Table,
  CardBody,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DataService from "../../../../services/requestApi";
import Pagination from "../../Pagination";
import ShowMaterialValidationList from "./ShowMaterialvalidationList";
const ToMaterialValidation = () => {
  const [allDistrictData, setAllDistrictData] = useState([]);
  const [showPrePage] = useState(10);
  const [isShowAllData, setIsShowAllData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [showData, setShowData] = useState();
  const [allEventList, setAllEventList] = useState([]);
  const [selectEvent, setSelectEvent] = useState();
  const [listLoader, setListLoader] = useState(false);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const theading = ["ID", "DISTRICT NAME", "DISTRICT CODE", "ACTION"];

  useEffect(() => {
    getAllEvent();
  }, []);

  const getAllEvent = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllEvent();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllEventList(data?.data);
        console.log("data?.data[0]?._id::: ", data?.data[0]?._id);
        setSelectEvent(data?.data[0]?._id);
        getDistrictByEvent(data?.data[0]?._id);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const getDistrictByEvent = async (item) => {
    console.log("item::: ", item);
    setListLoader(true);
    try {
      const { data } = await DataService.GetDistrictByEvent(
        "treasuryMaterial",
        item
      );
      console.log("GetDistrictByEvent data::: ", data);
      if (data.message == "Success") {
        setAllDistrictData(data?.data);
        setFilteredData(data?.data);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setListLoader(false);
  };
  const handelChangeEventType = (e) => {
    console.log("e::: ", e);
    getDistrictByEvent(e);
  };

  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = allDistrictData.filter((item) => {
      console.log("item::: ", item);
      return item?.district?.name
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
    });
    setFilteredData(filtered);
  };

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                T/O Verification
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  {isShowAllData ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsShowAllData(!isShowAllData);
                        setShowData();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="blue"
                        class="bi bi-box-arrow-in-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                        />
                      </svg>
                    </span>
                  ) : (
                    <Link to="/admin/index">
                      <i className="fas fa-home"></i>
                    </Link>
                  )}
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  {"T/O Material Verification"}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
      </Container>
      {isShowAllData ? (
        <>
          <ShowMaterialValidationList showData={showData} />
        </>
      ) : (
        <>
          {/* Page content */}
          <Container fluid>
            <Card className="mb-4">
              <CardBody>
                <Row>
                  <Col xl="4">
                    <FormGroup
                      className="mb-0  mr-3"
                      style={{ position: "relative" }}
                    >
                      <Input
                        name="select"
                        type="select"
                        value={selectEvent}
                        onChange={(e) => {
                          setSelectEvent(e.target.value);
                          handelChangeEventType(e.target.value);
                          setFilteredData([]);
                        }}
                      >
                        <option value="">Select Event</option>
                        {allEventList?.map((item) => (
                          <>
                            <option value={item?._id}>{item?.name}</option>
                          </>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow mb-5">
                  <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">T/O Material verification</h3>
                    <FormGroup
                      className="mb-0"
                      style={{ position: "relative" }}
                    >
                      <Input
                        placeholder="Search"
                        onChange={handleFilter}
                        style={{ width: 250, borderRadius: 20 }}
                      />
                      <i
                        className="fas fa-search"
                        style={{ position: "absolute", right: 15, top: 12 }}
                      />
                    </FormGroup>
                  </CardHeader>
                  <Table
                    id="mainTable"
                    className="align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        {theading?.map((data, index) => (
                          <th scope="col" key={index}>
                            {data}
                          </th>
                        ))}
                        {/* <th scope="col" style={{ width: "70%" }}>
              Status
            </th> */}
                      </tr>
                    </thead>
                    {listLoader ? (
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <h5 className="text-center">Loading...</h5>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {filteredData?.length > 0 ? (
                          <>
                            {filteredData
                              ?.slice(pagination.start, pagination.end)
                              ?.map((data, idx) => (
                                <tr key={idx}>
                                  <td>{pagination.start + idx + 1}</td>
                                  <td>{data?.district?.name || "-"}</td>
                                  <td>{data?.district?.code || "-"}</td>
                                  {/* <td>
                                    <div
                                      style={{
                                        height: "25px",
                                        width: "100px",
                                        background:
                                          data?.district?.treasuryStatus ==
                                          "unmatched"
                                            ? "red"
                                            : data?.district.treasuryStatus ==
                                              "matched"
                                            ? "green"
                                            : "#FDDA0D",
                                        color:
                                          data?.district.treasuryStatus ==
                                          "unmatched"
                                            ? "white"
                                            : data?.district.treasuryStatus ==
                                              "matched"
                                            ? "white"
                                            : "black",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      {data?.district.treasuryStatus == "none"
                                        ? "Pending"
                                        : data?.district.treasuryStatus}
                                    </div>
                                  </td> */}
                                  <td>
                                    <Button
                                      href="#"
                                      color="link"
                                      className="p-0"
                                      onClick={() => {
                                        setShowData(data?.district?._id);
                                        setIsShowAllData(!isShowAllData);
                                      }}
                                    >
                                      <i className="fas fa-eye mx-2" />
                                      Material
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={5}>
                                <h5 className="text-center">
                                  No Data Available
                                </h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    )}
                  </Table>
                  {console.log("filteredData::: ", filteredData)}
                  <CardFooter className="py-4">
                    {filteredData?.length > 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={filteredData?.length}
                      />
                    )}
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ToMaterialValidation;
