import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";
import CustomTable from "components/table/CustomTable";
import { STATECITIES } from "./StateCitiesObject";
import DataService from "../../../services/requestApi";
import moment from "moment";
import PaginationData from "../Pagination";
import CitiesStateDate from "./CitiesStateInputField";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";

const ExamMaster = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [rowValue, setRowValue] = useState();
  const [isEditData, setIsEditData] = useState(false);
  const [cityShow, setCityShow] = useState(false);
  const [examDepartment, setExamDepartment] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showPrePage] = useState(10);
  const [selectDepartment, setSelectDepartment] = useState("");
  const [title, setTitle] = useState();
  const [filteredData, setFilteredData] = useState([]);
  // const [dateCitiyStateArray, setDateCitiyStateArray] = useState([1]);

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  useEffect(() => {
    applyFilter();
  }, [title, selectDepartment]);

  const applyFilter = () => {
    const filtered = tableData?.filter((item) => {
      return (
        (selectDepartment === "" ||
          item.department_id
            ?.toLowerCase()
            .includes(selectDepartment?.toLowerCase())) &&
        (title === "" ||
          item.title?.toLowerCase().includes(title?.toLowerCase()))
      );
    });
    console.log("filtered::: ", filtered);

    setFilteredData(filtered);
  };
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const theading = ["ID", "TITLE", "DEPARTMENT", "CODE"];
  const tdata = [[14, "FTII", "	SG-1", "	012"]];

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required("required"),
    department_id: Yup.string().required("required"),
    code: Yup.string().required("required"),
    // city_name: Yup.string().required("required"),
    // state_name: Yup.string().required("required"),
    // date_of_exam: Yup.string().required("required"),
  });

  useEffect(() => {
    getExamDepartment();
    getExamMaster();
  }, []);
  const getExamDepartment = async () => {
    const { data } = await DataService.GetExamDepartment();
    setExamDepartment(data.examDepartment);
  };

  const getExamMaster = async () => {
    const { data } = await DataService.GetExamMaster();
    setTableData(data.examMaster);
    setFilteredData(data.examMaster);
  };

  const handleSubmit = async (data) => {
    console.log("data::: ", data);
    const res = await DataService.CreateExamMaster(data);
    console.log("res::: ", res);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getExamMaster();
    } else {
      alert("False");
    }
  };
  const updateUserRole = (item) => {
    setRowValue(item);
    setModal(true);
    // setCitiesArray(STATECITIES[0][item.state_name]);
    setCityShow(true);
  };
  const updateUserData = async (data) => {
    console.log("update::: ", data);
    const res = await DataService.UpdateExamMaster(rowValue._id, data);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getExamMaster();
    } else {
      alert("False");
    }
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteExamMaster(item._id);
    if (res.data.message == "Success") {
      alert("True");
      getExamMaster();
    } else {
      alert("False");
    }
  };

  const examDepartmentList = (data) => {
    const result = examDepartment.filter((item) => item._id == data);
    return result[0]?.title;
  };
  const downloadData = filteredData?.map((elt, i) => [
    i,
    elt.title,
    examDepartmentList(data?.department_id) || "-",
    elt.code,
  ]);
  const downloadHeaders = [["ID", "TITLE", "DEPARTMENT", "CODE"]];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Exam Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setRowValue();
                setViewData(false);
                setCityShow(false);
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                tablePdf("Exam Master", downloadHeaders, downloadData)
              }
            >
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                exportExcel("Exam Master", downloadHeaders, downloadData)
              }
            >
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Card tables</h3>
                <div className="d-flex">
                  <FormGroup
                    className="mb-0 mr-3"
                    style={{ position: "relative" }}
                  >
                    <Input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Title"
                      style={{ width: 250, borderRadius: 20 }}
                    />
                    <i
                      className="fas fa-search"
                      style={{ position: "absolute", right: 15, top: 12 }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0" style={{ position: "relative" }}>
                    <Input
                      name="select"
                      type="select"
                      value={selectDepartment}
                      onChange={(e) => setSelectDepartment(e.target.value)}
                    >
                      <option value="">select Department ..</option>
                      {examDepartment?.map((item) => (
                        <>
                          <option value={item._id}>{item.title}</option>
                        </>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    {/* <th scope="col" style={{ width: "70%" }}>
              Status
            </th> */}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    filteredData
                      ?.slice(pagination.start, pagination.end)
                      ?.map((data, idx) => (
                        <tr key={idx}>
                          <th scope="row">
                            <FormGroup check inline>
                              <Input type="checkbox" />
                            </FormGroup>
                          </th>
                          <td>{idx + 1}</td>
                          <td>{data?.title || "-"}</td>
                          <td>
                            {examDepartmentList(data?.department_id) || "-"}
                          </td>

                          <td>{data.code}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    updateUserRole(data);
                                    setViewData(false);
                                    setIsEditData(false);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt" />
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  onClick={() => {
                                    setModal(!modal);
                                    updateUserRole(data);
                                    setViewData(true);
                                  }}
                                >
                                  <i className="	fas fa-eye" />
                                  View
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  // onClick={(e) => e.preventDefault()}
                                  onClick={() => deleteUserRole(data)}
                                >
                                  <i className="fas fa-trash " />
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <h5 className="text-center">No Data Available</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <PaginationData
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Formik
          initialValues={{
            title: rowValue ? rowValue?.title : "",
            department_id: rowValue ? rowValue?.department_id : "",
            code: rowValue ? rowValue?.code : "",
            stateCityDate: rowValue ? rowValue?.stateCityDate : [],
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const onOffToggle = { status: toggle };
            let merged = {
              ...values,
              ...onOffToggle,
            };
            console.log("merged::: ", merged);
            isEditData ? handleSubmit(merged) : updateUserData(merged);
          }}
        >
          {({ touched, errors, setFieldValue, values }) => (
            <div>
              <Form>
                <Row>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Title<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="title"
                        className={` form-control
                          ${touched.title && errors.title ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="title"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Department<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        as="select"
                        type="select"
                        name="department_id"
                        className={` form-control
                          ${
                            touched.department_id && errors.department_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select Exam Category .. </option>
                        {examDepartment?.map((item) => (
                          <>
                            <option value={item._id}>{item.title}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="department_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Code<span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="code"
                        className={` form-control
                          ${touched.code && errors.code ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="code"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  <FieldArray
                    name="stateCityDate"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.stateCityDate &&
                        values?.stateCityDate?.length > 0 ? (
                          values?.stateCityDate?.map((item, index) => (
                            <div key={index}>
                              <Row className="px-3">
                                <CitiesStateDate
                                  cityShow={cityShow}
                                  rowValue={rowValue}
                                  index={index}
                                  viewData={viewData}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                                <Col xl={viewData ? "12" : "6"}>
                                  <FormGroup>
                                    <Label>
                                      Exam date
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Field
                                      disabled={viewData}
                                      type="date"
                                      name={`stateCityDate[${index}].date_of_exam`}
                                      // onChange={(e) =>
                                      //   setFieldValue(
                                      //     `stateCityDate[${index}].date_of_exam`,
                                      //     e.target.value
                                      //   )
                                      // }
                                      className={` form-control`}
                                    />
                                  </FormGroup>
                                </Col>
                                {!viewData && (
                                  <Col xl="6" className="d-flex mt-4 p-2">
                                    <div>
                                      <Button
                                        type="button"
                                        className="bg-primary text-white"
                                        onClick={() => {
                                          arrayHelpers.push(index, {
                                            state_name: "",
                                            city_name: "",
                                            date_of_exam: "",
                                          });
                                        }}
                                      >
                                        +
                                      </Button>
                                    </div>
                                    <div>
                                      <Button
                                        type="button"
                                        className="mx-2 btn btn-danger"
                                        onClick={() => {
                                          setCityShow(true);
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        -
                                      </Button>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          ))
                        ) : (
                          <button
                            className="button btn btn-primary mx-3"
                            onClick={() => {
                              arrayHelpers.push("");
                            }}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    )}
                  />

                  <Col xl="12 mt-4">
                    <Label className="d-block">Status</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="status"
                        onChange={(e) => {
                          setToggle(e.target.checked);
                        }}
                        defaultChecked={rowValue?.status}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col>
                </Row>
                {!viewData && (
                  <div className="btn-container">
                    <Button
                      type="submit"
                      className="w-100 bg-primary text-white"
                    >
                      {isEditData ? "Create" : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default ExamMaster;
