import {
  Card,
  CardHeader,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
  CardBody,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as FileSaver from "file-saver";
// core components
import { Link, useLocation, useParams } from "react-router-dom";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";
import CustomTable from "components/table/CustomTable";
import { useQuery } from "react-query";
import DataService from "../../../../services/requestApi";
import Pagination from "../../Pagination";
import AllDistList from "./AllDistList";
const DistrictValidation = () => {
  const [allCenterData, setAllCenterData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [showPrePage] = useState(10);
  const [isShowAllData, setIsShowAllData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [showData, setShowData] = useState([]);
  const [allDistData, setAllDistData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [centerName, setCenterName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [assignedUser, setAssignedUser] = useState("");
  const [allEventList, setAllEventList] = useState([]);
  const [selectEvent, setSelectEvent] = useState();
  const [listLoader, setListLoader] = useState([]);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const theading = [
    "ID",
    "CENTER NAME",
    "DISTRICT NAME",
    "ASSIGN USER",
    "APPROVE BY",
    "STATUS",
    "ACTION",
  ];

  useEffect(() => {
    // getAllCenter();
    getAllDistrict();
    getUsers();
    getAllEvent();
  }, []);

  const getAllCenter = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllCenter();
      console.log("data::: ", data);
      if (data.message == "Success") {
        const reverse = data?.data?.reverse();
        setAllCenterData(reverse);
        setFilteredData(reverse);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const getAllDistrict = async () => {
    try {
      const { data } = await DataService.GetAllDistrict();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllDistData(data.data);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const getUsers = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllUser();
      console.log("data", data);
      if (data.message == "Success") {
        console.log("data.User::: ", data.User);
        setUserData(data.User);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const handleFilter = () => {
    const filtered = allCenterData.filter((item) => {
      const matchesCenterName = item?.school_name
        ?.toLowerCase()
        .includes(centerName?.toLowerCase());
      const matchesDistrictName = districtName
        ? item?.district?.some((district) => district?._id === districtName)
        : true;

      const matchesAssignedUser = assignedUser
        ? item?.user?.some((user) => user?._id === assignedUser)
        : true;
      return matchesCenterName && matchesDistrictName && matchesAssignedUser;
    });
    setFilteredData(filtered);
  };

  const handleClear = () => {
    setCenterName("");
    setDistrictName("");
    setAssignedUser("");
    setFilteredData(allCenterData);
  };

  const assignUser = (item) => {
    let userId = [];
    item.map((row) => {
      userId.push(row?.approvedBy?.userId);
    });
    return Array.from(new Set(userId));
  };

  const handelChangeEventType = (e) => {
    console.log("e::: ", e);
    getDistrictByEvent(e);
  };

  const getAllEvent = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllEvent();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllEventList(data?.data);
        console.log("data?.data[0]?._id::: ", data?.data[0]?._id);
        setSelectEvent(data?.data[0]?._id);
        getDistrictByEvent(data?.data[0]?._id);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const getDistrictByEvent = async (item) => {
    console.log("item::: ", item);
    setListLoader(true);
    try {
      const { data } = await DataService.GetDistrictByEvent("center", item);
      console.log("GetDistrictByEvent data::: ", data);
      if (data?.message == "Success") {
        const reverse = data?.data?.reverse();
        setAllCenterData(reverse);
        setFilteredData(reverse);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setListLoader(false);
  };

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Verification
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  {isShowAllData ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsShowAllData(!isShowAllData);
                        setShowData();
                        getAllCenter();
                        getAllEvent();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="blue"
                        class="bi bi-box-arrow-in-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                        />
                      </svg>
                    </span>
                  ) : (
                    <Link to="/admin/index">
                      <i className="fas fa-home"></i>
                    </Link>
                  )}
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  {showData?.school_name || "Center Verification"}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
      </Container>
      {isShowAllData ? (
        <>
          <AllDistList showData={showData} />
        </>
      ) : (
        <>
          <Container fluid>
            <Card className="mb-4">
              <CardBody>
                <Row>
                  <Col xl="4">
                    <FormGroup
                      className="mb-0  mr-3"
                      style={{ position: "relative" }}
                    >
                      <Input
                        name="select"
                        type="select"
                        value={selectEvent}
                        onChange={(e) => {
                          setSelectEvent(e.target.value);
                          handelChangeEventType(e.target.value);
                          setFilteredData([]);
                        }}
                      >
                        <option value="">Select Event</option>
                        {allEventList?.map((item) => (
                          <>
                            <option value={item?._id}>{item?.name}</option>
                          </>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* filter */}
            <Card className="shadow p-3 mb-3">
              <Row>
                <Col xl="2">
                  <FormGroup className="mb-2">
                    <Label for="exampleSelect">Center Name</Label>
                    <Input
                      type="text"
                      placeholder="Center Name"
                      value={centerName}
                      onChange={(e) => setCenterName(e.target.value)}
                    />
                  </FormGroup>
                </Col>

                <Col xl="2">
                  <FormGroup className="mb-0">
                    <Label>District Name</Label>
                    <Input
                      name="select"
                      type="select"
                      value={districtName}
                      onChange={(e) => setDistrictName(e.target.value)}
                    >
                      <option value="">Select ...</option>
                      {allDistData.length > 0 &&
                        allDistData?.map((item) => (
                          <>
                            <option value={item._id}>{item?.name}</option>
                          </>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xl="2">
                  <FormGroup className="mb-0">
                    <Label>Assign User</Label>
                    <Input
                      name="select"
                      type="select"
                      value={assignedUser}
                      onChange={(e) => setAssignedUser(e.target.value)}
                    >
                      <option value="">Select ...</option>
                      {userData.length > 0 &&
                        userData?.map((item) => (
                          <>
                            <option value={item._id}>{item?.userId}</option>
                          </>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col xl="6">
                  <div className="btn-container d-flex align-items-end justify-content-end h-100">
                    <Button onClick={() => handleFilter()}>filter</Button>
                    <Button onClick={() => handleClear()}>Clear</Button>
                  </div>
                </Col>
              </Row>
            </Card>
            {/* Table */}
          </Container>
          {/* Page content */}
          <Container fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow mb-5">
                  <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">Center</h3>
                    {/* <FormGroup
                      className="mb-0"
                      style={{ position: "relative" }}
                    >
                      <Input
                        placeholder="Search"
                        onChange={handleFilter}
                        style={{ width: 250, borderRadius: 20 }}
                      />
                      <i
                        className="fas fa-search"
                        style={{ position: "absolute", right: 15, top: 12 }}
                      />
                    </FormGroup> */}
                  </CardHeader>
                  <Table
                    id="mainTable"
                    className="align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        {theading?.map((data, index) => (
                          <th scope="col" key={index}>
                            {data}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {listLoader ? (
                      <tbody>
                        <tr>
                          <td colSpan={10}>
                            <h5 className="text-center">Loading...</h5>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {filteredData?.length > 0 ? (
                          <>
                            {filteredData
                              ?.slice(pagination.start, pagination.end)
                              ?.map((data, idx) => (
                                <tr key={idx}>
                                  <td>{pagination.start + idx + 1}</td>
                                  <td>{data?.school_name || "-"}</td>
                                  <td>
                                    {data?.district?.length == 0 && "-"}
                                    {data?.district?.length > 0 &&
                                      data?.district?.map((item) => (
                                        <>{item?.name || "-"}</>
                                      ))}
                                  </td>
                                  <td>
                                    {data?.user?.length == 0 && "-"}
                                    {data?.user?.map((item) => (
                                      <>{item?.userId}, </>
                                    )) || "-"}
                                  </td>
                                  <td>
                                    {assignUser(data?.centerVerification)
                                      ?.length > 0 ? (
                                      assignUser(data?.centerVerification).map(
                                        (item, index) => (
                                          <div key={index}>{item}</div>
                                        )
                                      )
                                    ) : (
                                      <div>---</div>
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        height: "25px",
                                        width: "100px",
                                        background:
                                          data?.verificationStatus == "Matched"
                                            ? "green"
                                            : data?.verificationStatus ==
                                              "Unmatched"
                                            ? "red"
                                            : "#FDDA0D",
                                        color:
                                          data?.verificationStatus == "Matched"
                                            ? "white"
                                            : data?.verificationStatus ==
                                              "Unmatched"
                                            ? "white"
                                            : "black",
                                        // color: "black",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      {data?.verificationStatus}
                                    </div>
                                  </td>

                                  <td>
                                    <Button
                                      href="#"
                                      color="link"
                                      className="p-0"
                                      onClick={() => {
                                        setShowData(data);
                                        setIsShowAllData(!isShowAllData);
                                      }}
                                    >
                                      <i className="fas fa-eye mx-2" />
                                      View
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={10}>
                                <h5 className="text-center">
                                  No Data Available
                                </h5>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    )}
                  </Table>
                  <CardFooter className="py-4">
                    {filteredData?.length > 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={filteredData?.length}
                      />
                    )}
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default DistrictValidation;
