import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  Spinner,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Button,
  BreadcrumbItem,
  Breadcrumb,
} from "reactstrap";
import DataService from "../../../../services/requestApi";
import { useEffect, useState } from "react";
import uttarpradesh from "../../../../assets/mapJson/up.json";

import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";
import { Tooltip } from "react-tooltip";
import AllDistList from "./AllDistList";

const Dashboard = (props) => {
  const [dataLoader, setDataLoader] = useState(false);
  const [allDistData, setAllDistData] = useState([]);
  const [districtName, setDistrictName] = useState();
  const [tooltipContent, setTooltipContent] = useState();
  const [jSONData, setJSONData] = useState([]);
  const [countDist, setCountDist] = useState(0);
  const [countMatch, setCountMatch] = useState(0);
  const [countUnMatch, setCountUnMatch] = useState(0);
  const [countPending, setCountPending] = useState(0);
  const [showData, setShowData] = useState([]);
  const [isShowAllData, setIsShowAllData] = useState(false);

  const [districtStatus, setDistrictStatus] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [dotList, setDotList] = useState([]);

  const [allEventList, setAllEventList] = useState([]);
  const [selectEvent, setSelectEvent] = useState();
  const [listLoader, setListLoader] = useState([]);

  const history = useHistory();

  useEffect(() => {
    // getAllCenter();
    // getAllJSON();
    getAllEvent();
  }, []);

  const getAllCenter = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllCenter();
      console.log("GetAllCenter data::: ", data);
      if (data?.message == "Success") {
        var dotsList = [
          {
            latitude: 80.679,
            longitude: 27.568,
            verificationStatus: "Matched",
          },
        ];
        data.data.map((item) => {
          if (item.centerVerification) {
            item.centerVerification.map((item2) => {
              if (item2.centerData) {
                if (item2.centerData.geo_location) {
                  dotsList.push({
                    latitude: parseFloat(
                      item2?.centerData?.geo_location?.latitude
                    ),
                    longitude: parseFloat(
                      item2?.centerData?.geo_location?.longitude
                    ),
                    verificationStatus: item.verificationStatus,
                  });
                }
              }
            });
          }
        });
        setDotList(dotsList);

        const reverse = data?.data?.reverse();
        setAllDistData(reverse);
        setFilteredData(reverse);
        setCountDist(data?.data.length);
        const districtStatus = reverse?.reduce((acc, item) => {
          acc[item?.district[0]?.name] = item?.verificationStatus;
          return acc;
        }, {});
        setDistrictStatus(districtStatus);

        const pendingCount = data?.data?.reduce((accumulator, currentValue) => {
          if (currentValue?.verificationStatus === "Pending") {
            return accumulator + 1;
          } else {
            return accumulator;
          }
        }, 0);
        setCountPending(pendingCount);
        const matchedCount = data?.data?.reduce((accumulator, currentValue) => {
          if (currentValue?.verificationStatus === "Matched") {
            return accumulator + 1;
          } else {
            return accumulator;
          }
        }, 0);
        setCountMatch(matchedCount);

        const unMatchedCount = data?.data?.reduce(
          (accumulator, currentValue) => {
            if (currentValue?.verificationStatus === "Unmatched") {
              return accumulator + 1;
            } else {
              return accumulator;
            }
          },
          0
        );
        setCountUnMatch(unMatchedCount);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
    setDataLoader(false);
  };

  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = allDistData?.filter((item) => {
      return (
        item?.school_name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        item?.verificationStatus?.includes(searchQuery)
      );
    });
    setFilteredData(filtered);
  };

  function downloadAsExcel() {
    console.log("filteredData::: ", filteredData);
    // const treasuryVerificationData = filteredData.flatMap((d) =>
    //   d.treasuryVerification.map((tv) => ({
    //     images: tv.images.join(", "),
    //     status: tv.status,
    //     _id: tv._id,
    //     cctv: tv.cctv,
    //     room: tv.room,
    //     length: tv.length || (tv.roomData && tv.roomData.length),
    //     width: tv.width || (tv.roomData && tv.roomData.width),
    //     area: tv.area || (tv.roomData && tv.roomData.area),
    //     door_count: tv.door_count || (tv.roomData && tv.roomData.door_count),
    //     window_count:
    //       tv.window_count || (tv.roomData && tv.roomData.window_count),
    //     feedback_comment:
    //       tv.feedback_comment || (tv.roomData && tv.roomData.feedback_comment),
    //     district: tv.district,
    //     user_first_name: tv.user.first_name,
    //     user_last_name: tv.user.last_name,
    //     user_role_title: tv.user.role.title,
    //     user_role_department: tv.user.role.department,
    //   }))
    // );
    // const worksheet = XLSX.utils.json_to_sheet(filteredData);
    // const worksheetTreasuryVerification = XLSX.utils.json_to_sheet(
    //   treasuryVerificationData
    // );

    // const workbook = {
    //   Sheets: {
    //     data: worksheet,
    //     treasuryVerification: worksheetTreasuryVerification,
    //   },
    //   SheetNames: ["data", "treasuryVerification"],
    // };
    // const excelBuffer = XLSX.write(workbook, {
    //   bookType: "xlsx",
    //   type: "array",
    // });
    // saveAsExcel(excelBuffer, "District_data");
  }

  const handelChangeEventType = (e) => {
    console.log("e::: ", e);
    getDistrictByEvent(e);
  };

  const getAllEvent = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllEvent();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllEventList(data?.data);
        console.log("data?.data[0]?._id::: ", data?.data[0]?._id);
        setSelectEvent(data?.data[0]?._id);
        getDistrictByEvent(data?.data[0]?._id);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const getDistrictByEvent = async (item) => {
    console.log("item::: ", item);
    setListLoader(true);
    try {
      const { data } = await DataService.GetDistrictByEvent("center", item);
      console.log("GetDistrictByEvent data::: ", data);
      if (data?.message == "Success") {
        var dotsList = [
          {
            latitude: 80.679,
            longitude: 27.568,
            verificationStatus: "Matched",
          },
        ];
        data?.data.map((item) => {
          if (item?.centerVerification) {
            item?.centerVerification.map((item2) => {
              if (item2?.centerData) {
                if (item2?.centerData?.geo_location) {
                  dotsList?.push({
                    latitude: parseFloat(
                      item2?.centerData?.geo_location?.latitude
                    ),
                    longitude: parseFloat(
                      item2?.centerData?.geo_location?.longitude
                    ),
                    verificationStatus: item.verificationStatus,
                  });
                }
              }
            });
          }
        });
        setDotList(dotsList);

        const reverse = data?.data?.reverse();
        setAllDistData(reverse);
        setFilteredData(reverse);
        setCountDist(data?.data.length);
        const districtStatus = reverse?.reduce((acc, item) => {
          acc[item?.district[0]?.name] = item?.verificationStatus;
          return acc;
        }, {});
        setDistrictStatus(districtStatus);

        const pendingCount = data?.data?.reduce((accumulator, currentValue) => {
          if (currentValue?.verificationStatus === "Pending") {
            return accumulator + 1;
          } else {
            return accumulator;
          }
        }, 0);
        setCountPending(pendingCount);
        const matchedCount = data?.data?.reduce((accumulator, currentValue) => {
          if (currentValue?.verificationStatus === "Matched") {
            return accumulator + 1;
          } else {
            return accumulator;
          }
        }, 0);
        setCountMatch(matchedCount);

        const unMatchedCount = data?.data?.reduce(
          (accumulator, currentValue) => {
            if (currentValue?.verificationStatus === "Unmatched") {
              return accumulator + 1;
            } else {
              return accumulator;
            }
          },
          0
        );
        setCountUnMatch(unMatchedCount);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setListLoader(false);
  };

  return (
    <>
      {isShowAllData ? (
        <>
          <Container
            fluid
            style={{
              paddingTop: "100px",
            }}
          >
            <Row>
              <Col lg="8">
                <div className="bread mb-3 d-flex flex-column">
                  <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                    Verification
                  </h6>
                  <Breadcrumb className="d-none d-md-inline-block">
                    <BreadcrumbItem>
                      {isShowAllData ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsShowAllData(!isShowAllData);
                            setShowData();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="blue"
                            class="bi bi-box-arrow-in-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                            />
                          </svg>
                        </span>
                      ) : (
                        <Link to="/admin/index">
                          <i className="fas fa-home"></i>
                        </Link>
                      )}
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {showData?.school_name || "Center Verification"}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>
          <AllDistList showData={showData} />
        </>
      ) : (
        <Container className="mt-5" fluid>
          <Row>
            <Col lg="8">
              <div className="bread mb-3 d-flex flex-column">
                <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                  Center Verification
                </h6>
                <Breadcrumb className="d-none d-md-inline-block">
                  <BreadcrumbItem>
                    <Link to="/admin/index">
                      <i className="fas fa-home"></i>
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    Dashboard
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </Col>
          </Row>

          <Card className="mb-4">
            <CardBody>
              <Row>
                <Col xl="4">
                  <FormGroup
                    className="mb-0  mr-3"
                    style={{ position: "relative" }}
                  >
                    <Input
                      name="select"
                      type="select"
                      value={selectEvent}
                      onChange={(e) => {
                        setFilteredData([]);
                        setDotList([]);
                        setCountDist(0);
                        setCountMatch(0);
                        setCountUnMatch(0);
                        setCountPending(0);
                        setSelectEvent(e.target.value);
                        handelChangeEventType(e.target.value);
                      }}
                    >
                      <option value="">Select Event</option>
                      {allEventList?.map((item) => (
                        <>
                          <option value={item?._id}>{item?.name}</option>
                        </>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col lg={7}>
              <div style={{ position: "relative", height: "500px" }}>
                {dataLoader ? (
                  <Card
                    style={{
                      height: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner color="light" size="md" type="grow"></Spinner>
                    <span className="mt-2"> Loading...</span>
                  </Card>
                ) : (
                  <Card style={{ height: "100%", width: "100%" }}>
                    <ComposableMap
                      data-tip=""
                      projection="geoMercator"
                      width={600}
                      height={560}
                      projectionConfig={{ scale: 4000, center: [80.5, 27.2] }}
                    >
                      <Geographies geography={uttarpradesh}>
                        {({ geographies }) =>
                          geographies.map((geo) => {
                            const { district } = geo.properties;

                            let sum = 0;
                            let Pending = 0;
                            let Matched = 0;
                            let Unmatched = 0;

                            allDistData.map((item) => {
                              if (district == item.district[0]?.name) {
                                if (item.verificationStatus == "Pending") {
                                  Pending = Pending + 1;
                                }
                                if (item.verificationStatus == "Matched") {
                                  Matched = Matched + 1;
                                }
                                if (item.verificationStatus == "Unmatched") {
                                  Unmatched = Unmatched + 1;
                                }
                                sum = sum + 1;
                              }
                            });

                            return (
                              <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                onMouseEnter={() => {
                                  setTooltipContent(
                                    `${district} : ${sum} 
                                  <br/> 
                                 
                                <span style="color: green;">Matched : ${Matched}</span> 
                                 
                                  <br/> 
                                   <span style="color: red;"> Unmatched : ${Unmatched} </span> 
                                 
                                  <br/> 
                                   <span style="color: yellow;">  Pending : ${Pending} </span> 
                                  `
                                  );
                                }}
                                onMouseLeave={() => {
                                  setTooltipContent("");
                                }}
                                onClick={() => {
                                  setDistrictName(
                                    `${district} : 0 <br/> Matched : 0`
                                  );
                                }}
                                style={{
                                  default: {
                                    fill: "#80808059",
                                    stroke: "white",
                                    strokeWidth: 1,
                                  },
                                }}
                                data-tooltip-id="map-tooltip"
                              />
                            );
                          })
                        }
                      </Geographies>
                      {dotList?.map((item) => (
                        <>
                          <Marker
                            coordinates={[
                              item.latitude.toFixed(2),
                              item.longitude.toFixed(2),
                            ]}
                          >
                            <circle r={5} fill="#F00" />
                          </Marker>
                        </>
                      ))}
                    </ComposableMap>
                    <Tooltip id="map-tooltip">
                      <div
                        dangerouslySetInnerHTML={{ __html: tooltipContent }}
                      ></div>
                    </Tooltip>
                  </Card>
                )}
              </div>
            </Col>
            <Col lg={5}>
              <Row>
                <Col lg={6}>
                  <Card className="w-100">
                    <CardBody>
                      <Row>
                        <div
                          className="col"
                          style={{
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Center
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {countDist}
                            </span>
                          </div>
                        </div>
                        <Col
                          className="col-auto"
                          style={{
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">
                          <a href="#!">View reports</a>
                        </span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="w-100">
                    <CardBody>
                      <Row>
                        <div
                          className="col"
                          style={{
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Pending
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {countPending}
                            </span>
                          </div>
                        </div>
                        <Col
                          className="col-auto"
                          style={{
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">
                          <a href="#!">View reports</a>
                        </span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6} className="mt-4">
                  <Card className="w-100">
                    <CardBody>
                      <Row>
                        <div
                          className="col"
                          style={{
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Matched
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {countMatch}
                            </span>
                          </div>
                        </div>
                        <Col
                          className="col-auto"
                          style={{
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">
                          <a href="#!">View reports</a>
                        </span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6} className="mt-4">
                  <Card className="w-100">
                    <CardBody>
                      <Row>
                        <div
                          className="col"
                          style={{
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Unmatched
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {countUnMatch}
                            </span>
                          </div>
                        </div>
                        <Col
                          className="col-auto"
                          style={{
                            height: "100px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">
                          <a href="#!">View reports</a>
                        </span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Card className="p-4 mt-4">
            <CardHeader className="d-flex justify-content-between">
              <div className="d-flex">
                <FormGroup
                  className="mb-0"
                  style={{ position: "relative", width: "250px" }}
                >
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 10 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
                <FormGroup className="mb-2" style={{ width: "250px" }}>
                  <Input
                    name="select"
                    type="select"
                    onChange={handleFilter}
                    className="mx-2"
                    style={{ width: 250, borderRadius: 10 }}
                  >
                    <option value="">-- Select status --</option>
                    <option value="Matched">Matched</option>
                    <option value="Unmatched">Unmatched</option>
                    <option value="Pending">Pending</option>
                  </Input>
                </FormGroup>
              </div>
              <div>
                {!listLoader && countDist !== 0 && (
                  <Button onClick={() => downloadAsExcel()}>
                    Download Excel
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardBody>
              <table style={{ borderRadius: "10px" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        background: "rgb(87 100 255 / 82%)",
                        color: "white",
                      }}
                    >
                      Center Name
                    </th>
                    <th
                      style={{
                        background: "rgb(87 100 255 / 82%)",
                        color: "white",
                      }}
                    >
                      District Name
                    </th>

                    <th
                      style={{
                        background: "rgb(87 100 255 / 82%)",
                        color: "white",
                      }}
                    >
                      Assign User
                    </th>
                    <th
                      style={{
                        background: "rgb(87 100 255 / 82%)",
                        color: "white",
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        background: "rgb(87 100 255 / 82%)",
                        color: "white",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listLoader ? (
                    <tr>
                      <td colSpan={5} className="text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    <>
                      {filteredData?.length > 0 ? (
                        filteredData?.map((item) => (
                          <>
                            <tr>
                              <td>{item?.school_name}</td>
                              <td>
                                {item?.district?.length == 0 && "-"}
                                {item?.district?.length > 0 &&
                                  item?.district?.map((item) => (
                                    <>{item?.name || "-"}</>
                                  ))}
                              </td>
                              <td>
                                {" "}
                                {item?.user?.length == 0 && "-"}
                                {item?.user?.map((item) => (
                                  <>{item?.userId}, </>
                                )) || "-"}
                              </td>
                              <td
                                style={{
                                  textTransform: "capitalize",
                                  background:
                                    item?.verificationStatus == "Matched"
                                      ? "green"
                                      : item?.verificationStatus == "Unmatched"
                                      ? "red"
                                      : "#FDDA0D",
                                  color:
                                    item?.verificationStatus == "Matched"
                                      ? "white"
                                      : item?.verificationStatus == "Unmatched"
                                      ? "white"
                                      : "black",
                                }}
                              >
                                {item?.verificationStatus == "Matched" &&
                                  "Matched"}
                                {item?.verificationStatus == "Unmatched" &&
                                  "Unmatched"}
                                {item?.verificationStatus == "Pending" &&
                                  "Pending"}
                              </td>
                              <td>
                                <Button
                                  href="#"
                                  color="link"
                                  className="p-0"
                                  onClick={() => {
                                    setShowData(item);
                                    setIsShowAllData(!isShowAllData);
                                  }}
                                >
                                  <i className="fas fa-eye mx-2" />
                                  View
                                </Button>
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No data found!
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Container>
      )}
      <style jsx>
        {`
          .table-container {
            width: 800px;
            margin: 0 auto;
          }

          table {
            width: 100%;
            // border-collapse: collapse;
            font-family: sans-serif;
            border-collapse: separate;
            border-spacing: 8px; /* Sets the gap between cells */
          }

          th {
            text-align: left;
            padding: 10px;
            border-radius: 10px;

            // border: 1px solid #ddd;
          }
          td {
            text-align: left;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #ddd;
          }

          th {
            background-color: #f2f2f2;
          }

          .positive {
            color: green;
          }
        `}
      </style>
    </>
  );
};

export default Dashboard;
