import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import routes from "routes.js";

const Auth = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div ref={mainContent}>
        <Switch>
          {getRoutes(routes)}
          <Redirect to="/auth/login" />
        </Switch>
      </div>
    </>
  );
};

export default Auth;
