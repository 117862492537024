import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
  Table,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useState } from "react";
import CustomTable from "components/table/CustomTable";
const BioMatric = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);

  const handleFile = (file) => {
    const img = URL.createObjectURL(file);
    setData(img);
  };
  const theading = [
    "ID",
    "SR. NO",
    "ROLL NUMBER",
    "FIRST NAME",
    "MOBILE",
    "EMAIL",
    "EXAM NAME",
    "CENTER NAME",
    "BIOMETRIC CAPTURED DATE",
    "IS VERIFIED?",
    "IS BIO METRIC BY OBSERVER?",
  ];
  const tdata = [
    [
      10133,
      "Invigilator",
      "Nitesh",
      "Kumar",
      "nekolkata@gmail.com",
      "9143060898",
    ],
  ];

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Reports
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Candidate Bio Matric Data Reports
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => setModal(!modal)}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button className="p-0">
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button className="p-0">
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* filter */}
        <Card className="shadow p-3 mb-3">
          <Row>
            <Col xl="3">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">Candidate Name</Label>
                <Input placeholder="Name" />
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label>Exam Name</Label>
                <Input name="select" type="select">
                  <option>Select Exam Name</option>
                  <option>Examination Venue Lucknow - SICP</option>
                  <option>Examination Venue Lucknow - ASI</option>
                  <option>UPPRB </option>
                  <option>Demo</option>
                  <option>Aptech - CBSE</option>
                  <option>RRC </option>
                  <option> CBSE-Aptech-KVS</option>
                  <option>NWDA</option>
                  <option>FTII</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label>Center Name</Label>
                <Input name="select" type="select">
                  <option>Select Center Name</option>
                  <option>Examination Venue Lucknow -SICP-1</option>
                  <option>Examination Venue Lucknow ASI-1</option>
                  <option>Examination Venue Lucknow - ASI-2</option>
                  <option>Examination Venue Lucknow -SICP-2</option>
                  <option>UPPRB Center 1</option>
                  <option>UPPRB (Date 06/01/2023)</option>
                  <option>UPPRB (Date 08/01/2023)</option>
                  <option>UPPRB (Date 14-15/01/2023)</option>
                  <option>UPPRB (Date 16-17/01/2023)</option>
                  <option>UPPRB (Date 19/01/2023)</option>
                  <option>UPPRB (Date 20-21/01/2023)</option>
                  <option>UPPRB (Date 23-24/01/2023)</option>
                  <option>UPPRB (Date 28-29/01/2023)</option>
                  <option>UPPRB (Date 30-31/01/2023)</option>
                  <option>Demo Center</option>
                  <option>AIS ASSESSMENT ZONE</option>
                  <option>ARWACHIN BHARTI BHAWAN</option>
                  <option>BAL VIDYA ACADEMY</option>
                  <option>BAL VIDYA MODEL SCHOOL</option>
                  <option>BHARTIYAM DIGITAL ACADEMY</option>
                  <option>
                    DELHI SCHOOL OF PROFESSIONAL STUDIES AND RESEARCH
                  </option>
                  <option>ELBRUS ASSESSMENT CENTRE</option>
                  <option>GALLATIN ASSESSMENT CENTRE-1</option>
                  <option>ALLATIN ASSESSMENT CENTRE-1</option>
                  <option>GALLATIN ASSESSMENT CENTRE-2</option>
                  <option>
                    GURU HARGOBIND INSTITUTE OF MANAGEMENT TECHNOLOGY
                  </option>
                  <option>HOLY CONVENT SER. SEC. SCHOOL</option>
                  <option>INDRAPRASTHA PUBLIC SCHOOL</option>
                  <option>JN INTERNATIONAL SCHOOL</option>
                  <option>KARAKORAM ASSESSMENT CENTRE</option>
                  <option>M.A GLOBAL ONLINE EXAM CENTER</option>
                  <option>MAFINGA ASSEESSMENT CENTER</option>
                  <option>MD INDRAPRASTHA INSTITUTE OF HIGHER EDUCATION</option>
                  <option>MR BHARTI ASSESSMENTS</option>
                  <option>MRS GLOBAL INSTITUTE OF TECHNOLOGY</option>
                  <option>NEW SAINIK EXAM CENTRE</option>
                  <option>RAPID ONLINE SOLUTIONS</option>
                  <option>
                    RSP ONLINE DATA PROCESSING AND INFOSYSTEM PVT LTD
                  </option>
                  <option>SALASAR ONLINE SERVICES PVT. LTD</option>
                  <option>SHIKSHA BHARATI COLLEGE</option>
                  <option>SRA ONLINE CENTER</option>
                  <option>TIKRI DIGITAL ZONE</option>
                  <option>AIS ASSESSMENT ZONE</option>
                  <option>COSMOS PUBLIC SCHOOL ONLINE EXAMINATION</option>
                  <option>DR. B. R. AMBEDKAR INSTITUTE OF TECHNOLOGY</option>
                  <option>SRI BALAJI PG COLLEGE</option>
                  <option>BALAJI INFORMATION TECHNOLOGY</option>
                  <option>SOFT TECHNOLOGIES</option>
                  <option>SANDEEPANI EDUCATIONAL SOCIETY</option>
                  <option>GSR ONLINE ACADEMY</option>
                  <option>SS DIGITAL ZONE</option>
                  <option>LAKSHMI BALAJI COMPUTERS</option>
                  <option>BETIMES ENTERPRISE</option>
                  <option>ARUNACHAL UNIVERSITY OF STUDIES</option>
                  <option>SANKALPA FOUNDATION</option>
                  <option>ASSAM DOWNTOWN UNIVERSITY</option>
                  <option>GALAXY COMPUTER INSTITUTE</option>
                  <option>SILCHAR POLYTECHNIC</option>
                  <option>ARANYAK KEERTI INSTITUTE</option>
                  <option>
                    TEZPUR UNIVERSITY(SOE OFFICE OF DEAN BUILDING)
                  </option>
                  <option>SHRISTI INFOTECH</option>
                  <option>BHAGALPUR ONLINE CENTRE</option>
                  <option>GEETANJALI TEST CENTRE</option>
                  <option>INDIA EXAM CENTRE</option>
                  <option>KRISHNA DIGIETAL</option>
                  <option>MANGLAM INFOSOL</option>
                  <option>SHREE GANESH INNOVATIVE</option>
                  <option>SRIBALAJEE INFOTECH</option>
                  <option>CAREER DIGITAL ZONE PVT LTD</option>
                  <option>SATYENDRA KISHAN COLLEGE OF EDUCATION</option>
                  <option>ACME INSTITUTE OF INFORMATION TECHNOLOGY</option>
                  <option>GANPATI IT POINT</option>
                  <option>INFORMATIC COMPUTER EDUCATION, PATLIPUTRA</option>
                  <option>UNIVERSAL DIGITAL NETWORK</option>
                  <option>
                    VRINDAVAN INNOVATIVE ONLINE EXAMINATION CENTRE
                  </option>
                  <option>WISDOM CARE ONLINE EXAM CENTER</option>
                  <option>S.P.R. EXAM SOLUTION PRIVATE LTD.</option>
                  <option>
                    SANJAY RUNGTA GROUP OF INSTITUTIONS (CENTRE-1)
                  </option>
                  <option>
                    SANJAY RUNGTA GROUP OF INSTITUTIONS (CENTRE-2)
                  </option>
                  <option>MOUNT LITERA ONLINE EXAM CENTER</option>
                  <option>RAIPUR INSTITUTE OF TECHNOLOGY, RAIPUR</option>
                  <option>EASTERN ONLINE TEST CENTER</option>
                  <option>SILVER OAK UNIVERSITY</option>
                  <option>SKYHUB DIGITAL ZONE</option>
                  <option>AMATYAS GLOBAL IT SOLUTION</option>
                  <option>VISION INFOTECH</option>
                  <option>GLOBAL INSTITUTE(MEHSANA)</option>
                  <option>SUCCESS INFOTECH</option>
                  <option>RELIABLE KNOWLEDGE CENTER</option>
                  <option>ZERUA ASSESSMENT SOLUTION</option>
                  <option>SARTHIK PVT INDUSTRIAL TRAINING INSTITUTE</option>
                  <option>APEX ONLINE SOLUTION</option>
                  <option>GURUGRAM INSTITUTE OF TECHNO WORLD</option>
                  <option>VARTMAAN ONLINE SERVICES</option>
                  <option>FUTURE VISION</option>
                  <option>DRONACHARYA DEGREE COLLEGE</option>
                  <option>NEW GURUKUL INFOTECH</option>
                  <option>Test</option>
                  <option>
                    HIMACHAL INSTITUTE OF ENGINEERING AND TECHNOLOGY
                  </option>
                  <option>DIGITAL VISION ONLINE PVT. LTD.</option>
                  <option>LR GROUP INSTITUTIONS</option>
                  <option>CRYPTECH DIGITAL ACADEMY</option>
                  <option>B. N. COLLEGE OF EDUCATION</option>
                  <option>ADVANTECH COMPUTER EDUCATION</option>
                  <option>TISSA TECHNOLOGY</option>
                  <option>DHANBAD DIGITAL</option>
                  <option>INSTITUTE FOR EDUCATION</option>
                  <option>SHREE INFOTECH</option>
                  <option>GLOBAL IT SOLUTIONS</option>
                  <option>MILESTONE TECHNOLOGIES</option>
                  <option>SRI SAI COMPUTERS</option>
                  <option>EDUTECH SOLUTION C/O CHETAN BUSINESS SCHOOL</option>
                  <option>
                    MANGALURU MANIPAL INSTITUTE OF COMPUTER EDUCATION
                  </option>
                  <option>CHANDANA RESOURCE CENTER TECH</option>
                  <option>DIFEEN DIGITAL FEATHER ENGINEERS PVT LTD</option>
                  <option>PROGRESSIVE ONLINE TESTING SERVICES</option>
                  <option>MARYGIRI E-PARK, PODIKKALAM</option>
                  <option>BASELIOS MATHEWS II COLLEGE OF ENGINEERING</option>
                  <option>
                    MANGALAM SCHOOL OF ARCHITECTURE AND PLANNING, KOTTAYAM
                  </option>
                  <option>MAESTRO RED ONLINE</option>
                  <option>REGIONAL EDU CITY</option>
                  <option>ARYANET INSTITUTE OF TECHNOLOGY, PALAKKAD</option>
                  <option>JOHN COX MEMORIAL CSI INSTITUTE OF TECHNOLOGY</option>
                  <option>JB COMPUTER SOLUTION</option>
                  <option>VAISHNAVI INSTITUTE OF TECHNOLOGY AND SCIENCE</option>
                  <option>BETHESDA INSTITUTE OF TECHNOLOGY AND SCIENCE</option>
                  <option>
                    NAGAJI INSTITUTE OF TECHNOLOGY &amp; MANAGEMENT
                  </option>
                  <option>PLEXUS IT SYSTEM</option>
                  <option>
                    NACHIKETA INSTITUTE OF MANAGEMENT &amp; INFORMATION
                    TECHNOLOGY
                  </option>
                  <option>GYANVEER INSTITUTE OF MANAGMENT &amp; SCIENCE</option>
                  <option>MARUTI COLLEGE OF EDUCATION</option>
                  <option>
                    PRASHANTI INSTITUTE OF TECHNOLOGY &amp; SCIENCE
                  </option>
                  <option>PROF. SANTOSH KUTE EXAMINATION UNIT</option>
                  <option>GIRIRAJ ENTERPRISES</option>
                  <option>EVEREST COLLEGE OF ENGINEERING</option>
                  <option>BAJAJ POLYTECHNIC</option>
                  <option>ABHAY BED AND JUNIOR SCIENCE COLLEGE</option>
                  <option>S S SYSTEMS</option>
                  <option>INET SOLUTIONS</option>
                  <option>
                    NETIZENS SKILL TRAINING AND IT SOLUTIONS LATUR
                  </option>
                  <option>MBC PARK</option>
                  <option>G H RAISONI SCHOOL OF BUSINESS MANAGEMENT</option>
                  <option>
                    NIIT S LATE ANNASAHEB PATIL POLYTECHNIC COLLAGE
                  </option>
                  <option>DR. SUDHAKAR JADHAVAR COLLEGE</option>
                  <option>SHREE KREETI TECHNOLOGIES</option>
                  <option>AGNIHOTRI ONLINE EXAM CENTER</option>
                  <option>THONGAMACHA COMPUTER CENTRE</option>
                  <option>TECH SERVICES</option>
                  <option>AGAPE COMPUTER INSTITUTE</option>
                  <option>CITY INSTITUTE</option>
                  <option>MAA DIGITAL ZONE</option>
                  <option>MAVERICKS DIGITAL ZONE</option>
                  <option>KMBB COLLEGE OF ENGINEERING AND TECHNOLOGY</option>
                  <option>KTI FUTURE SOLUTION (KSHETRABASI ITI)</option>
                  <option>ORISSA COLLEGE OF PHARMACEUTICAL SCIENCE,</option>
                  <option>ISPAT PVT. ITI</option>
                  <option>ITECH</option>
                  <option>
                    SRI VENKATESHWARA COLLEGE OF ENGINEERING AND TECHNOLOGY
                  </option>
                  <option>
                    SATYAM INSTITUTE OF ENGINEERING &amp; TECHNOLOGY
                  </option>
                  <option>REGIONAL POLYTECHNIC COLLEGE</option>
                  <option>LUDHIANA TRADE CENTRE</option>
                  <option>INDO GLOBAL COLLEGES</option>
                  <option>SRI SAI COLLEGE OF ENGINEERING AND TECHNOLOGY</option>
                  <option>ARYAN GROUP OF COLLAGES</option>
                  <option>DIGITAL COMPUTING</option>
                  <option>LOTUS ASSESSMENT CENTRE</option>
                  <option>CHILDRENS ACADEMY CONVENT SCHOOL</option>
                  <option>
                    CHANDRAVATI EDUCATIONAL CHARITABLE TRUST GROUP OF
                    INSTITUTIONS BHARATPUR
                  </option>
                  <option>KEEN COLLEGE</option>
                  <option>ARYA COLLEGE OF ENGINEERING AND IT</option>
                  <option>ADHYA INFOTECH</option>
                  <option>PHOENIX ONLINE EXAM CENTRE</option>
                  <option>
                    SHREE MADAN LAL BIYANI GIRLS ADARSH VIDHYA MANDIR SCHOOL
                    SIKAR RAJ.
                  </option>
                  <option>SHRI AATM VALLABH JAIN GIRLS COLLEGE</option>
                  <option>EKLAVYA ACADEMY UDAIPUR</option>
                  <option>MOHAMED SATHAK A.J. COLLEGE OF ENGINEERING</option>
                  <option>NEHRU ARTS &amp; SCIENCE</option>
                  <option>BRIGHT SERVICES</option>
                  <option>SURYA TECHNOLOGIES</option>
                  <option>NIGAMA ENGINEERING COLLEGE</option>
                  <option>MS ACE INFOTECH SERVICES</option>
                  <option>DIGITAL VALLEY</option>
                  <option>SMT BHUDEVI PG COLLEGE</option>
                  <option>ALLAHABAD INFOTECH PRIVATE LIMITED</option>
                  <option>RD INSTITUTE</option>
                  <option>BHABHUTI PRASAD SMARAK MAHAVIDYALAY</option>
                  <option>
                    SHRI SIDDHI VINAYAK INSTITUTE OF TECHNOLOGY BAREILLY
                  </option>
                  <option>YUVA ONLINE TEST CENTRE</option>
                  <option>YUVA ONLINE TEST CENTRE</option>
                  <option>ROHIL KHAND COMPUTER TRAINING INSTITUTE</option>
                  <option>ASPIRING WINGS INFOTECH</option>
                  <option>NKA DIGITAL CENTRE</option>
                  <option>MD INFOTECH</option>
                  <option>SULTAN FOUNDATION</option>
                  <option>
                    PANCHWATI INSTITUTE OF ENGINEERING AND TECHNOLOGY
                  </option>
                  <option>MORADABAD INFOTECH</option>
                  <option>SIGMA ONLINE TEST SERVICES</option>
                  <option>CCMC</option>
                  <option>A.S.J.S INSTITUTE OF TECHNOLOGY</option>
                  <option>CRJ SOFTWARE TECHNOLOGIES PVT LTD</option>
                  <option>OCBT IT SERVICE</option>
                  <option>SHEETALA INSTITUTE OF TECHNOLOGY</option>
                  <option>SUDHAKAR MAHILA VIDHI MAHAVIDHYALAY COLLEGE</option>
                  <option>SIDDHARTHA GROUP OF INSTITUTIONS COMPUTER LAB</option>
                  <option>RADHAKRISHNA ASSESMENT CENTER</option>
                  <option>ROORKEE PUBLIC SCHOOL</option>
                  <option>RANIGANJ PRIVATE ITI</option>
                  <option>
                    MURSHIDABAD COLLEGE OF ENGINEERING &amp; TECHNOLOGY
                  </option>
                  <option>BIDYASAGAR INSTITUTE OF COMPUTERS</option>
                  <option>CONFIANCE</option>
                  <option>SERAMPORE COLLEGE</option>
                  <option>FUTURE STAIRS</option>
                  <option>IMPS COLLEGE OF ENGINEERING AND TECHNOLOGY</option>
                  <option>SALBANI INSTITUTE OF TECHNOLOGY</option>
                  <option>UMANG INFOTECH</option>
                  <option>U.P. PUBLIC SCHOOL</option>
                  <option>
                    PARIKRAMA GROUP OF INSTITUTIONS,COLLEGE OF ENGINEERING
                    KASHTI
                  </option>
                  <option>LOVELY PROFESSIONAL UNIVERSITY</option>
                  <option>LAMDON ONLINE ASSESSMENT</option>
                  <option>RRC Exam</option>
                  <option>EAST DELHI ASSESSMENT ZONE</option>
                  <option>AZTECH ONLINE TEST CENTRE</option>
                  <option>MAA ASSESSMENT</option>
                  <option>SILICON INSTITUTE OF TECHNOLOGY</option>
                  <option>ANJANEY PRIVATE ITI</option>
                  <option>NIBEDITA POLYTECHNIC</option>
                  <option>
                    ARYABHATTA INSTITUTE OF ENGINEERING &amp; MANAGEMENT
                  </option>
                  <option>
                    ABACUS INSTITUTE OF ENGINEERING &amp; MANAGEMENT
                  </option>
                  <option>MANTHAN</option>
                  <option>MBC MODI HOUSE</option>
                  <option>SUNSHINE EXAM CENTRE</option>
                  <option>STAR INSTITUTE(BHARAT NAGAR)</option>
                  <option>KHALSA INFOTECH SERVICE</option>
                  <option>BALA JI COMPUTERS</option>
                  <option>
                    SHAHEED UDHAM SINGH COLLEGE OF ENGINEERING AND TECHNOLOGY
                  </option>
                  <option>STAR INSTITUTE(PRADHAN NAGAR)</option>
                  <option>MBC SUDHIR MEMORIAL INSTITUTE</option>
                  <option>ECHELON COLLEGE OF ENGG &amp; TECH</option>
                  <option>HAPPY MODEL SENIOR SECONDARY SCHOOL</option>
                  <option>BRIGHTER HORIZONS</option>
                  <option>GAUR COLLEGE OF EDUCATION ONLINE EXAM CENTER</option>
                  <option>MAYUR ASSESSMENT CENTRE</option>
                  <option>JYOTISAR ASSESSMENT CENTRE</option>
                  <option>BPR-ITI COLLEGE</option>
                  <option>SKS GROUP OF INSTITUTES</option>
                  <option>
                    MODERN EDUCATION COLLEGE ONLINE EXAMINATION LAB
                  </option>
                  <option>
                    MAHANT BACHITTAR SINGH COLLEGE OF ENGINEERING &amp;
                    TECHNOLOGY
                  </option>
                  <option>SBN INFOTECH INFRA SOLUTIONS PVT. LIMITED</option>
                  <option>
                    BHAGWATI INSTITUTE OF TECHNOLOGY &amp; SCIENCE
                  </option>
                  <option>
                    BHAGWATI INSTITUTE OF TECHNOLOGY &amp; SCIENCE CENTER-2
                  </option>
                  <option>
                    VAS INDIA COMMUNICATIONS PVT.LTD.ONLINE EXAM CENTRE
                  </option>
                  <option>VIDHAN PUBLIC SCHOOL</option>
                  <option>MEERUT INSTITUTE OF TECHNOLOGY(MIT)</option>
                  <option>MEERUT INTERNATIONAL INSTITUTE OF TECHNOLOGY</option>
                  <option>SHRINATHJI INSTITUTE OF TECHNOLOGY</option>
                  <option>SWAMI VIVEKANAND SUBHARTI UNIVERSITY</option>
                  <option>
                    MEERUT INSTITUTE OF ENGINEERING AND TECHNOLOGY
                  </option>
                  <option>JANHIT DIGITAL ZONE</option>
                  <option>PT. SALIGRAM INTER COLLEGE)</option>
                  <option>
                    MUZAFFARPUR DIGITAL (ONLINE EXAMINATION CENTRE)
                  </option>
                  <option>SGH INFOTECH PVT. LTD</option>
                  <option>SRM IT SOLUTION</option>
                  <option>JDS ITI</option>
                  <option>PERTH DIGITAL ZONE</option>
                  <option>NSE TEST CENTRE</option>
                  <option>ASPIRE INFOTECH SOLUTION</option>
                  <option>SHIVA INFOTECH ONLINE TESTING SOLUTIONS</option>
                  <option>I.T INFOTECH ONLINE SOLUTIONS</option>
                  <option>ARUNUMA TECHNICAL SERVICES PVT. LTD.</option>
                  <option>ASSESSMENT DIGITAL ZONE</option>
                  <option>CBS COLLEGE OF MANAGEMENT</option>
                  <option>AIIT &amp; MANAGEMENT COLLEGE</option>
                  <option>CAMPUS 2 AIIT &amp; MANAGEMENT COLLEGE</option>
                  <option>NAND KISHORE SINGH COMPUTER ACADEMY</option>
                  <option>SHAMBUNATH COLLEGE OF EDUCATION</option>
                  <option>SHREE RAJDEV GROUP OF INSTITUTION</option>
                  <option>UNITED INSTITUTE OF TECHNOLOGY</option>
                  <option>VIVEKANAND PUBLIC INTER COLLEGE</option>
                  <option>XCELLENCE INFOTECH</option>
                  <option>H.L. SKILL &amp; IT SOLUTIONS</option>
                  <option>WE CARE FOUNDATION</option>
                  <option>KPM PUBLIC HIGH SCHOOL ONLINE EXAM CENTER</option>
                  <option>YATIKAS COMPUTER CENTER</option>
                  <option>BHAGAT SINGH ONLINE EXAMINATION CENTER</option>
                  <option>PARVATI DIGITAL ZONE</option>
                  <option>SHRISTI ONLINE</option>
                  <option>SURYODAY ONLINE CENTER</option>
                  <option>RAJ ESTATE</option>
                  <option>INDIAN INSTITUTE OF GLOBEL IT</option>
                  <option>RCRD KANYA MAHAVIDYALAY</option>
                  <option>RCRD ONLINE ASSSESSMENT CENTRE</option>
                  <option>SCHOLAR COMPUTER INSTITUTE</option>
                  <option>SHRI S N TECHNOLOGIES</option>
                  <option>AP COMPUTER</option>
                  <option>ARYA PROFESSIONAL ACADEMY</option>
                  <option>AYUSH ONLINE SOLUTION</option>
                  <option>BASUDEV DIGITAL CENTER</option>
                  <option>CHATRASHAKTI INFO SOLUTION PVT LTD</option>
                  <option>CITY LAW COLLEGE</option>
                  <option>CITY MODERN ACADEMY</option>
                  <option>IFACT ACADEMY</option>
                  <option>INSTITUTE OF COMPUTER SCIENCE</option>
                  <option>SHUBHAM FOUNDATION</option>
                  <option>SIESTA COMMUNICATION PRIVATE LIMITED</option>
                  <option>SUBHASH CHANDRA BOSE ACADEMY INTER COLLEGE</option>
                  <option>SV COMPUTER ACADEMY</option>
                  <option>ACTC COMPUTER CENTER BRANCH 2</option>
                  <option>GANGA DEVI GIRLS COLLEGE</option>
                  <option>
                    (DROTCA) DHANNU RAM ONLINE TEST &amp; COMPUTER APPLICATION
                    PRIVATE LIMITED
                  </option>
                  <option>AGM INFOTECH</option>
                  <option>ASIANSUN INFOTECH (OPC) PRIVATE LIMITED</option>
                  <option>BVM INFOTECH</option>
                  <option>D.N. INSTITUTE OF TECHNOLOGY</option>
                  <option>HANSH INSTITUTE</option>
                  <option>
                    M. P. ENGLISH SCHOOL &amp; INSTITUTE OF TECHNOLOGY
                  </option>
                  <option>M.L. INSTITUTE OF TECHNOLOGY</option>
                  <option>SHRI JEET NARAYAN MEMORIAL IT INSTITUTE</option>
                  <option>WEBLINE INFOTECH</option>
                  <option>DD COLLEGE</option>
                  <option>
                    GRD INSTITUTE OF MANAGEMENT AND TECHNOLOGY - [GRD IMT],
                    DEHRADUN
                  </option>
                  <option>INSTITUTE OF TECHNOLOGY &amp; MANAGEMENT</option>
                  <option>ST JOHNS DIGITAL ACADEMY</option>
                  <option>QUEENS PUBLIC SCHOOL -II , DAMUADHUNGA</option>
                  <option>
                    UNIVERSAL DIGITAL ZONE (SWAMI VIVEKANAND COLLEGE OF
                    MANAGEMENT AND TECHNOLOGY)
                  </option>
                  <option>S.S PUBLIC SR.SEC.SCHOOL ROORKEE</option>
                  <option>
                    KLE DR. M.S. SHESHGIRI COLLEGE OF ENGINEERING AND TECHNOLOGY
                  </option>
                  <option>REVEREND TECHNOLOGIES PVT LTD</option>
                  <option>EMERIT-R.T NAGAR</option>
                  <option>ARYZE TECHNOLOGIES</option>
                  <option>SHETTY INSTITUTE OF TECHNOLOGY</option>
                  <option>YENEPOYA INSTITUTE OF TECHNOLOGY</option>
                  <option>ATPPL(ADITHYA TECH PARK PVT LTD)</option>
                  <option>BLUE LOTUS TECHNOLOGY PRIVATE LIMITED</option>
                  <option>
                    AKSHAYA COLLEGE OF ENGINEERING &amp; TECHNOLOGY
                  </option>
                  <option>SOLOMALAI COLLEGE OF ENGINEERING</option>
                  <option>UDAYA SCHOOL OF ENGINEERING</option>
                  <option>SVS TECHNOLOGY AND SOLUTIONS</option>
                  <option>GLOBAL INFO PERINTHAMANNA</option>
                  <option>MULTIDATA</option>
                  <option>CIOS DIGITALS PRIVATE LIMITED</option>
                  <option>MAXZIMO INFOTECH PRIVATE LIMITED</option>
                  <option>SEZ TECHNOLOGIES AND SOLUTIONS</option>
                  <option>BOMMA INSTITUTE OF TECHNOLOGY AND SCIENCE</option>
                  <option>SRI VENKATESWARA ENGINEERING COLLEGE</option>
                  <option>
                    JAYA INSTITUTE OF TECHNOLOGY &amp; SCIENCE FOR WOMEN
                  </option>
                  <option>UBIETY DIGITAL ZONE</option>
                  <option>
                    PRIYADARSHANI SIKHSHAN SANSTHAS MARATHI SECONDARY SCHOOL
                  </option>
                  <option>ENTRANCE CORNER</option>
                  <option>BHILAI INSTITUTE OF TECHNOLOGY</option>
                  <option>
                    KRUTI INSTITUTE OF TECHNOLOGY &amp; ENGINEERING
                  </option>
                  <option>TECHSOLVE DESIGNING &amp; GRAFIX SOLUTION</option>
                  <option>SHREE DIGITAL ONLINE EXAM CENTER</option>
                  <option>KAVYARATNA INFO SERVICES</option>
                  <option>DECIPHER TECHNOLOGIES</option>
                  <option>
                    TRINITY INSTITUTE OF TECHNOLOGY &amp; RESEARCH
                  </option>
                  <option>SARWA DHARMA MAHAVIDHYALAYA</option>
                  <option>SHIV SHAKTI ONLINE SOLUTION</option>
                  <option>SHRI B.P.COMPUTER ACADEMY</option>
                  <option>WISDOM COMPUTER INSTITUTE</option>
                  <option>BHAGWAN MAHAVIR ONLINE EXAM CENTER</option>
                  <option>SIDDHI VINAYAK ONLINE EXAMINATION CENTER</option>
                  <option>SHRI SATYA SAI PG COLLEGE FOR WOMEN</option>
                  <option>
                    ARYA INSTITUTE OF ENGINEERING &amp; TECHNOLOGY (BLOCK -1)
                  </option>
                  <option>
                    ARYA INSTITUTE OF ENGINEERING &amp; TECHNOLOGY (BLOCK -2)
                  </option>
                  <option>
                    ARYA COLLEGE OF INDUSTRIAL TRAINING AND VOCATIONAL COURSES
                    PVT LTD
                  </option>
                  <option>RAJASTHAN COLLEGE OF ENGINEERING FOR WOMEN</option>
                  <option>SJM COLLEGE FOR GIRLS</option>
                  <option>R S MEMORIAL INSTITUTE OF TECHNOLOGY</option>
                  <option>BIYANI GIRLS B.ED. COLLEGE</option>
                  <option>SANSKRITI COLLEGE</option>
                  <option>VEGMINE INFOTECH</option>
                  <option>RAWAT P.G. GIRLS COLLEGE</option>
                  <option>SK PRINCE INFOTECH</option>
                  <option>KMMP SCHOOL</option>
                  <option>SHREE KIRTARAM SHIKSHAN SANSTHAN</option>
                  <option>KOTA ONLINE IT EDUCATION CENTER</option>
                  <option>SURENDRA GROUP OF INSTITUTIONS</option>
                  <option>BUDDHA GROUP OF INSTITUTIONS</option>
                  <option>MUSKAN INFOTECH</option>
                  <option>QUEENS PUBLIC SENIOR SECONDARY SCHOOL -I</option>
                  <option>GLOBAL BUSINESS SCHOOL</option>
                  <option>GYANVEER PVT ITI</option>
                  <option>NAVJYOTI CONVENT SR. SEC. SCHOOL</option>
                  <option>
                    HON SHRI BABANRAO PACHPUTE VICHARDHARA TRUSTS PARIKRAMA
                    GROUP OF INSTITUTIONS,COLLEGE OF ENGINEERI
                  </option>
                  <option>MOUNT LITERA ZEE SCHOOL</option>
                  <option>MAA ASSESSMENT</option>
                  <option>KTI FUTURE SOLUTION (KSHETRABASI ITI)</option>
                  <option>VISHNU SOLUTIONS PVT. LTD.</option>
                  <option>DIGITAL VISION ONLINE PVT. LTD.</option>
                  <option>STUDY WEB COMPUTERS</option>
                  <option>INDO GLOBAL COLLEGES</option>
                  <option>ARYA COLLEGE OF ENGINEERING AND IT</option>
                  <option>
                    BABU BANARSI DAS INSTITUTE OF TECHNOLOGY(BBDIT)
                  </option>
                  <option>INSTITUTE OF MANAGEMENT AND RESEARCH(IMR)</option>
                  <option>TISSA TECHNOLOGY</option>
                  <option>INSTITUTE FOR EDUCATION</option>
                  <option>I CUBE DIGITAL</option>
                  <option>GLOBAL IT SOLUTIONS</option>
                  <option>EAST DELHI ASSESSMENT</option>
                  <option>BUDDHAM TECHNOLOGY</option>
                  <option>GANDHI ENGINEERING COLLEGE</option>
                  <option>RAJENDRANATH COLLEGE OF POLYTECHNIC</option>
                  <option>
                    KANAD INSTITUTE OF ENGINEERING &amp; MANAGEMENT
                  </option>
                  <option>R. A. SHAW PRIVATE ITI</option>
                  <option>SHANTINIKETAN POLYTECHNIC, DURGAPUR</option>
                  <option>RAMAKRISHNA MISSION SIKSHANAMANDIRA</option>
                  <option>
                    GOVERNMENT COLLEGE OF ENGINEERING &amp; LEATHER TECHNOLOGY
                  </option>
                  <option>TECHNO MAIN SALTLAKE</option>
                  <option>WEBEL HUMAN RESOURCE DEVELOPMENT CENTER</option>
                  <option>BHAWANI INFOTECH</option>
                  <option>MALLABHUM INSTITUTE OF TECHNOLOGY</option>
                  <option>SLBS ENGINEERING COLLEGE</option>
                  <option>
                    RATNAGIRI DIGITAL ZONE (A UNIT OF RATNAGIRI SEWA SANSTHAN)
                  </option>
                  <option>THE LEARNING SPACE</option>
                  <option>BS INFOTECH</option>
                  <option>NES DIGITAL ZONE</option>
                  <option>ST. K.C. MEMORIAL ENGISH SCHOOL</option>
                  <option>MAA SHEETLA INFOTECH</option>
                  <option>DBMS CAREER ACADEMY</option>
                  <option>LAHOWAL COLLEGE</option>
                  <option>STUDYLINK ACADEMY</option>
                  <option>
                    GUWAHATI ACADEMY OF TERTIARY EDUCATION (GATE INSTITUTE)
                  </option>
                  <option>TAGORE GOVERNMENT COLLEGE OF EDUCATION</option>
                  <option>DATASCRIBE CONSULTACY SERVICES</option>
                  <option>SRI SAIRAM COLLEGE OF ENGINEERING</option>
                  <option>MBC ONLINE TESTING CENTER</option>
                  <option>PRAGYA TECHNOLOGIES</option>
                  <option>
                    FATIMA MICHAEL COLLEGE OF ENGINEERING &amp; TECHNOLOGY
                  </option>
                  <option>
                    TAGORE INSTITUTE OF ENGINEERING AND TECHNOLOGY
                  </option>
                  <option>PVKK INSTITUTE OF TECHNOLOGY</option>
                  <option>MOOKAMBIKA TECHNICAL CAMPUS</option>
                  <option>ILM COLLEGE OF ENGINEERING AND TECHNOLOGY</option>
                  <option>MUTHOOT INSTITUTE OF TECHNOLOGY &amp; SCIENCE</option>
                  <option>CLOUD9 CYBERSPACE</option>
                  <option>CENTOS ASSESSMENT SCHOOL</option>
                  <option>MUDRA INSTITUTE OF ASSESSMENT</option>
                  <option>RIO TECH</option>
                  <option>UDAYA INSTITUTE OF TECHNOLOGY</option>
                  <option>EXATECH</option>
                  <option>K P M TECHNOLOGIES AND SOLUTIONS</option>
                  <option>LAXMAN IT ACADEMY</option>
                  <option>
                    DARIPALLY ANANTHA RAMULU COLLEGE OF ENGINEERING &amp;
                    TECHNOLOGY
                  </option>
                  <option>
                    PADMABHUSHAN VASANTDADA PATIL PRATISHTHAN'S COLLEGE OF
                    ENGINEERING
                  </option>
                  <option>
                    THAKUR VIDYA MANDIR HIGH SCHOOL &amp; JUNIOR COLLEGE
                  </option>
                  <option>DG ENTERPRISES</option>
                  <option>KNOWLEDGE TEST CENTER</option>
                  <option>DISHA COMPUTER EDUCATION, BEED</option>
                  <option>
                    CHHATRAPATI SHIVAJI INSTITUTE OF TECHNOLOGY (CSIT)
                  </option>
                  <option>MANSA COLLEGE BLOCK A</option>
                  <option>MANSA COLLEGE BLOCK B</option>
                  <option>SANJIVANI INSTITUTE OF PHARMACY</option>
                  <option>
                    COLUMBIA INSTITUTE OF ENGINEERING AND TECHNOLOGY
                  </option>
                  <option>VADODARA INSTITUTE OF ENGINEERING</option>
                  <option>LAKSHMI NARAIN COLLEGE OF TECHNOLOGY (LNCT)</option>
                  <option>VINDHYA INSTITUTE OF TECHNOLOGY &amp; SCIENCE</option>
                  <option>SARTHAK EDUCATIONAL TRUST( ABS ACADEMY)</option>
                  <option>INSTITUTE OF INSTRUMENTATION &amp; METROLOGY</option>
                  <option>INDUS TECHLAB AT TALENIQ</option>
                  <option>INSPIRIA KNOWLEDGE CAMPUS</option>
                  <option>BUDHA GROUP OF INSTITUTIONS</option>
                  <option>A.V.INSTITUTE OF NURSING AND MEDICAL SCIENCES</option>
                  <option>JAI LAKSHMI INSTUTE OF TECHNOLOGY</option>
                  <option>FASTER COMPUTER EDUCATION</option>
                  <option>GCOM CENTRE</option>
                  <option>MARWAR KESARI ONLINE EXAM CENTER</option>
                  <option>RASTRIYA ONLINE CENTER</option>
                  <option>GSS TECHNO ONLINE EXAMINATION PVT LTD</option>
                  <option>SHIVA INFOTECH ONLINE TESTING SOLUTIONS</option>
                  <option>MINAXI SOLUTION</option>
                  <option>NITA KAUR INNOVATIVE TECHNOCOM PVT LTD.</option>
                  <option>ST. JOHN'S DIGITAL ACADEMY</option>
                  <option>LAKSHMI BALAJI COMPUTERS (VIJAYAWADA)</option>
                  <option>PROGRESSIVE ONLINE TESTING SERVICES</option>
                  <option>
                    JSPM'S IMPERIAL COLLEGE OF ENGINEERING &amp; RESEARCH
                  </option>
                  <option>
                    HON.SHRI. BABANRAO PACHPUTE VICHARDHARA TRUST'S PARIKRAMA
                    GROUP OF INSTITUTIONS,COLLEGE OF ENGINEERI
                  </option>
                  <option>
                    SANJAY RUNGTA GROUP OF INSTITUTIONS (CENTRE-1)
                  </option>
                  <option>
                    SANJAY RUNGTA GROUP OF INSTITUTIONS (CENTRE-2)
                  </option>
                  <option>KRISHNA ENGINEERING COLLEGE</option>
                  <option>SHREE RAM EXAM CENTRE</option>
                  <option>BVM COLLEGE OF MANAGEMENT &amp; EDUCATION</option>
                  <option>HEADWAY IT SERVICES</option>
                  <option>SRS DIGITAL INSTITUTE</option>
                  <option>INSTITUTE FOR ADVANCED COMPUTER TECHNOLOGY</option>
                  <option>
                    HON.SHRI. BABANRAO PACHPUTE VICHARDHARA TRUST'S PARIKRAMA
                    GROUP OF INSTITUTIONS,COLLEGE OF ENGINEERI
                  </option>
                  <option>
                    HON.SHRI. BABANRAO PACHPUTE VICHARDHARA TRUST'S PARIKRAMA
                    GROUP OF INSTITUTIONS,COLLEGE OF ENGINEERI
                  </option>
                  <option>
                    HON.SHRI. BABANRAO PACHPUTE VICHARDHARA TRUST'S PARIKRAMA
                    GROUP OF INSTITUTIONS,COLLEGE OF ENGINEERI
                  </option>
                  <option>DN institute of technology Varanasi</option>
                  <option>
                    HON.SHRI. BABANRAO PACHPUTE VICHARDHARA TRUST'S PARIKRAMA
                    GROUP OF INSTITUTIONS,COLLEGE OF ENGINEERI
                  </option>
                  <option>R.S TECHNICAL INSTITUTE</option>
                  <option>JYOTHY INSTITUTE OF TECHNOLOGY</option>
                  <option>AL Kabir polytechnic Jamshedpur</option>
                  <option>ADARSHA COLLEGE OF ENGINEERING</option>
                  <option>INDUS TECHLAB</option>
                  <option>SKY INSTITUTE</option>
                  <option>GLOBAL INSTITUTE OF TECHNOLOGY</option>
                  <option>DIVYA DIGITAL ZONE</option>
                  <option>INDERPRASTHA ENGINEERING COLLEGE</option>
                  <option>ARANYA ONLINE SERVICES PVT LTD</option>
                  <option>SIDHHIVINAYAK COMPUTER PVT LTD</option>
                  <option>ACTIVE IT SOLUTION</option>
                  <option>ASTER IT AND MANAGEMENT</option>
                  <option>BLUEMOON IT SOLUTIONS</option>
                  <option>MICROHARD DIGITAL CENTRE PVT. LTD.</option>
                  <option>MKS CYBERCITY PVT.LTD</option>
                  <option>TECHNO PARK</option>
                  <option>AL-KABIR POLYTECHNIC</option>
                  <option>
                    COLLEGE OF ENGINEERING &amp; TECHNOLOGY, AKOLA
                  </option>
                  <option>G H RAISONI COLLEGE OF ENGINEERING</option>
                  <option>
                    SAGAR INSTITUTE OF RESEARCH AND TECHNOLOGY- (EXCELLENCE
                    BLOCK)
                  </option>
                  <option>ROYAL SOLUTIONS ONLINE EXAMINATION CENTER</option>
                  <option>AIS ASSESSMENT ZONE10</option>
                  <option>AIS ASSESSMENT ZONE</option>
                  <option>
                    GOVERNMENT COLLEGE OF ENGINEERING AND LEATHER TECHNOLOGY
                  </option>
                  <option>
                    GOVERNMENT COLLEGE OF ENGINEERING &amp; LEATHER TECHNOLOGY
                  </option>
                  <option>
                    SCHOLARS INSTITUTE OF TECHNOLOGY AND MANAGEMENT (SITM)
                  </option>
                  <option>INDRAPRASTHA PUBLIC SCHOOL</option>
                  <option>SANGWAN MODEL SECONDARY SCHOOL</option>
                  <option>DIVINE HAPPY SENIOR SECONDARY SCHOOL</option>
                  <option>BAL VIDYA MANDIR MODEL SCHOOL</option>
                  <option>BAL NIKUNJ INTERCOLLEGE BLOCK-A</option>
                  <option>JVC GROUP OF INSTITUTIONS</option>
                  <option>VEENA VIDYA NIKETAN, RAJEEVNAGAR</option>
                  <option>KCG COLLEGE OF TECHNOLOGY</option>
                  <option>SHAILENDRA EDUCATION SOCIETY</option>
                  <option>SILVER OAK UNIVERSITY</option>
                  <option>A.V COLLEGE OF ARTS SCIENCE AND COMMERCE</option>
                  <option>NATIONAL ENGLISH SCHOOL</option>
                  <option>SANGWAN MODEL SECONDARY SCHOOL</option>
                  <option>MOHAMED SATHAK COLLEGE OF ARTS AND SCIENCE</option>
                  <option>
                    ADV. SHARDUL SUDHAKAR JADHAVAR COLLEGE CAMPUS 2
                  </option>
                  <option>SHAILENDRA EDUCATION SOCIETY</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="3">
              <FormGroup className="mb-0">
                <Label>Date</Label>
                <Input type="date" />
              </FormGroup>
            </Col>
            <Col xl="5">
              <FormGroup className="mb-0">
                <Label>Is Bio Matric Captured By Observer?</Label>
                <Input name="select" type="select">
                  <option>Select Option</option>
                  <option>No</option>
                  <option>Yes</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl="7">
              <div className="btn-container d-flex align-items-end justify-content-end h-100">
                <Button>filter</Button>
                <Button>Clear</Button>
              </div>
            </Col>
          </Row>
        </Card>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0">
                <h3 className="mb-0">Card tables</h3>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
                showStatus
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={theading?.length + 2}>
                      <h5 className="text-center">No Data Available</h5>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Form>
          <FormGroup>
            <Label>User Role*</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>First Name*</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Last Name</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input />
          </FormGroup>
          <FormGroup>
            <Label>Mobile*</Label>
            <Input type="number" />
          </FormGroup>
          <FormGroup>
            <Label>Password*</Label>
            <Input type="password" />
          </FormGroup>
          <FormGroup>
            <Label>Status</Label>
            <Input type="switch" />
          </FormGroup>
          <FormGroup>
            <Label>User Profile Image</Label>
            <Input
              type="file"
              onChange={(e) => handleFile(e.target?.files[0])}
            />
            {data && <img src={data} width={200} className="p-3" alt="" />}
          </FormGroup>
        </Form>
      </CustomModal>
    </>
  );
};

export default BioMatric;
