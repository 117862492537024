import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
  CardBody,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// core components
import { Link } from "react-router-dom";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useRef, useState } from "react";
import DataService from "../../../services/requestApi";
import Pagination from "../Pagination";
import { toast } from "react-toastify";

const District = () => {
  const [modal, setModal] = useState(false);
  const [allForms, setAllForms] = useState([]);
  const [rowValue, setRowValue] = useState();
  const [isEditData, setIsEditData] = useState(false);
  const [showPrePage] = useState(10);
  const [viewData, setViewData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  useEffect(() => {
    getAllForms();
  }, []);

  const getAllForms = async () => {
    setDataLoader(true);
    const { data } = await DataService.GetAllForms();
    console.log("data::: ", data);
    if (data.message == "Success") {
      setAllForms(data.data);
      setFilteredData(data.data);
    }
    setDataLoader(false);
  };

  const handleSubmit = async (data) => {
    console.log("data", data);
    setLoading(true);
    try {
      const res = await DataService.UploadForm(data);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
        setModal(false);
      } else {
        setModal(false);
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getAllForms();
    setLoading(false);
  };

  const updateUserRole = (item) => {
    setRowValue(item);
    setModal(true);
  };
  const updateForm = async (item) => {
    setLoading(true);
    console.log("item::: ", item);
    try {
      const res = await DataService.UpdateForm(rowValue._id, item);
      console.log("res::: ", res);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
        setModal(false);
      } else {
        setModal(false);
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getAllForms();
    setLoading(false);
  };
  const deleteForm = async (item) => {
    setLoading(true);
    try {
      const res = await DataService.DeleteFormData(item._id);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getAllForms();
    setLoading(false);
  };
  const formSchema = Yup.object().shape({
    title: Yup.string().required("required"),
  });
  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = allForms?.filter((item) => {
      return item?.title?.toLowerCase().includes(searchQuery?.toLowerCase());
    });
    setFilteredData(filtered);
  };

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Forms
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  New Forms
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setViewData(false);
                setRowValue();
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />{" "}
              <span className="px-2">Add new</span>
            </Button>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Forms</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>

              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">title</th>
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                {dataLoader ? (
                  <tbody>
                    <tr>
                      <td colSpan={5}>
                        <h5 className="text-center">Loading...</h5>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {filteredData?.length > 0 ? (
                      <>
                        {filteredData
                          ?.slice(pagination?.start, pagination?.end)
                          ?.map((data, idx) => (
                            <tr key={idx}>
                              <td>{pagination.start + idx + 1}</td>
                              <td>{data?.title || "-"}</td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() => {
                                        updateUserRole(data);
                                        setIsEditData(false);
                                        setViewData(false);
                                      }}
                                    >
                                      <i className="fas fa-pencil-alt" />
                                      Edit
                                    </DropdownItem>
                                    {/* <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      setModal(!modal);
                                      updateUserRole(data);
                                      setViewData(true);
                                    }}
                                  >
                                    <i className="	fas fa-eye" />
                                    View
                                  </DropdownItem> */}
                                    <DropdownItem
                                      onClick={() => deleteForm(data)}
                                    >
                                      <i className="fas fa-trash " />
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={5}>
                            <h5 className="text-center">No Data Available</h5>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                )}
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <Pagination
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add title"
      >
        <Formik
          initialValues={{
            title: rowValue?.title || "",
          }}
          validationSchema={formSchema}
          onSubmit={(values) => {
            const dto = {
              title: values.title,
            };
            isEditData ? handleSubmit(dto) : updateForm(dto);
          }}
        >
          {({ touched, errors }) => (
            <div>
              <Form>
                <FormGroup>
                  <Label>Title</Label>
                  <span className="text-danger">*</span>
                  <Field
                    type="text"
                    name="title"
                    className={`mt-2 form-control
                          ${touched.title && errors.title ? "is-invalid" : ""}`}
                  />
                  <ErrorMessage
                    component="div"
                    name="title"
                    className="invalid-feedback"
                  />
                </FormGroup>

                <div className="btn-container">
                  <Button
                    type="submit"
                    className="w-100 bg-primary text-white"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Create"}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default District;
