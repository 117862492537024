// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navCust .active {
  background-color: rgba(0, 0, 255, 0.67) !important;

  border: 0px !important;
  border-radius: 10px !important;
  color: white !important;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
  border-left: 0px !important;
}

.navCust .active i {
  color: white !important;
}

.navbar-brand-img {
  height: 100px !important;
  max-height: 60px !important;
}

.navbar-vertical .navbar-collapse:before {
  margin: 0px !important;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/index.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;;EAElD,sBAAsB;EACtB,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".navCust .active {\n  background-color: rgba(0, 0, 255, 0.67) !important;\n\n  border: 0px !important;\n  border-radius: 10px !important;\n  color: white !important;\n}\n\n.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {\n  border-left: 0px !important;\n}\n\n.navCust .active i {\n  color: white !important;\n}\n\n.navbar-brand-img {\n  height: 100px !important;\n  max-height: 60px !important;\n}\n\n.navbar-vertical .navbar-collapse:before {\n  margin: 0px !important;\n}\n\n.truncate {\n  width: 250px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
