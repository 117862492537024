// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
  background-color: transparent !important;
}

.dashboard-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
  gap: 40px;
  height: 440px;
}
.dashboard-cards .card {
  width: 30%;
  height: 170px;
  border-radius: 10px;
}
.dashboard-cards .card .card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.table tr td {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/custom.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,SAAA;EACA,aAAA;AAEF;AADE;EACE,UAAA;EACA,aAAA;EACA,mBAAA;AAGJ;AAFI;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;AAIN;;AAcA;EACE,eAAA;AAXF","sourcesContent":[".breadcrumb {\r\n  background-color: transparent !important;\r\n}\r\n.dashboard-cards {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-wrap: wrap;\r\n  margin-top: 100px;\r\n  gap: 40px;\r\n  height: 440px;\r\n  .card {\r\n    width: 30%;\r\n    height: 170px;\r\n    border-radius: 10px;\r\n    .card-body {\r\n      display: flex;\r\n      justify-content: center;\r\n      flex-direction: column;\r\n    }\r\n  }\r\n}\r\n// ::-webkit-scrollbar-track {\r\n//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\r\n//   background-color: #f5f5f5;\r\n// }\r\n\r\n// ::-webkit-scrollbar {\r\n//   width: 0px;\r\n//   background-color: #f5f5f5;\r\n// }\r\n\r\n// ::-webkit-scrollbar-thumb {\r\n//   background-color: rgb(54, 54, 54);\r\n// }\r\n\r\n.table tr td {\r\n  font-size: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
