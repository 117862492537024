import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import DataService from "../../../services/requestApi";
import { useLocation, useHistory } from "react-router-dom";

// CreateCreateRestApi;

const CreateRestApi = () => {
  const location = useLocation();
  const history = useHistory();
  const passedData = location.state?.data;
  console.log("passedData?.status::: ", passedData?.status);
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Container fluid className="mt-5">
        <Card className="p-4 shadow mb-5">
          <Formik
            initialValues={{
              api_name: passedData?.api_name || "",
              api_type: passedData?.api_type || "",
              api_url: passedData?.api_url || "",
              paging: passedData?.paging || "",
              page_limit: passedData?.page_limit || "",
              token_required: passedData?.token_required || "",
              main_table: passedData?.main_table || "",
              api_method: passedData?.api_method || "",

              output_fields: passedData?.output_fields || [
                {
                  table: "",
                  field_name: "",
                  field_type: "",
                  encryption: "",
                  file_path: "",
                },
              ],
              input_fields: passedData?.input_fields || [
                {
                  field_name: "",
                  field_type: "",
                  encryption: "",
                  isRequired: "",
                  allowed_format: "",
                  file_path: "",
                },
              ],
              joins: passedData?.joins || [
                {
                  table_name1: "",
                  table_field1: "",
                  table_name2: "",
                  table_field2: "",
                },
              ],
              filters: passedData?.filters || [
                {
                  table: "",
                  field_name: "",
                  filterType: "",
                },
              ],
              order_by: passedData?.order_by || [
                {
                  table: "",
                  field_name: "",
                  type: "",
                },
              ],
            }}
            onSubmit={async (values) => {
              const onOffToggle = { status: toggle };
              const marge = { ...onOffToggle, ...values };
              console.log("marge::: ", marge);
              if (passedData != undefined) {
                const res = await DataService.UpdateRestApi(
                  passedData._id,
                  values
                );
                if (res.data.status) {
                  toast.success(res.data.message);
                  history.push("/admin/mobile-api");
                } else {
                  toast.error(res.data.message);
                }
              } else {
                const res = await DataService.CreateCreateRestApi(values);
                if (res.data.status) {
                  toast.success(res.data.message);
                  history.push("/admin/mobile-api");
                } else {
                  toast.error(res.data.message);
                }
              }
            }}
          >
            {({ touched, errors, values }) => (
              <div>
                <Form>
                  <Row>
                    <Col xl="3">
                      <FormGroup>
                        <Label>
                          API Name<span className="text-danger">*</span>
                        </Label>
                        <Field
                          type="text"
                          name="api_name"
                          className={` form-control
                          ${
                            touched.api_name && errors.api_name
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="api_name"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label>
                          API Type<span className="text-danger">*</span>
                        </Label>
                        <Field
                          as="select"
                          name="api_type"
                          type="select"
                          className={` form-control
                          ${
                            touched.api_type && errors.api_type
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option>select API Type</option>
                          {["list", "save"].map((item) => (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="api_type"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label>
                          API URL /apiv1/rest/*
                          <span className="text-danger">*</span>
                        </Label>
                        <Field
                          type="text"
                          name="api_url"
                          className={` form-control
                          ${
                            touched.api_url && errors.api_url
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="api_url"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label>
                          API Method<span className="text-danger">*</span>
                        </Label>
                        <Field
                          as="select"
                          name="api_method"
                          type="select"
                          className={` form-control
                          ${
                            touched.api_method && errors.api_method
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">select API Method</option>
                          {["get", "post"].map((item) => (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="api_method"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label>
                          Paging?<span className="text-danger">*</span>
                        </Label>
                        <Field
                          as="select"
                          name="paging"
                          type="select"
                          className={` form-control
                          ${
                            touched.paging && errors.paging ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">select Paging Type?</option>
                          {[
                            { name: "Yes", value: true },
                            { name: "No", value: false },
                          ]?.map((item) => (
                            <>
                              <option value={item.value}>{item.name}</option>
                            </>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="paging"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label className="d-block">
                          Page Limit<span className="text-danger">*</span>
                        </Label>
                        <Field
                          type="text"
                          name="page_limit"
                          className={` form-control
                          ${
                            touched.page_limit && errors.page_limit
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="page_limit"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label>
                          Token Required?<span className="text-danger">*</span>
                        </Label>
                        <Field
                          as="select"
                          name="token_required"
                          type="select"
                          className={` form-control
                          ${
                            touched.token_required && errors.token_required
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Token Required??</option>
                          {[
                            { name: "Yes", value: true },
                            { name: "No", value: false },
                          ].map((item) => (
                            <>
                              <option value={item.value}>{item.name}</option>
                            </>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="token_required"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="3">
                      <FormGroup>
                        <Label className="d-block">
                          Select Main Table
                          <span className="text-danger">*</span>
                        </Label>
                        <Field
                          as="select"
                          name="main_table"
                          type="select"
                          className={` form-control
                          ${
                            touched.main_table && errors.main_table
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Token Required??</option>
                          {[
                            "Exam Department",
                            "Exam Master",
                            "Exam Center",
                            "Exam Center Allocation",
                            "Exam Center Validation",
                            "Exam Center Observer Allocation",
                            "Exam Center Invigilator Allocation",
                            "Candidate",
                            "Candidate Education",
                            "Candidate BIO Metrics",
                            "Candidate IRIS",
                            "Candidate MAC Address",
                            "Candidate Exam Center",
                            "States",
                            "Cities",
                            "Villages",
                            "Settings",
                            "Users",
                            "User Roles",
                            "Invigilator Validation",
                            "Districts",
                            "Caste Categories",
                            "Type Of Disabilities",
                            "UserBioMetrics",
                          ].map((item) => (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="main_table"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="12">
                      <h3>Join Tables</h3>
                      <div className="api-table-container">
                        <FieldArray
                          name="joins"
                          render={(arrayHelpers) => (
                            <>
                              <Table bordered responsive>
                                {values?.joins && values?.joins.length > 0 ? (
                                  values?.joins.map((item, index) => (
                                    <>
                                      {index == 0 && (
                                        <thead>
                                          <tr className="bg-green">
                                            <th>TABLE 1 </th>
                                            <th>FIELD 1</th>
                                            <th>TABLE 2</th>
                                            <th>FIELD 2</th>
                                            <th>JOIN TYPE</th>
                                            <th>
                                              <Button
                                                className="px-2 py-0"
                                                onClick={() => {
                                                  arrayHelpers.push(index, {});
                                                }}
                                              >
                                                +
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                      )}

                                      <tbody>
                                        <tr key={index}>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`joins[${index}].table_name1`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`joins[${index}].table_field1`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`joins[${index}].table_name2`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`joins[${index}].table_field2`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`input_fields[${index}].required`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>

                                          <td>
                                            {!index == 0 && (
                                              <Button
                                                className="px-2 py-0 btn-danger"
                                                onClick={() => {
                                                  if (index > 0) {
                                                    arrayHelpers.remove(index);
                                                  }
                                                }}
                                              >
                                                -
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </>
                                  ))
                                ) : (
                                  <button
                                    className="button btn btn-primary mx-3"
                                    onClick={() => {
                                      arrayHelpers.push("");
                                    }}
                                  >
                                    Add
                                  </button>
                                )}
                              </Table>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col xl="12">
                      <h3 className="">filters</h3>
                      <div className="api-table-container">
                        <FieldArray
                          name="filters"
                          render={(arrayHelpers) => (
                            <>
                              <Table bordered responsive>
                                {values?.filters &&
                                values?.filters.length > 0 ? (
                                  values?.filters.map((item, index) => (
                                    <>
                                      {index == 0 && (
                                        <thead>
                                          <tr className="bg-green">
                                            <th>TABLE</th>
                                            <th>FIELD</th>
                                            <th>FILTER TYPE</th>
                                            <th>
                                              <Button
                                                className="px-2 py-0"
                                                onClick={() => {
                                                  arrayHelpers.push(index, {});
                                                }}
                                              >
                                                +
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                      )}

                                      <tbody>
                                        <tr key={index}>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`filters[${index}].table`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`filters[${index}].field_name`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`filters[${index}].filter_type`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["=", ">=", "<=", "LIKE"].map(
                                                  (item) => (
                                                    <>
                                                      <option value={item}>
                                                        {item}
                                                      </option>
                                                    </>
                                                  )
                                                )}
                                              </Field>
                                            </FormGroup>
                                          </td>

                                          <td>
                                            {!index == 0 && (
                                              <Button
                                                className="px-2 py-0 btn-danger"
                                                onClick={() => {
                                                  if (index > 0) {
                                                    arrayHelpers.remove(index);
                                                  }
                                                }}
                                              >
                                                -
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </>
                                  ))
                                ) : (
                                  <button
                                    className="button btn btn-primary mx-3"
                                    onClick={() => {
                                      arrayHelpers.push("");
                                    }}
                                  >
                                    Add
                                  </button>
                                )}
                              </Table>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col xl="12">
                      <h3 className="">Order By</h3>
                      <div className="api-table-container">
                        <FieldArray
                          name="order_by"
                          render={(arrayHelpers) => (
                            <>
                              <Table bordered responsive>
                                {values?.order_by &&
                                values?.order_by.length > 0 ? (
                                  values?.order_by.map((item, index) => (
                                    <>
                                      {index == 0 && (
                                        <thead>
                                          <tr className="bg-green">
                                            <th>TABLE</th>
                                            <th>FIELD</th>
                                            <th>FILTER TYPE</th>
                                            <th>
                                              <Button
                                                className="px-2 py-0"
                                                onClick={() => {
                                                  arrayHelpers.push(index, {});
                                                }}
                                              >
                                                +
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                      )}

                                      <tbody>
                                        <tr key={index}>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`order_by[${index}].table`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`order_by[${index}].field_name`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`order_by[${index}].type`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["ASC", "DESC"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>

                                          <td>
                                            {!index == 0 && (
                                              <Button
                                                className="px-2 py-0 btn-danger"
                                                onClick={() => {
                                                  if (index > 0) {
                                                    arrayHelpers.remove(index);
                                                  }
                                                }}
                                              >
                                                -
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </>
                                  ))
                                ) : (
                                  <button
                                    className="button btn btn-primary mx-3"
                                    onClick={() => {
                                      arrayHelpers.push("");
                                    }}
                                  >
                                    Add
                                  </button>
                                )}
                              </Table>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col xl="12">
                      <h3 className="">Output Fields</h3>
                      <div className="api-table-container">
                        <FieldArray
                          name="output_fields"
                          render={(arrayHelpers) => (
                            <>
                              <Table bordered responsive>
                                {values?.output_fields &&
                                values?.output_fields.length > 0 ? (
                                  values?.output_fields.map((item, index) => (
                                    <>
                                      {index == 0 && (
                                        <thead>
                                          <tr className="bg-green">
                                            <th>TABLE </th>
                                            <th>FIELD</th>
                                            <th>FIELD TYPE</th>
                                            <th>ENCRYPTION?</th>
                                            <th>FILE PATH</th>
                                            <th>
                                              <Button
                                                className="px-2 py-0"
                                                onClick={() => {
                                                  arrayHelpers.push(index, {});
                                                }}
                                              >
                                                +
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                      )}

                                      <tbody>
                                        <tr key={index}>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`output_fields[${index}].table`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`output_fields[${index}].field_name`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`output_fields[${index}].field_type`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`output_fields[${index}].encryption`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {[
                                                  { name: "Yes", value: true },
                                                  { name: "No", value: false },
                                                ].map((item) => (
                                                  <>
                                                    <option value={item.value}>
                                                      {item.name}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>

                                          <td>
                                            <FormGroup>
                                              <Field
                                                type="text"
                                                placeholder="File Path"
                                                name={`output_fields[${index}].filePath`}
                                                className={`form-control `}
                                              />
                                            </FormGroup>
                                          </td>
                                          <td>
                                            {!index == 0 && (
                                              <Button
                                                className="px-2 py-0 btn-danger"
                                                onClick={() => {
                                                  if (index > 0) {
                                                    arrayHelpers.remove(index);
                                                  }
                                                }}
                                              >
                                                -
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </>
                                  ))
                                ) : (
                                  <button
                                    className="button btn btn-primary mx-3"
                                    onClick={() => {
                                      arrayHelpers.push("");
                                    }}
                                  >
                                    Add
                                  </button>
                                )}
                              </Table>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col xl="12">
                      <h3 className="">Input Fields</h3>
                      <div className="api-table-container mb-3">
                        <FieldArray
                          name="input_fields"
                          render={(arrayHelpers) => (
                            <>
                              <Table bordered responsive>
                                {values?.input_fields &&
                                values?.input_fields.length > 0 ? (
                                  values?.input_fields.map((item, index) => (
                                    <>
                                      {index == 0 && (
                                        <thead>
                                          <tr className="bg-green">
                                            <th>FIELD </th>
                                            <th>FIELD TYPE</th>
                                            <th>ENCRYPTION?</th>
                                            <th>REQUIRED</th>
                                            <th>ALLOWED FORMAT</th>
                                            <th>FILE PATH</th>
                                            <th>
                                              <Button
                                                className="px-2 py-0"
                                                onClick={() => {
                                                  arrayHelpers.push(index, {});
                                                }}
                                              >
                                                +
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>
                                      )}

                                      <tbody>
                                        <tr key={index}>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`input_fields[${index}].field_name`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`input_fields[${index}].field_type`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {["get", "post"].map((item) => (
                                                  <>
                                                    <option value={item}>
                                                      {item}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`input_fields[${index}].encryption`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {[
                                                  { name: "Yes", value: true },
                                                  { name: "No", value: false },
                                                ].map((item) => (
                                                  <>
                                                    <option value={item.value}>
                                                      {item.name}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                as="select"
                                                name={`input_fields[${index}].isRequired`}
                                                type="select"
                                                className={` form-control`}
                                              >
                                                <option value="">select</option>
                                                {[
                                                  { name: "Yes", value: true },
                                                  { name: "No", value: false },
                                                ]?.map((item) => (
                                                  <>
                                                    <option value={item.value}>
                                                      {item.name}
                                                    </option>
                                                  </>
                                                ))}
                                              </Field>
                                            </FormGroup>
                                          </td>

                                          <td>
                                            <FormGroup>
                                              <Field
                                                type="text"
                                                placeholder="File Path"
                                                name={`input_fields[${index}].allowed_format`}
                                                className={`form-control `}
                                              />
                                            </FormGroup>
                                          </td>
                                          <td>
                                            <FormGroup>
                                              <Field
                                                type="text"
                                                placeholder="File Path"
                                                name={`input_fields[${index}].file_path`}
                                                className={`form-control `}
                                              />
                                            </FormGroup>
                                          </td>
                                          <td>
                                            {!index == 0 && (
                                              <Button
                                                className="px-2 py-0 btn-danger"
                                                onClick={() => {
                                                  if (index > 0) {
                                                    arrayHelpers.remove(index);
                                                  }
                                                }}
                                              >
                                                -
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </>
                                  ))
                                ) : (
                                  <button
                                    className="button btn btn-primary mx-3"
                                    onClick={() => {
                                      arrayHelpers.push("");
                                    }}
                                  >
                                    Add
                                  </button>
                                )}
                              </Table>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col xl="12">
                      <Label className="d-block">Status</Label>
                      <Label className="custom-toggle custom-toggle-warning">
                        <input
                          type="checkbox"
                          name="status"
                          onChange={(e) => {
                            console.log("setToggle::: ", e.target.checked);
                            setToggle(e.target.checked);
                          }}
                          defaultChecked={passedData?.status}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="Off"
                          data-label-on="On"
                        />
                      </Label>
                    </Col>
                  </Row>

                  <div className="btn-container mt-4">
                    <Button type="submit" className=" bg-primary text-white">
                      Create
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </Card>
      </Container>
    </>
  );
};

export default CreateRestApi;
