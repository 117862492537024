import http from "./http-common";

class DataService {
  // Login Services
  Login(data) {
    return http.get(
      `admin/login?email=${data?.email}&password=${data?.password}`
    );
  }

  //district
  GetAllDistrict() {
    return http.get("district/admin/all");
  }

  AddNewDistrict(data) {
    return http.post("district/create", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  UpdateDistrictData(id, data) {
    return http.patch(`district/update/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  UploadDistrict(data) {
    return http.post("district/excel", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  }

  DeleteDistrict(id) {
    return http.delete(`district/delete/${id}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  }

  //role
  GetAllRole() {
    return http.get("role/admin/all");
  }

  AddUserRole(data) {
    return http.post("role/create", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  UpdateRoleData(id, data) {
    return http.patch(`role/update/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  UploadRole(data) {
    return http.post("role/excel", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  }

  //user
  GetAllUser() {
    return http.get("user/admin/all");
  }

  AddUserData(data) {
    return http.post("/user/admin/create", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  UpdateUserData(id, data) {
    return http.patch(`/user/admin/update/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  GetDistrictVarificationData(id) {
    return http.get(`/treasury_verification/admin/district/all/${id}`);
  }

  GetAllJSON() {
    return http.get("district/admin/json/all");
  }

  UploadUser(data) {
    return http.post("user/excel", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  }

  //center

  GetAllCenter() {
    return http.get(`center/admin/all`);
  }

  GetAllFormJson(id) {
    return http.get(`/admin/form/id/${id}`);
  }

  UpdateCenterJson(id, data) {
    return http.patch(`admin/form/update/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  AddNewCenter(data) {
    return http.post("center/create", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  UploadCenter(data) {
    return http.post("center/excel", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  }

  UpdateCenterData(id, data) {
    return http.patch(`center/update/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  GetCenterVarificationData(id) {
    return http.get(`center_verification/admin/centerVerification/${id}`);
  }

  GetAllForms() {
    return http.get(`admin/form/getAll`);
  }

  UpdateForm(id, data) {
    return http.patch(`admin/form/update/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  UploadForm(data) {
    return http.post("admin/form/create", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  }

  UploadForm(data) {
    return http.post("admin/form/create", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  }

  DeleteFormData(id) {
    return http.delete(`admin/form/delete/${id}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    });
  }

  ApproveRejectForm(id, data) {
    return http.patch(`center_verification/admin/update/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  // event

  GetAllEvent() {
    return http.get(`event/all`);
  }

  AddNewEvent(data) {
    return http.post("event/create", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  DeleteEvent(id) {
    return http.delete(`event/delete/${id}`);
  }

  UpdateEventData(id, data) {
    return http.patch(`event/update/${id}`, data);
  }

  GetDistrictByEvent(id) {
    return http.get(`event/districtByEvent/${id}`);
  }

  GetTreasuryMaterialVerification(id) {
    return http.get(`treasury_material_verification/admin/id/${id}`);
  }

  GetDistrictByEvent(type, id) {
    return http.get(`event/districtByEvent?type=${type}&id=${id}`);
  }
}

export default new DataService();
