/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import CandidateReports from "views/examples/CandidateReports";
import Icons from "views/examples/Icons.js";
import ObserverReports from "views/examples/ObserverReports";
import SupportStaffReport from "views/examples/SupportStaffReport";
import TestCenterReports from "views/examples/TestCenterReports";
import Candidate from "views/examples/candidate/Candidate";
import CandidateExam from "views/examples/candidate/CandidateExam";
import DataImport from "views/examples/dataImport/DataImport";
import CenterAllocation from "views/examples/master/CenterAllocation";
import CenterInvigilator from "views/examples/master/CenterInvigilator";
import CenterObserver from "views/examples/master/CenterObserver";
import Cities from "views/examples/master/Cities";
import ExamCenter from "views/examples/master/ExamCenter";
import ExamDepartment from "views/examples/master/ExamDepartment";
import ExamMaster from "views/examples/master/ExamMaster";
import Incidents from "views/examples/master/Incidents";
import InvigilatorValidation from "views/examples/master/InvigilatorValidation";
import States from "views/examples/master/States";
import UserRole from "views/examples/master/UserRole";
import Users from "views/examples/master/Users";
import RestAPI from "views/examples/mobileAPI/RestAPI";
import CreateRestApi from "views/examples/mobileAPI/CreateRestApi";
import BioMatric from "views/examples/trackReport/BioMatric";
import CenterValidated from "views/examples/trackReport/CenterValidated";
import IncompleteProfile from "views/examples/trackReport/IncompleteProfile";
import InvigilatorValidated from "views/examples/trackReport/InvigilatorValidated";
import UserTrack from "views/examples/trackReport/UserTrack";
import AuditTrail from "views/examples/webAdmin/AuditTrail";
import Settings from "views/examples/webAdmin/Settings";
import Tables from "views/examples/Tables";
import Login from "views/examples/Login";
import Register from "views/examples/Register";
import District from "views/examples/master/District";
import DistrictValidation from "views/examples/verification/toValidation/DistrictValidation";
import TreasuryDashboard from "views/examples/verification/toValidation/Dashboard";
import ToMaterialValidation from "views/examples/verification/toValidation/ToMaterialvalidation";

import CenterDashboard from "views/examples/verification/centerValidation/Dashboard";
import CenterValidation from "views/examples/verification/centerValidation/CenterValidation";
import Center from "views/examples/master/Center";
import AddForm from "views/examples/dynaminForms/AddForm";
import FormJson from "views/examples/dynaminForms/FormJson";
import EventList from "views/examples/event/eventList";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-tv-2 text-primary",
    component: Login,
    layout: "/auth",
    show: false,
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-solid fa-list",
    component: Index,
    layout: "/admin",
    show: true,
  },
  {
    name: "Masters",
    id: "masters",
    icon: "fas fa-user-shield text-black",
    layout: "/admin",
    show: true,
    collapse: true,
    views: [
      {
        path: "/district",
        name: "district",
        component: District,
        layout: "/admin",
      },
      {
        path: "/role",
        name: "Role",
        component: UserRole,
        layout: "/admin",
      },
      {
        path: "/center",
        name: "Center",
        component: Center,
        layout: "/admin",
      },
    ],
  },
  {
    name: "User",
    path: "/user",
    icon: "fas fa-user ",
    component: Users,
    layout: "/admin",
    show: true,
  },

  {
    name: "T/O Verification",
    id: "treasury-verification",
    icon: "ni ni-planet",
    layout: "/admin",
    show: true,
    collapse: true,
    views: [
      {
        path: "/treasury-verification/dashboard",
        name: "Dashboard",
        component: TreasuryDashboard,
        layout: "/admin",
      },
      {
        path: "/district-verification",
        name: "T/O Verification",
        component: DistrictValidation,
        layout: "/admin",
      },
      {
        path: "/to-material-verification",
        name: "T/O Material Verification",
        component: ToMaterialValidation,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Center Verification",
    id: "center-verifications",
    icon: "ni ni-planet",
    layout: "/admin",
    show: true,
    collapse: true,
    views: [
      {
        path: "/center-verification/dashboard",
        name: "Dashboard",
        component: CenterDashboard,
        layout: "/admin",
      },
      {
        path: "/center-verification-list",
        name: "Center Verification",
        component: CenterValidation,
        layout: "/admin",
      },
    ],
  },

  {
    name: "Event",
    path: "/event",
    icon: "fa fa-calendar",
    component: EventList,
    layout: "/admin",
    show: true,
  },

  {
    name: "Dynamic Json",
    id: "dynamic-json",
    icon: "fa fa-file-code",
    layout: "/admin",
    show: true,
    collapse: true,
    views: [
      {
        path: "/add-new-forms ",
        name: "New Forms",
        component: AddForm,
        layout: "/admin",
      },
      {
        path: "/form-json",
        name: "Form JSON",
        component: FormJson,
        layout: "/admin",
      },
    ],
  },
];
export default routes;
