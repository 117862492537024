import {
  Card,
  CardHeader,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  Table,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// core components
import { Link, useHistory } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useRef, useState } from "react";
import CustomTable from "components/table/CustomTable";
import DataService from "../../../services/requestApi";
import Pagination from "../Pagination";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";
import SampleFile from "../../../assets/samplefile/user_center_data.xlsx";
import { toast } from "react-toastify";

const Users = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState();
  const [rowValue, setRowValue] = useState();
  const [allUserRoleData, setAllUserRoleData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [isEditData, setIsEditData] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [imageObj, setImageObj] = useState();
  const [showPrePage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [userRoleFilterFieldValue, setUserRoleFilterFieldValue] = useState("");
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [emailFilterValue, setEmailFilterValue] = useState("");
  const [mobileFilterValue, setMobileFilterValue] = useState("");
  const [statusFilterValue, setStatusFilterValue] = useState("all");
  const [allRoleData, setAllRoleData] = useState([]);
  const [allDistData, setAllDistData] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCenterData, setAllCenterData] = useState([]);
  const [district, setDistrict] = useState("");
  const [file, setFile] = useState();
  const [loaderUpload, setLoaderUpload] = useState(false);
  const fileInputRef = useRef(null);

  const handleFile = async (e) => {
    // setData(URL.createObjectURL(file));
    setData(URL.createObjectURL(e.target.files[0]));
    let base64 = await convertBase64(e.target.files[0]);
    base64 = base64.split(",")[1];
    // const imageObj = {
    //   name: e.target.files[0].name,
    //   contentType: e.target.files[0].type,
    //   file: base64,
    // };
    setImageObj(base64);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  useEffect(() => {
    getUserRole();
    getUsers();
    getAllDistrict();
    // getAllCenter();
  }, []);
  // const getUserRole = async () => {
  //   const { data } = await DataService.GetUserRoles();
  //   console.log("data::: ", data.userRole);
  //   setAllUserRoleData(data.userRole);
  // };
  const getUsers = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllUser();
      console.log("data", data);
      if (data.message == "Success") {
        const reverse = data?.User?.reverse();
        setUserData(reverse);
        setFilteredData(reverse);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const getAllCenter = async (id) => {
    console.log("id::: ", id);
    setAllCenterData([]);
    setDataLoader(true);

    try {
      const { data } = await DataService.GetAllCenter();
      console.log("GetAllCenter data::: ", data);

      if (data.message == "Success") {
        const filter = data?.data.filter((data) => data.district[0]._id == id);
        console.log("filter::: ", filter);
        const filterList = [];
        await filter?.map((data) => {
          filterList.push({ value: data._id, label: data.school_name });
        });
        console.log("filterList::: ", filterList);
        setAllCenterData(filterList);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const getUserRole = async () => {
    try {
      const { data } = await DataService.GetAllRole();
      console.log("data", data);
      if (data.message == "Success") {
        const sortedUsers = data?.data?.sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
          if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
          return 0;
        });
        setAllRoleData(sortedUsers);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const getAllDistrict = async () => {
    try {
      const { data } = await DataService.GetAllDistrict();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllDistData(data.data);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const theading = [
    "ID",
    "FIRST NAME",
    "LAST NAME",
    "USERID",
    "EMAIL",
    "DISTRICT",
    "MOBILE",
    "ROLE",
    "STATUS",
  ];
  const tdata = [
    [
      10133,
      "Invigilator",
      "Nitesh",
      "Kumar",
      "nekolkata@gmail.com",
      "9143060898",
    ],
  ];

  const downloadHeaders = [
    ["ID", "USER ROLE", "FIRST NAME", "LAST NAME", "EMAIL", "MOBILE", "STATUS"],
  ];
  const LoginSchema = Yup.object().shape({
    first_name: Yup.string().required("required"),
    role: Yup.string().required("required"),
    last_name: Yup.string().required("required"),
    district: Yup.string().required("required"),
    userId: Yup.string().required("required"),
  });
  const handleSubmit = async (item) => {
    setLoading(true);
    try {
      const { data } = await DataService.AddUserData(item);
      if (data?.status_code == 200) {
        toast.success(data?.message);
        setModal(false);
      } else {
        setModal(false);
        toast.error(data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getUsers();
    setLoading(false);
  };
  const updateUserRole = async (item) => {
    setRowValue(item);
    setModal(true);
  };
  const updateUserData = async (data) => {
    console.log("data", rowValue._id, data);

    if (data?.password == "") {
      delete data?.password;
    }
    console.log("data::: ", data);

    setLoading(true);
    try {
      const { data: res } = await DataService.UpdateUserData(
        rowValue._id,
        data
      );
      console.log("res::: ", res);
      if (res?.message == "Success") {
        toast.success(res?.message);
        setModal(false);
      } else {
        setModal(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getUsers();
    setLoading(false);
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteUserData(item._id);
    if (res.data.message == "Success") {
      alert("True");
      // getUsers();
    } else {
      alert("False");
    }
  };
  const userRole = (data) => {
    const result = allUserRoleData.filter((item) => item._id == data);
    return result[0]?.role_name;
  };
  const clearFilter = () => {
    setUserRoleFilterFieldValue("");
    setNameFilterValue("");
    setEmailFilterValue("");
    setEmailFilterValue("");
    setMobileFilterValue("");
    setStatusFilterValue("all");
    setFilteredData(userData);
  };
  const downloadData = userData?.map((elt, i) => [
    i,
    userRole(elt.role_id),
    elt.first_name,
    elt.last_name,
    elt.email,
    elt.mobile,
    elt.status,
  ]);

  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    {
      console.log("userData", userData);
    }
    const filtered = userData?.filter((item) => {
      return item?.userId?.toLowerCase().includes(searchQuery?.toLowerCase());
    });
    setFilteredData(filtered);
  };

  console.log("district::: ", district);

  const handelUploadExcel = async () => {
    console.log("file", file);
    setLoaderUpload(true);
    const formData = new FormData();
    formData.append("excel", file);
    if (file) {
      try {
        const { data: res } = await DataService.UploadUser(formData);
        console.log("res::: ", res);
        if (res?.status_code == 200) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
        getUsers();
      } catch (error) {
        console.log("error::: ", error);
      }

      fileInputRef.current.value = null;
    } else {
      toast.error("Please select file!");
      setLoaderUpload(false);
    }
    setLoaderUpload(false);
    setLoading(false);
  };

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  User Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setRowValue();
                setViewData(false);
                setData("");
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />{" "}
              <span className="px-2">Add new</span>
            </Button>
            {/* <Button className="shadow-none" disabled>
              Export to
            </Button> */}
            {/* <Button
              className="p-0"
              onClick={() => tablePdf("user", downloadHeaders, downloadData)}
            >
              <img width={20} src={PDF} alt="" />
            </Button> */}
            {/* <Button
              className="p-0"
              onClick={() => exportExcel("user", downloadHeaders, downloadData)}
            >
              <img src={XLS} width={20} alt="" />
            </Button> */}
          </Col>
        </Row>
      </Container>

      {/* upload */}

      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="py-2 px-3 mb-3 bg-light">
                <h3 className="mb-0">Import Csv Data</h3>
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Upload file</Label>
                      <div
                        className="border border-2 py-1 px-2 rounded"
                        style={{ width: 300 }}
                      >
                        <Input
                          type="file"
                          name="upload"
                          id="upload"
                          innerRef={fileInputRef}
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                    </FormGroup>
                    <Button>
                      <a href={SampleFile} className="mt-4">
                        Download Sample file
                      </a>
                    </Button>
                  </Col>
                  <Col xl="6">
                    <div className="btn-container d-flex align-items-end h-100">
                      <Button
                        disabled={loaderUpload}
                        onClick={() => {
                          handelUploadExcel();
                        }}
                      >
                        {loaderUpload ? "Loading..." : "Upload"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Page content */}
      <Container fluid>
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">User List</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
                showStatus
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>

                {dataLoader ? (
                  <tbody>
                    <tr>
                      <td colSpan={10}>
                        <h5 className="text-center">Loading...</h5>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {filteredData?.length > 0 ? (
                      <>
                        {filteredData
                          ?.slice(pagination.start, pagination.end)
                          ?.map((data, idx) => (
                            <tr key={idx}>
                              <td>{pagination.start + idx + 1}</td>

                              <td>{data?.first_name || "-"}</td>
                              <td>{data?.last_name || "-"}</td>
                              <td>{data?.userId || "-"}</td>
                              <td>{data?.email || "-"}</td>
                              <td>{data?.district?.name || "-"}</td>
                              <td>{data?.phone || "-"}</td>
                              <td>{data?.role?.title || "-"}</td>

                              <td>{data.status ? "ON" : "OFF" || "-"}</td>

                              {/* <td>
                        <Label className="custom-toggle custom-toggle-warning">
                          <Input
                            type="checkbox"
                            defaultChecked={data?.status}
                            disabled
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Off"
                            data-label-on="On"
                          />
                        </Label>
                      </td> */}

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() => {
                                        updateUserRole(data);
                                        setViewData(false);
                                        setIsEditData(false);
                                      }}
                                    >
                                      <i className="fas fa-pencil-alt" />
                                      Edit
                                    </DropdownItem>
                                    {/* <DropdownItem
                                href=""
                                onClick={() => {
                                  setModal(!modal);
                                  updateUserRole(data);
                                  setViewData(true);
                                }}
                              >
                                <i className="	fas fa-eye" />
                                View
                              </DropdownItem> */}
                                    {/* <DropdownItem
                                href=""
                                // onClick={(e) => e.preventDefault()}
                                onClick={() => deleteUserRole(data)}
                              >
                                <i className="fas fa-trash " />
                                Delete
                              </DropdownItem> */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={5}>
                            <h5 className="text-center">No Data Available</h5>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                )}
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <Pagination
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Formik
          initialValues={{
            first_name: rowValue ? rowValue?.first_name : "",
            last_name: rowValue ? rowValue?.last_name : "",
            role: rowValue ? rowValue?.role?._id : "",
            district: rowValue ? rowValue?.district?._id : "",
            email: rowValue ? rowValue?.email : "",
            phone: rowValue ? rowValue?.phone : "",
            userId: rowValue ? rowValue?.userId : "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            isEditData ? handleSubmit(values) : updateUserData(values);
          }}
        >
          {({ touched, errors, setFieldValue }) => (
            <div>
              <Form>
                <Row>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        User Role <span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="role"
                        className={` form-control
                          ${touched.role && errors.role ? "is-invalid" : ""}`}
                      >
                        <option value="">Select role...</option>
                        {allRoleData?.map((item) => (
                          <>
                            <option value={item._id}>{item.title}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="role"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        District <span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="district"
                        value={district}
                        onChange={(e) => {
                          setFieldValue("district", e?.target?.value);
                          setDistrict(e?.target?.value);
                          getAllCenter(e?.target?.value);
                        }}
                        className={` form-control
                          ${
                            touched.district && errors.district
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select district...</option>
                        {allDistData?.map((item) => (
                          <>
                            <option value={item._id}>{item.name}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="district"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>

                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="first_name"
                        className={` form-control
                          ${
                            touched.first_name && errors.first_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="first_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="last_name"
                        className={` form-control
                          ${
                            touched.last_name && errors.last_name
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="last_name"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>
                        User Id <span className="text-danger">*</span>
                      </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="userId"
                        className={` form-control
                          ${
                            touched.userId && errors.userId ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="userId"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Email</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="email"
                        className={` form-control
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Phone</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="phone"
                        className={` form-control
                          ${touched.phone && errors.phone ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="phone"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label>Password </Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="password"
                        className={` form-control
                          ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="password"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col xl="6">
                    <FormGroup>
                      <Label>Password</Label>
                      <Field
                        disabled={viewData}
                        type="text"
                        name="pwd"
                        className={` form-control
                          ${touched.pwd && errors.pwd ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="pwd"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col> */}
                  {/* <Col xl="6">
                    <FormGroup>
                      <Label>User Profile Image</Label>
                      <Field
                        disabled={viewData}
                        type="file"
                        name="file"
                        onChange={(e) => handleFile(e)}
                        className={` form-control
                          ${touched.file && errors.file ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="file"
                        className="invalid-feedback"
                      />
                      {data && (
                        <img src={data} width={200} className="p-3" alt="" />
                      )}
                    </FormGroup>
                  </Col> */}

                  {/* <Col xl="6">
                    <Label className="d-block">Status</Label>
                    <Label className="custom-toggle custom-toggle-warning">
                      <input
                        disabled={viewData}
                        type="checkbox"
                        name="status"
                        onChange={(e) => {
                          setToggle(e.target.checked);
                        }}
                        defaultChecked={rowValue?.status}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Off"
                        data-label-on="On"
                      />
                    </Label>
                  </Col> */}
                </Row>
                {!viewData && (
                  <div className="btn-container">
                    <Button
                      type="submit"
                      className="w-100 bg-primary text-white"
                      disabled={loading}
                    >
                      {loading
                        ? "Loading..."
                        : isEditData
                        ? "Create"
                        : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default Users;
