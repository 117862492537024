import axios from "axios";
let AuthToken = sessionStorage.getItem("token");
console.log("token::: ", AuthToken);
export const BASEURL = process.env.REACT_APP_API_ENDPOINT;

const Headers = {
  "Access-Control-Allow-Origin": "*",

  Authorization: `Bearer ${AuthToken}`,
};

const getValidPath = (BASEURL) => {
  let baseHost = "";
  const host = window.location.host;
  if (host.includes("www.")) {
    baseHost = "www.";
  }
  return `https://${baseHost}${BASEURL}/api/`;
};

const axiosInstance = axios.create({
  baseURL: getValidPath(BASEURL),
  headers: Headers,
});
export default axiosInstance;
