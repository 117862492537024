import PrivateRoute from "PrivateRoute";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

export default function App() {
  const isAuthenticated = () => {
    return !!sessionStorage.getItem("token");
  };
  return (
    <Switch>
      <PrivateRoute path="/admin" component={AdminLayout} />
      <Route path="/auth" component={AuthLayout} />
      {isAuthenticated() ? (
        <Redirect from="*" to="/admin/index" />
      ) : (
        <Redirect from="*" to="/auth/login" />
      )}
    </Switch>
  );
}
