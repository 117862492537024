import {
  Card,
  CardHeader,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
  CardBody,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as FileSaver from "file-saver";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useRef, useState } from "react";
import CustomTable from "components/table/CustomTable";
import { useQuery } from "react-query";
import DataService from "../../../services/requestApi";
import Pagination from "../Pagination";
import { exportExcel } from ".././downloadService/DownloadButtons";
import SampleFile from "../../../assets/samplefile/sample_center.xlsx";
import { toast } from "react-toastify";
import Select from "react-select";
import * as XLSX from "xlsx";
import * as saveAs from "file-saver";
const Center = () => {
  const [modal, setModal] = useState(false);
  const [allCenterData, setAllCenterData] = useState([]);
  const [rowValue, setRowValue] = useState();
  const [isEditData, setIsEditData] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [showPrePage] = useState(10);
  const [viewData, setViewData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [loaderUpload, setLoaderUpload] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [allDistData, setAllDistData] = useState([]);
  const [allUserData, setUserData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const fileInputRef = useRef(null);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const downloadFile = () => {
    const url = SampleFile;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "center.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getAllCenter();
    getAllDistrict();
    getAllUser();
  }, []);

  const getAllDistrict = async () => {
    try {
      const { data } = await DataService.GetAllDistrict();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllDistData(data.data);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const getAllCenter = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllCenter();
      console.log("GetAllCenter data::: ", data);
      if (data.message == "Success") {
        const reverse = data?.data?.reverse();
        setAllCenterData(reverse);
        setFilteredData(reverse);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const getAllUser = async () => {
    setDataLoader(true);

    try {
      const { data } = await DataService.GetAllUser();
      console.log("GetAllCenter data::: ", data);

      if (data.message == "Success") {
        console.log("data?.User::: ", data?.User);
        const filterList = [];
        await data?.User?.map((data) => {
          filterList.push({
            value: data._id,
            label: `${data?.first_name} ${data?.last_name} (${data?.userId})`,
          });
        });
        console.log("filterList::: ", filterList);
        setUserData(filterList);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const handleSubmit = async (data) => {
    const selectedValues = selectedOption
      ? selectedOption.map((opt) => opt.value)
      : [];
    const dto = { ...data, user: selectedValues || [] };
    console.log("data", dto);
    setLoading(true);
    try {
      const { data: res } = await DataService.AddNewCenter(dto);
      console.log("res::: ", res);
      if (res?.status_code == 200) {
        toast.success(res?.message);
        setModal(false);
        getAllCenter();
      } else {
        setModal(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setLoading(false);
  };

  const updateUserRole = async (item) => {
    setRowValue(item);
    setToggle(item?.status);
    setModal(true);
    console.log("item::: ", item);
    const filterList = [];
    await item?.user?.map((data) => {
      filterList.push({
        value: data?._id,
        label: `${data?.first_name} ${data?.last_name} (${data?.userId})`,
      });
    });
    console.log("filterList::: ", filterList);
    setSelectedOption(filterList);
  };
  const updateCenterData = async (item) => {
    console.log("rowValue._id", rowValue._id);
    console.log("item::: ", item);

    const selectedValues = selectedOption
      ? selectedOption.map((opt) => opt.value)
      : [];
    const dto = { ...item, user: selectedValues || [] };
    console.log("dto::: ", dto);

    setLoading(true);
    try {
      const { data: res } = await DataService.UpdateCenterData(
        rowValue._id,
        dto
      );
      console.log("res::: ", res);
      if (res?.status_code == 200) {
        toast.success(res?.message);
        setModal(false);
      } else {
        setModal(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setSelectedOption(null);
    getAllCenter();
    setLoading(false);
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteUserRoleData(item._id);
    if (res.data.message == "Success") {
      alert("True");
    } else {
      alert("False");
    }
  };
  const centerSchema = Yup.object().shape({
    district: Yup.string().required("required"),
    school_name: Yup.string().required("required"),
    school_address: Yup.string().required("required"),
    principal_name: Yup.string().required("required"),
    principal_mobile: Yup.string().required("required"),
    principal_email: Yup.string().required("required").email("invalid email"),
    exam_center_grade: Yup.string().required("required"),
    num_physical_rooms: Yup.string().required("required"),
    total_exam_rooms: Yup.string().required("required"),
    total_seating_capacity: Yup.string().required("required"),
    total_teachers: Yup.string().required("required"),
    exam_experience: Yup.string().required("required"),
    center_within_city: Yup.string().required("required"),
    // transport_condition: Yup.string().required("required"),
    // center_condition: Yup.string().required("required"),
    // furniture_and_devices: Yup.string().required("required"),
    active_cctvs: Yup.string().required("required"),
    no_adverse_fact: Yup.string().required("required"),
    // extra_remark: Yup.string().required("required"),
  });
  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = allCenterData?.filter((item) => {
      return item?.school_name
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
    });
    setFilteredData(filtered);
  };

  const handelUploadExcel = async () => {
    console.log("file", file);
    setLoaderUpload(true);
    const formData = new FormData();
    formData.append("excel", file);
    try {
      if (file) {
        const { data: res } = await DataService.UploadCenter(formData);
        console.log("res::: ", res);
        if (res?.status_code == 200) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
        getAllCenter();
        setLoaderUpload(false);
        setLoading(false);
        fileInputRef.current.value = null;
      } else {
        toast.error("Please select file!");
        setLoaderUpload(false);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";

  function downloadAsExcel() {
    console.log("allCenterData::: ", allCenterData);
    const filterList = allCenterData?.map((school) => ({
      school_name: school.school_name,
      school_address: school.school_address,
      principal_name: school.principal_name,
      principal_mobile: school.principal_mobile,
      principal_email: school.principal_email,
      exam_center_grade: school.exam_center_grade,
      num_physical_rooms: school.num_physical_rooms,
      total_exam_rooms: school.total_exam_rooms,
      total_seating_capacity: school.total_seating_capacity,
      total_teachers: school.total_teachers,
      exam_experience: school.exam_experience,
      center_within_city: school.center_within_city,
      district_name: school.district[0].name,
      active_cctvs: school.active_cctvs,
      no_adverse_fact: school.no_adverse_fact,
      user: school?.user.map((item) => item.userId + ",").join(""),
      verificationStatus: school.verificationStatus,
    }));
    console.log("filterList::: ", filterList);
    const worksheet = XLSX.utils.json_to_sheet(filterList);

    const workbook = {
      Sheets: {
        data: worksheet,
      },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAsExcel(excelBuffer, "Center");
  }

  function saveAsExcel(buffer, filename) {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, filename + EXCEL_EXTENSION);
  }

  console.log("filteredData::: ", filteredData);
  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Center
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setViewData(false);
                setRowValue();
                setSelectedOption(null);
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />{" "}
              <span className="px-2">Add new</span>
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button className="p-0" onClick={() => downloadAsExcel()}>
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>

      {/* upload */}

      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="py-2 px-3 mb-3 bg-light">
                <h3 className="mb-0">Import Csv Data</h3>
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <Col xl="6">
                    <FormGroup className="">
                      <Label>Upload file</Label>
                      <div
                        className="border border-2 py-1 px-2 rounded"
                        style={{ width: 300 }}
                      >
                        <Input
                          type="file"
                          name="upload"
                          accept=".xlsx, .xls, .csv"
                          id="upload"
                          innerRef={fileInputRef}
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                    </FormGroup>
                    <Button onClick={downloadFile}>
                      {/* <a href={SampleFile} className="mt-4"> */}
                      Download Sample file
                      {/* </a> */}
                    </Button>
                  </Col>
                  <Col xl="6">
                    <div className="btn-container d-flex align-items-end h-100">
                      <Button
                        onClick={() => handelUploadExcel()}
                        disabled={loaderUpload}
                      >
                        {loaderUpload ? "Loading..." : "Upload"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Center</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>

              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">CENTER NAME</th>
                    <th scope="col">DISTRICT NAME</th>
                    <th scope="col">ASSIGN USER</th>

                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                {dataLoader ? (
                  <tbody>
                    <tr>
                      <td colSpan={5}>
                        <h5 className="text-center">Loading...</h5>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {filteredData?.length > 0 ? (
                      <>
                        {filteredData
                          ?.slice(pagination.start, pagination.end)
                          ?.map((data, idx) => (
                            <tr key={idx}>
                              <td>{pagination.start + idx + 1}</td>
                              <td>{data?.school_name || "-"}</td>
                              <td>
                                {" "}
                                {data?.district?.length == 0 && "-"}
                                {data?.district?.length > 0 &&
                                  data?.district?.map((item) => (
                                    <>{item?.name || "-"}</>
                                  ))}
                              </td>
                              <td>
                                {data?.user?.length == 0 && "-"}
                                {data?.user?.map((item) => (
                                  <>{item?.userId}, </>
                                )) || "-"}
                              </td>

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        updateUserRole(data);
                                        setIsEditData(false);
                                        setViewData(false);
                                      }}
                                    >
                                      <i className="fas fa-pencil-alt" />
                                      Edit
                                    </DropdownItem>
                                    {/* <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      setModal(!modal);
                                      updateUserRole(data);
                                      setViewData(true);
                                    }}
                                  >
                                    <i className="	fas fa-eye" />
                                    View
                                  </DropdownItem> */}
                                    {/* <DropdownItem
                                    href="#"
                                    // onClick={(e) => e.preventDefault()}
                                    onClick={() => deleteUserRole(data)}
                                  >
                                    <i className="fas fa-trash " />
                                    Delete
                                  </DropdownItem> */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={5}>
                            <h5 className="text-center">No Data Available</h5>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                )}
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <Pagination
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add Center"
      >
        <div
          style={{ height: "80vh", overflowY: "scroll", overflowX: "hidden" }}
        >
          <Formik
            initialValues={{
              school_name: rowValue?.school_name || "",
              school_address: rowValue?.school_address || "",
              principal_name: rowValue?.principal_name || "",
              principal_mobile: rowValue?.principal_mobile || "",
              principal_email: rowValue?.principal_email || "",
              exam_center_grade: rowValue?.exam_center_grade || "",
              num_physical_rooms: rowValue?.num_physical_rooms || "",
              total_exam_rooms: rowValue?.total_exam_rooms || "",
              total_seating_capacity: rowValue?.total_seating_capacity || "",
              total_teachers: rowValue?.total_teachers || "",
              exam_experience: rowValue?.exam_experience || "",
              center_within_city: rowValue?.center_within_city || "",
              centerCode: rowValue?.centerCode || "",
              district: rowValue?.district[0]?._id || "",
              //   transport_condition: rowValue?.transport_condition || "",
              //   center_condition: rowValue?.center_condition || "",
              //   furniture_and_devices: rowValue?.furniture_and_devices || "",
              active_cctvs: rowValue?.active_cctvs || "",
              no_adverse_fact: rowValue?.no_adverse_fact || "",
              //   extra_remark: rowValue?.extra_remark || "",
            }}
            // validationSchema={centerSchema}
            onSubmit={(values) => {
              isEditData ? handleSubmit(values) : updateCenterData(values);
            }}
          >
            {({ touched, errors }) => (
              <div>
                {/* ["ID", "DISTRICT NAME", "DISTRICT CODE", "STATUS"]; */}
                <Form>
                  <Row>
                    <Col xl="6">
                      <FormGroup>
                        <Label>District</Label>

                        <Field
                          disabled={viewData}
                          as="select"
                          type="text"
                          name="district"
                          className={`mt-2 form-control
                          ${
                            touched.district && errors.district
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select district...</option>
                          {allDistData?.map((item) => (
                            <>
                              <option value={item._id}>{item.name}</option>
                            </>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="district"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>School Name</Label>

                        <Field
                          disabled={viewData}
                          type="text"
                          name="school_name"
                          className={`mt-2 form-control
                          ${
                            touched.school_name && errors.school_name
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="school_name"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="12">
                      <FormGroup>
                        <Label>Center Code</Label>
                        <Field
                          disabled={viewData}
                          type="text"
                          name="centerCode"
                          className={`mt-2 form-control
                          ${
                            touched.centerCode && errors.centerCode
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="centerCode"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="12">
                      <FormGroup style={{ margin: "2px" }}>
                        <Label>Assign User</Label>

                        <Select
                          options={allUserData}
                          value={selectedOption}
                          onChange={handleChange}
                          isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <ErrorMessage
                          component="div"
                          name="district"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6" className="mt-2">
                      <FormGroup>
                        <Label>School Address</Label>

                        <Field
                          disabled={viewData}
                          type="text"
                          name="school_address"
                          className={`mt-2 form-control
                          ${
                            touched.school_address && errors.school_address
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="school_address"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6" className="mt-2">
                      <FormGroup>
                        <Label>Principal Name</Label>

                        <Field
                          disabled={viewData}
                          type="text"
                          name="principal_name"
                          className={`mt-2 form-control
                          ${
                            touched.principal_name && errors.principal_name
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="principal_name"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Principal Mobile</Label>

                        <Field
                          disabled={viewData}
                          type="number"
                          name="principal_mobile"
                          className={`mt-2 form-control
                          ${
                            touched.principal_mobile && errors.principal_mobile
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="principal_mobile"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Principal Email</Label>

                        <Field
                          disabled={viewData}
                          type="text"
                          name="principal_email"
                          className={`mt-2 form-control
                          ${
                            touched.principal_email && errors.principal_email
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="principal_email"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Exam Center Grade</Label>

                        <Field
                          disabled={viewData}
                          type="text"
                          name="exam_center_grade"
                          className={`mt-2 form-control
                          ${
                            touched.exam_center_grade &&
                            errors.exam_center_grade
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="exam_center_grade"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Num Physical Rooms</Label>

                        <Field
                          disabled={viewData}
                          type="number"
                          name="num_physical_rooms"
                          className={`mt-2 form-control
                          ${
                            touched.num_physical_rooms &&
                            errors.num_physical_rooms
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="num_physical_rooms"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Total exam rooms</Label>

                        <Field
                          disabled={viewData}
                          type="number"
                          name="total_exam_rooms"
                          className={`mt-2 form-control
                          ${
                            touched.total_exam_rooms && errors.total_exam_rooms
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="total_exam_rooms"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Total seating capacity</Label>

                        <Field
                          disabled={viewData}
                          type="number"
                          name="total_seating_capacity"
                          className={`mt-2 form-control
                          ${
                            touched.total_seating_capacity &&
                            errors.total_seating_capacity
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="total_seating_capacity"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Total teachers</Label>

                        <Field
                          disabled={viewData}
                          type="number"
                          name="total_teachers"
                          className={`mt-2 form-control
                          ${
                            touched.total_teachers && errors.total_teachers
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="total_teachers"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Exam experience</Label>

                        <Field
                          disabled={viewData}
                          type="text"
                          name="exam_experience"
                          className={`mt-2 form-control
                          ${
                            touched.exam_experience && errors.exam_experience
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="exam_experience"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <FormGroup>
                        <Label>Center within city</Label>

                        <Field
                          disabled={viewData}
                          type="text"
                          name="center_within_city"
                          className={`mt-2 form-control
                          ${
                            touched.center_within_city &&
                            errors.center_within_city
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="center_within_city"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col xl="6">
                      <FormGroup>
                        <Label>Transport condition</Label>
                        
                        <Field
                          disabled={viewData}
                          type="text"
                          name="transport_condition"
                          className={`mt-2 form-control
                          ${
                            touched.transport_condition &&
                            errors.transport_condition
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="transport_condition"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col> */}
                    {/* <Col xl="6">
                      <FormGroup>
                        <Label>Center condition</Label>
                        
                        <Field
                          disabled={viewData}
                          type="text"
                          name="center_condition"
                          className={`mt-2 form-control
                          ${
                            touched.center_condition && errors.center_condition
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="center_condition"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col> */}
                    {/* <Col xl="6">
                      <FormGroup>
                        <Label>Furniture and devices</Label>
                        
                        <Field
                          disabled={viewData}
                          type="text"
                          name="furniture_and_devices"
                          className={`mt-2 form-control
                          ${
                            touched.furniture_and_devices &&
                            errors.furniture_and_devices
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="furniture_and_devices"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col> */}
                    <Col xl="6">
                      <FormGroup>
                        <Label>Active cctvs</Label>

                        <Field
                          disabled={viewData}
                          type="number"
                          name="active_cctvs"
                          className={`mt-2 form-control
                          ${
                            touched.active_cctvs && errors.active_cctvs
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="active_cctvs"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6" className="mb-5">
                      <FormGroup>
                        <Label>No adverse facts</Label>

                        <Field
                          disabled={viewData}
                          type="text"
                          name="no_adverse_fact"
                          className={`mt-2 form-control
                          ${
                            touched.no_adverse_fact && errors.no_adverse_fact
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="no_adverse_fact"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col xl="6" className="mb-5">
                      <FormGroup>
                        <Label>Extra remark</Label>
                        
                        <Field
                          disabled={viewData}
                          type="text"
                          name="extra_remark"
                          className={`mt-2 form-control
                          ${
                            touched.extra_remark && errors.extra_remark
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="extra_remark"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      padding: "15px",
                      background: "white",
                      left: 0,
                    }}
                  >
                    {!viewData && (
                      <div className="btn-container">
                        <Button
                          type="submit"
                          className="w-100 bg-primary text-white"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Create"}
                        </Button>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </CustomModal>
    </>
  );
};

export default Center;
