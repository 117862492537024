import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
  CardBody,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
// core components
import { Link } from "react-router-dom";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useRef, useState } from "react";
import DataService from "../../../services/requestApi";
import Pagination from "../Pagination";
import SampleFile from "../../../assets/samplefile/DISTRICT CODES.xlsx";
import { toast } from "react-toastify";
import qs from "qs";

const EventList = () => {
  const [modal, setModal] = useState(false);
  const [allEventList, setAllEventList] = useState([]);
  const [rowValue, setRowValue] = useState();
  const [isEditData, setIsEditData] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [showPrePage] = useState(10);
  const [viewData, setViewData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);

  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const data = allEventList?.map((elt, i) => [i, elt.role_name]);

  useEffect(() => {
    getAllEvent();
  }, []);

  const getAllEvent = async () => {
    setDataLoader(true);
    try {
      const { data } = await DataService.GetAllEvent();
      console.log("data::: ", data);
      if (data.message == "Success") {
        setAllEventList(data.data);
        setFilteredData(data.data);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    setDataLoader(false);
  };

  const handleSubmit = async (data) => {
    console.log("data", data);

    const dto = {
      ...data,
      status: toggle,
    };
    console.log("dto::: ", dto);

    setLoading(true);
    try {
      const { data: res } = await DataService.AddNewEvent(dto);
      console.log("res::: ", res);
      if (res?.status_code == 200) {
        toast.success(res?.message);
        setModal(false);
        getAllEvent();
      } else {
        setModal(false);
        toast.error(res?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.log("error::: ", error);
    }

    setLoading(false);
  };

  const formatTimeEdit = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  const updateUserRole = (item) => {
    console.log("item::: ", item);
    const transformedObj = {
      _id: item?._id,
      eventType: item?.eventType,
      name: item?.name,
      eventSchedule: item?.eventSchedule.map((schedule) => ({
        date: schedule.date.split("T")[0], // Convert ISO date to YYYY-MM-DD format
        shifts: schedule.shifts.map((shift) => ({
          shiftNumber: shift.shiftNumber,
          startTime: formatTimeEdit(shift.startTime), // Convert startTime to 24-hour format
          endTime: formatTimeEdit(shift.endTime), // Convert endTime to 24-hour format
        })),
      })),
    };
    console.log("transformedObj::: ", transformedObj);
    console.log("item::: ", item);
    setRowValue(transformedObj);
    setToggle(item?.status);
    setModal(true);
  };
  const updateEventData = async (item) => {
    const dto = { ...item, status: toggle };
    console.log("dto::: ", dto);
    setLoading(true);
    try {
      const { data: res } = await DataService.UpdateEventData(
        rowValue._id,
        dto
      );
      console.log("res::: ", res);
      if (res?.status_code == 200) {
        toast.success(res?.message);
        setModal(false);
      } else {
        setModal(false);
        toast.error(res?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getAllEvent();
    setLoading(false);
  };

  const handleFilter = (event) => {
    const searchQuery = event.target.value;
    const filtered = allEventList?.filter((item) => {
      return item?.name?.toLowerCase().includes(searchQuery?.toLowerCase());
    });
    setFilteredData(filtered);
  };

  const deleteEvent = async (item) => {
    setLoading(true);
    try {
      const res = await DataService.DeleteEvent(item._id);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
      getAllEvent();
    } catch (error) {
      console.log("error::: ", error);
      toast.error(error?.response?.data?.error);
    }

    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    eventType: Yup.string().required("Event Type is required"),
    name: Yup.string().required("Event Name is required"),
    eventSchedule: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required("Date is required"),
        shifts: Yup.array().of(
          Yup.object().shape({
            shiftNumber: Yup.string().required("Shift Number is required"),
            startTime: Yup.string().required("Start Time is required"),
            endTime: Yup.string().required("End Time is required"),
          })
        ),
      })
    ),
  });

  function formatTime(time) {
    let [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12; // Convert hour to 12-hour format
    minutes = minutes < 10 ? `0${minutes}` : minutes; // Ensure two digits for minutes

    return `${hours}:${minutes} ${period}`;
  }

  function convertEventSchedule(event) {
    return {
      ...event,
      eventSchedule: event.eventSchedule.map((schedule) => ({
        ...schedule,
        shifts: schedule.shifts.map((shift) => ({
          shiftNumber: parseInt(shift?.shiftNumber),
          startTime: formatTime(shift.startTime),
          endTime: formatTime(shift.endTime),
        })),
      })),
    };
  }
  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Event
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Event List
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setViewData(false);
                setRowValue();
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />{" "}
              <span className="px-2">Add new</span>
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Event List</h3>
                <FormGroup className="mb-0" style={{ position: "relative" }}>
                  <Input
                    placeholder="Search"
                    onChange={handleFilter}
                    style={{ width: 250, borderRadius: 20 }}
                  />
                  <i
                    className="fas fa-search"
                    style={{ position: "absolute", right: 15, top: 12 }}
                  />
                </FormGroup>
              </CardHeader>

              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Event Type</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                {dataLoader ? (
                  <tbody>
                    <tr>
                      <td colSpan={5}>
                        <h5 className="text-center">Loading...</h5>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {filteredData?.length > 0 ? (
                      <>
                        {filteredData
                          ?.slice(pagination.start, pagination.end)
                          ?.map((data, idx) => (
                            <tr key={idx}>
                              <td>{pagination.start + idx + 1}</td>
                              <td>{data?.eventType || "-"}</td>
                              <td>{data?.name || "-"}</td>
                              <td>{data.status ? "ON" : "OFF" || "-"}</td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href="#"
                                      onClick={() => {
                                        updateUserRole(data);
                                        setIsEditData(false);
                                        setViewData(false);
                                      }}
                                    >
                                      <i className="fas fa-pencil-alt" />
                                      Edit
                                    </DropdownItem>
                                    {/* <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      setModal(!modal);
                                      updateUserRole(data);
                                      setViewData(true);
                                    }}
                                  >
                                    <i className="	fas fa-eye" />
                                    View
                                  </DropdownItem> */}
                                    <DropdownItem
                                      href="#"
                                      // onClick={(e) => e.preventDefault()}
                                      onClick={() => deleteEvent(data)}
                                    >
                                      <i className="fas fa-trash " />
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={5}>
                            <h5 className="text-center">No Data Available</h5>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                )}
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <Pagination
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add New Event"
      >
        <Formik
          initialValues={{
            eventType: rowValue?.eventType || "",
            name: rowValue?.name || "",
            eventSchedule: rowValue?.eventSchedule || [
              {
                date: "",
                shifts: [
                  {
                    shiftNumber: "",
                    startTime: "",
                    endTime: "",
                  },
                ],
              },
            ],
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const finalData = convertEventSchedule(values);
            isEditData ? handleSubmit(finalData) : updateEventData(finalData);
          }}
        >
          {({ touched, errors, values }) => (
            <div>
              <Form>
                <FormGroup>
                  <Label for="eventType">Event Type</Label>
                  <span className="text-danger">*</span>
                  <Field
                    as="select"
                    name="eventType"
                    className={`form-control ${
                      touched.eventType && errors.eventType ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Select</option>
                    {["omr", "cbt", "offline"].map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="eventType"
                    className="invalid-feedback"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="name">Event Name</Label>
                  <span className="text-danger">*</span>
                  <Field
                    disabled={viewData}
                    type="text"
                    name="name"
                    className={`form-control ${
                      touched.name && errors.name ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="name"
                    className="invalid-feedback"
                  />
                </FormGroup>

                <FieldArray name="eventSchedule">
                  {({ push, remove }) => (
                    <div>
                      {values.eventSchedule.map((schedule, scheduleIndex) => (
                        <div key={scheduleIndex} className="border p-2 mb-2">
                          <div className="d-flex justify-content-between ">
                            {scheduleIndex + 1}
                            <Button
                              type="button"
                              color="danger"
                              onClick={() => remove(scheduleIndex)}
                            >
                              Remove Event
                            </Button>
                          </div>
                          <FormGroup>
                            <Label for={`eventSchedule.${scheduleIndex}.date`}>
                              Date
                            </Label>
                            <Field
                              disabled={viewData}
                              type="date"
                              name={`eventSchedule.${scheduleIndex}.date`}
                              className={`form-control ${
                                touched?.eventSchedule?.[scheduleIndex]?.date &&
                                errors?.eventSchedule?.[scheduleIndex]?.date
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name={`eventSchedule.${scheduleIndex}.date`}
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          <FieldArray
                            name={`eventSchedule.${scheduleIndex}.shifts`}
                          >
                            {({ push: pushShift, remove: removeShift }) => (
                              <div className="border p-2">
                                {schedule.shifts.map((shift, shiftIndex) => (
                                  <div key={shiftIndex}>
                                    <div className="d-flex justify-content-between">
                                      {shiftIndex + 1}
                                      <Button
                                        type="button"
                                        color="danger"
                                        onClick={() => removeShift(shiftIndex)}
                                      >
                                        Remove Shift
                                      </Button>
                                    </div>
                                    <FormGroup>
                                      <Label
                                        for={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.shiftNumber`}
                                      >
                                        Shift Number
                                      </Label>
                                      <Field
                                        as="select"
                                        name={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.shiftNumber`}
                                        className={`form-control ${
                                          touched?.eventSchedule?.[
                                            scheduleIndex
                                          ].shifts?.[shiftIndex].shiftNumber &&
                                          errors?.eventSchedule?.[scheduleIndex]
                                            .shifts?.[shiftIndex].shiftNumber
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                      >
                                        <option value="">Select</option>
                                        {[
                                          { value: 1, label: "Morning" },
                                          { value: 2, label: "Afternoon" },
                                        ].map((item) => (
                                          <option
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        component="div"
                                        name={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.shiftNumber`}
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                                    <FormGroup>
                                      <Label
                                        for={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.startTime`}
                                      >
                                        Start Time
                                      </Label>
                                      <Field
                                        type="time"
                                        name={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.startTime`}
                                        className={`form-control ${
                                          touched?.eventSchedule?.[
                                            scheduleIndex
                                          ].shifts?.[shiftIndex].startTime &&
                                          errors?.eventSchedule?.[scheduleIndex]
                                            .shifts?.[shiftIndex].startTime
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.startTime`}
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                                    <FormGroup>
                                      <Label
                                        for={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.endTime`}
                                      >
                                        End Time
                                      </Label>
                                      <Field
                                        type="time"
                                        name={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.endTime`}
                                        className={`form-control ${
                                          touched?.eventSchedule?.[
                                            scheduleIndex
                                          ].shifts?.[shiftIndex].endTime &&
                                          errors?.eventSchedule?.[scheduleIndex]
                                            .shifts?.[shiftIndex].endTime
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name={`eventSchedule.${scheduleIndex}.shifts.${shiftIndex}.endTime`}
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                ))}
                                <div className="d-flex justify-content-end my-2">
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      pushShift({
                                        shiftNumber: "",
                                        startTime: "",
                                        endTime: "",
                                      })
                                    }
                                  >
                                    Add Shift
                                  </Button>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      ))}

                      <Button
                        type="button"
                        style={{ width: "100%" }}
                        onClick={() =>
                          push({
                            date: "",
                            shifts: [
                              { shiftNumber: "", startTime: "", endTime: "" },
                            ],
                          })
                        }
                      >
                        Add Schedule
                      </Button>
                    </div>
                  )}
                </FieldArray>

                <FormGroup>
                  <Label className="d-block">Status</Label>
                  <Label className="custom-toggle custom-toggle-warning">
                    <Input
                      disabled={viewData}
                      type="checkbox"
                      name="status"
                      onChange={(e) => setToggle(e.target.checked)}
                      checked={toggle}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="Off"
                      data-label-on="On"
                    />
                  </Label>
                </FormGroup>

                <div className="btn-container">
                  <Button
                    type="submit"
                    className="w-100 bg-primary text-white"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Create"}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default EventList;
