import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// core components
import { Link } from "react-router-dom";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useEffect, useState } from "react";
import CustomTable from "components/table/CustomTable";
import DataService from "../../../services/requestApi";
import moment from "moment";
import PaginationData from "../Pagination";
import { exportExcel, tablePdf } from ".././downloadService/DownloadButtons";

const CenterInvigilator = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [rowValue, setRowValue] = useState();
  const [allUserRoleData, setAllUserRoleData] = useState([]);
  const [isEditData, setIsEditData] = useState(false);
  const [users, setUsers] = useState([]);
  const [examCenter, setExamCenter] = useState([]);
  const [examDepartment, setExamDepartment] = useState([]);
  const [examCenterInvigilatorAllocation, setExamCenterInvigilatorAllocation] =
    useState([]);
  const [showPrePage] = useState(10);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const [selectMobile, setSelectMobile] = useState("");
  const [selectUser, setSelectUser] = useState("");
  const [selectExamCenter, setSelectExamCenter] = useState("");
  const [selectExamCategory, setSelectExamCategory] = useState("");
  const [selectDate, setSelectDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getUsers();
    getExamCenter();
    getExamDepartment();
    getExamCenterInvigilatorAllocation();
  }, []);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const theading = [
    "ID",
    "	USER",
    "MOBILE",
    "EXAM CENTER",
    "EXAM CATEGORY",
    "EXAM DATE",
  ];
  const tdata = [
    [
      45,
      "Somenath",
      "8420595144",
      "GOVERNMENT COLLEGE OF ENGINEERING & LEATHER TECHNOLOGY",
      "NWDA",
      "	21-05-2023",
    ],
  ];
  const LoginSchema = Yup.object().shape({
    user_id: Yup.string().required("required"),
    exam_center_id: Yup.string().required("required"),
    exam_category_id: Yup.string().required("required"),
    exam_date: Yup.string().required("required"),
  });
  const getUsers = async () => {
    const { data } = await DataService.GetUsers();
    setUsers(data.users);
  };
  const getExamCenter = async () => {
    const { data } = await DataService.GetExamCenter();
    setExamCenter(data.examCenter);
  };
  const getExamDepartment = async () => {
    const { data } = await DataService.GetExamDepartment();
    setExamDepartment(data.examDepartment);
  };
  const handleSubmit = async (data) => {
    const res = await DataService.CreateExamCenterInvigilatorAllocation(data);
    console.log("res::: ", res);
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getExamCenterInvigilatorAllocation();
    } else {
      alert("False");
    }
  };
  const getExamCenterInvigilatorAllocation = async () => {
    const { data } = await DataService.GetExamCenterInvigilatorAllocation();
    setExamCenterInvigilatorAllocation(data.examCenterInvigilatorAllocation);
    setFilteredData(data.examCenterInvigilatorAllocation);
  };
  const exam_category = (data) => {
    const result = examDepartment.filter((item) => item._id == data);
    return result[0]?.title;
  };
  const examCenterData = (data) => {
    const result = examCenter.filter((item) => item._id == data);
    return result[0]?.name;
  };
  const mobileNO = (data) => {
    const result = users.filter((item) => item._id == data);
    return result[0]?.mobile;
  };
  const userName = (data) => {
    const result = users.filter((item) => item._id == data);
    return result[0]?.first_name;
  };
  const updateUserRole = (item) => {
    setRowValue(item);
    setModal(true);
  };
  const updateUserData = async (data) => {
    console.log("data::: ", data);
    const res = await DataService.UpdateExamCenterInvigilatorAllocation(
      rowValue._id,
      data
    );
    if (res.data.message == "Success") {
      alert("True");
      setModal(false);
      getExamCenterInvigilatorAllocation();
    } else {
      alert("False");
    }
  };
  const deleteUserRole = async (item) => {
    console.log("item::: ", item._id);
    const res = await DataService.DeleteExamCenterInvigilatorAllocation(
      item._id
    );
    if (res.data.message == "Success") {
      alert("True");
      getExamCenterInvigilatorAllocation();
    } else {
      alert("False");
    }
  };
  const applyFilter = () => {
    const filtered = examCenterInvigilatorAllocation.filter((item) => {
      return (
        selectExamCenterFilter(item) &&
        selectExamCategoryFilter(item) &&
        selectUserFilter(item) &&
        selectDateFilter(item) &&
        mobileFilter(item)
      );
    });
    setFilteredData(filtered);
  };
  const selectExamCenterFilter = (item) => {
    return (
      selectExamCenter === "" ||
      item.exam_center_id
        ?.toLowerCase()
        .includes(selectExamCenter?.toLowerCase())
    );
  };
  const selectExamCategoryFilter = (item) => {
    return (
      selectExamCategory === "" ||
      item.exam_category_id
        ?.toLowerCase()
        .includes(selectExamCategory?.toLowerCase())
    );
  };
  const selectUserFilter = (item) => {
    console.log("item.user_id::: ", item.user_id, selectUser);
    return (
      selectUser === "" ||
      item.user_id?.toLowerCase().includes(selectUser?.toLowerCase())
    );
  };
  const selectDateFilter = (item) => {
    return (
      selectDate === "" ||
      moment(item?.exam_date)
        .format("DD-MM-YYYY")
        ?.toLowerCase()
        .includes(moment(selectDate).format("DD-MM-YYYY")?.toLowerCase())
    );
  };
  const mobileFilter = (item) => {
    const result = users.filter((item) => item.mobile == selectMobile);
    return (
      result[0]?._id === "" ||
      item.user_id?.toLowerCase().includes(result[0]?._id?.toLowerCase())
    );
  };
  const clearFilter = () => {
    setSelectMobile("");
    setSelectUser("");
    setSelectExamCenter("");
    setSelectExamCategory("");
    setSelectDate("");
    setFilteredData(examCenterInvigilatorAllocation);
  };
  const downloadData = filteredData?.map((elt, i) => [
    i + 1,
    userName(elt?.user_id) || "-",
    mobileNO(elt?.user_id) || "-",
    examCenterData(elt?.exam_center_id) || "-",
    exam_category(elt?.exam_category_id) || "-",
    moment(elt?.exam_date).format("DD-MM-YYYY") || "-",
  ]);
  const downloadHeaders = [
    ["ID", "USER", "MOBILE", "EXAM CENTER", "EXAM CATEGORY", "EXAM DATE	"],
  ];
  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Master
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Exam Cener Invigilator Allocation Listing
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
          <Col lg="4" className=" text-right">
            <Button
              className="p-1 d-inline-flex justify-content-center align-items-center"
              onClick={() => {
                setModal(!modal);
                setIsEditData(true);
                setViewData(false);
                setRowValue();
              }}
            >
              <i className="ni ni-fat-add" style={{ fontSize: 30 }} />
            </Button>
            <Button className="shadow-none" disabled>
              Export to
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                tablePdf(
                  "Exam Center Invigilator",
                  downloadHeaders,
                  downloadData
                )
              }
            >
              <img width={20} src={PDF} alt="" />
            </Button>
            <Button
              className="p-0"
              onClick={() =>
                exportExcel(
                  "Exam Center Invigilator",
                  downloadHeaders,
                  downloadData
                )
              }
            >
              <img src={XLS} width={20} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* filter */}
        <Card className="shadow p-3 mb-3">
          <Row>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">Mobile No.</Label>
                <Input
                  type="number"
                  placeholder="Mobile No."
                  value={selectMobile}
                  onChange={(e) => setSelectMobile(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-0">
                <Label>User</Label>
                <Input
                  name="select"
                  type="select"
                  value={selectUser}
                  onChange={(e) => setSelectUser(e.target.value)}
                >
                  <option value="">Select ...</option>
                  {examCenterInvigilatorAllocation?.map((item) => (
                    <>
                      <option value={item.user_id}>
                        {userName(item?.user_id)}
                      </option>
                    </>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-0">
                <Label>Exam Center</Label>
                <Input
                  name="select"
                  type="select"
                  value={selectExamCenter}
                  onChange={(e) => setSelectExamCenter(e.target.value)}
                >
                  {" "}
                  <option value="">Select ...</option>
                  {examCenterInvigilatorAllocation?.map((item) => (
                    <>
                      <option value={item.exam_center_id}>
                        {examCenterData(item?.exam_center_id)}
                      </option>
                    </>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-0">
                <Label>Exam Category</Label>
                <Input
                  name="select"
                  type="select"
                  value={selectExamCategory}
                  onChange={(e) => setSelectExamCategory(e.target.value)}
                >
                  <option value="">Select ...</option>
                  {examCenterInvigilatorAllocation?.map((item) => (
                    <>
                      <option value={item.exam_category_id}>
                        {exam_category(item?.exam_category_id)}
                      </option>
                    </>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xl="2">
              <FormGroup className="mb-2">
                <Label for="exampleSelect">Exam Date</Label>
                <Input
                  type="date"
                  placeholder="Date"
                  value={selectDate}
                  onChange={(e) => setSelectDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xl="2">
              <div className="btn-container d-flex align-items-end justify-content-end h-100">
                <Button onClick={applyFilter}>filter</Button>
                <Button onClick={clearFilter}>Clear</Button>
              </div>
            </Col>
          </Row>
        </Card>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="border-0">
                <h3 className="mb-0">Card tables</h3>
              </CardHeader>
              {/* <CustomTable
                tdata={tdata}
                theading={theading}
                modal={modal}
                setModal={setModal}
              /> */}
              <Table
                id="mainTable"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <FormGroup check inline>
                        <Input type="checkbox" />
                      </FormGroup>
                    </th>
                    {theading?.map((data, index) => (
                      <th scope="col" key={index}>
                        {data}
                      </th>
                    ))}
                    {/* <th scope="col" style={{ width: "70%" }}>
              Status
            </th> */}
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    filteredData
                      ?.slice(pagination.start, pagination.end)
                      ?.map((data, idx) => (
                        <tr key={idx}>
                          <th scope="row">
                            <FormGroup check inline>
                              <Input type="checkbox" />
                            </FormGroup>
                          </th>

                          <td>{idx + 1}</td>
                          <td>{userName(data?.user_id) || "-"}</td>
                          <td>{mobileNO(data?.user_id) || "-"}</td>
                          <td>{examCenterData(data?.exam_center_id) || "-"}</td>
                          <td>
                            {exam_category(data?.exam_category_id) || "-"}
                          </td>
                          <td>
                            {moment(data?.exam_date).format("YYYY-MM-DD") ||
                              "-"}
                          </td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    updateUserRole(data);
                                    setViewData(false);
                                    setIsEditData(false);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt" />
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  onClick={() => {
                                    setModal(!modal);
                                    updateUserRole(data);
                                    setViewData(true);
                                  }}
                                >
                                  <i className="	fas fa-eye" />
                                  View
                                </DropdownItem>
                                <DropdownItem
                                  href=""
                                  // onClick={(e) => e.preventDefault()}
                                  onClick={() => deleteUserRole(data)}
                                >
                                  <i className="fas fa-trash " />
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <h5 className="text-center">No Data Available</h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {filteredData?.length > 0 && (
                  <PaginationData
                    showPrePage={showPrePage}
                    onPaginationChange={onPaginationChange}
                    total={filteredData?.length}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal */}
      <CustomModal
        open={modal}
        toggleModal={() => setModal(!modal)}
        title="Add User"
      >
        <Formik
          initialValues={{
            user_id: rowValue ? rowValue?.user_id : "",
            exam_center_id: rowValue ? rowValue?.exam_center_id : "",
            exam_category_id: rowValue ? rowValue?.exam_category_id : "",
            exam_date: rowValue
              ? moment(rowValue?.exam_date).format("YYYY-MM-DD")
              : "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            console.log("values::: ", values);
            isEditData ? handleSubmit(values) : updateUserData(values);
          }}
        >
          {({ touched, errors }) => (
            <div>
              <Form>
                <Row>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        User<span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="user_id"
                        className={` form-control
                          ${
                            touched.user_id && errors.user_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select role...</option>
                        {users?.map((item) => (
                          <>
                            <option value={item._id}>{item.mobile}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="user_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Exam Center<span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="exam_center_id"
                        className={` form-control
                          ${
                            touched.exam_center_id && errors.exam_center_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select role...</option>
                        {examCenter?.map((item) => (
                          <>
                            <option value={item._id}>{item.name}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="exam_center_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Exam Category<span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        as="select"
                        type="text"
                        name="exam_category_id"
                        className={` form-control
                          ${
                            touched.exam_category_id && errors.exam_category_id
                              ? "is-invalid"
                              : ""
                          }`}
                      >
                        <option value="">Select role...</option>
                        {examDepartment?.map((item) => (
                          <>
                            <option value={item._id}>{item.title}</option>
                          </>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="exam_category_id"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <FormGroup>
                      <Label>
                        Exam Date<span className="text-danger">*</span>
                      </Label>

                      <Field
                        disabled={viewData}
                        type="date"
                        name="exam_date"
                        className={` form-control
                          ${
                            touched.exam_date && errors.exam_date
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="exam_date"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {!viewData && (
                  <div className="btn-container">
                    <Button
                      type="submit"
                      className="w-100 bg-primary text-white"
                    >
                      {isEditData ? "Create" : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default CenterInvigilator;
