import React from "react";
import XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";

const exportExcel = async (fileName, headers, data) => {
  console.log("data::: ", data);
  console.log("fileName::: ", fileName);
  var workbook = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.sheet_add_aoa(ws, headers);
  XLSX.utils.book_append_sheet(workbook, ws, "Results");
  XLSX.writeFile(workbook, `${fileName}.xlsx`, { type: "file" });
};
const tablePdf = (fileName, headers, data) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(fileName, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${fileName}.pdf`);
};

export { exportExcel, tablePdf };
