/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";
import GoogleIcon from "../../assets/img/icons/common/google.svg";
import GithubIcon from "../../assets/img/icons/common/github.svg";
import { useH, useHistory, useHistoryistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import DataService from "../../services/requestApi";
import axiosInstance from "services/http-common";
import ImageLogo from "../../assets/img/brand/logo.png";

const Login = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [dto, setDto] = useState({
    email: "",
    password: "",
  });

  const handelLogin = async () => {
    setLoader(true);
    try {
      if (dto.email && dto.password) {
        const { data } = await DataService.Login(dto);
        console.log("data::: ", data);
        if (data?.data?.status) {
          toast.success(data.message);
          axiosInstance.defaults.headers.Authorization = `Bearer ${data?.token}`;

          sessionStorage.setItem("token", data?.token);
          sessionStorage.setItem("user", data?.data?._id);
          history.push("/admin/index");
        } else {
          toast.error(data.message);
        }
      } else {
        toast.warning("Fill all data!");
      }
      setLoader(false);
    } catch (e) {
      console.log("e::: ", e);
    }
  };

  return (
    <>
      {/* <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img alt="..." src={GithubIcon} />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img alt="..." src={GoogleIcon} />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    onChange={(e) =>
                      setDto({ ...dto, [e.target.name]: e.target.value })
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    onChange={(e) =>
                      setDto({ ...dto, [e.target.name]: e.target.value })
                    }
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={() => {
                    handelLogin();
                  }}
                >
                  {loader ? (
                    <>
                      <Spinner color="light" size="sm" type="grow"></Spinner>
                      <span className="px-2">Loading...</span>
                    </>
                  ) : (
                    "Sign in"
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col> */}

      <Row
        className="border shadow"
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col md={6} className="mb-4 mb-md-0 p-0">
          <div
            className="d-flex flex-column justify-content-center bg-primary text-white p-4"
            style={{ height: "100vh" }}
          >
            <div
              className="d-flex justify-content-center "
              style={{ height: "100px" }}
            >
              <img height="120" width="120" className="" src={ImageLogo} />
            </div>
            <h1
              className="text-center mb-4 mt-4 text-white"
              style={{ fontSize: "30px" }}
            >
              <b>Welcome to SEAP!</b>
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                className="text-center mb-5 "
                style={{ width: "70%", fontSize: "20px" }}
              >
                <b>Your evaluations are secure and efficient.</b>
                <br />
                <b>Begin your journey now!</b>
              </p>
            </div>
            {/* <Button color="secondary" className="mx-auto" block>
                Already have an account? Sign in.
              </Button> */}
          </div>
        </Col>
        <Col md={6} className="px-5">
          <div className="d-flex flex-column justify-content-center p-4">
            <h2 className="text-center mb-4" style={{ fontSize: "30px" }}>
              Signup
            </h2>

            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                onChange={(e) =>
                  setDto({ ...dto, [e.target.name]: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Enter your password"
                onChange={(e) =>
                  setDto({ ...dto, [e.target.name]: e.target.value })
                }
              />
            </FormGroup>
            <Button
              className="my-4"
              style={{ width: "100%" }}
              color="primary"
              type="button"
              onClick={() => {
                handelLogin();
              }}
              disabled={loader}
            >
              {loader ? (
                <>
                  <Spinner color="light" size="sm" type="grow"></Spinner>
                  <span className="px-2">Loading...</span>
                </>
              ) : (
                "Sign in"
              )}
            </Button>
            {/* <p className="text-center mb-3">or signup with</p>
                <div className="d-flex justify-content-center">
                  <Button color="link" className="mx-2">
                    <i className="fab fa-facebook-f"></i>
                  </Button>
                  <Button color="link" className="mx-2">
                    <i className="fab fa-google-plus-g"></i>
                  </Button>
                  <Button color="link" className="mx-2">
                    <i className="fab fa-linkedin-in"></i>
                  </Button>
                </div> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Login;
