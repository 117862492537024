import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const CustomModal = ({ open, toggleModal, title, children }) => {
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </>
  );
};

export default CustomModal;
