import { Button, Container } from "reactstrap";
import * as XLSX from "xlsx";
import * as saveAs from "file-saver";
// core components

const Tables = () => {
  const data = [
    {
      Segment: "Government",
      Country: "Canada",
      Product: "Carretera",
      Discount: "None",
    },
    {
      Segment: "Government",
      Country: "Germany",
      Product: "Carretera",
      Discount: "None",
    },
    {
      Segment: "Midmarket",
      Country: "France",
      Product: "Carretera",
      Discount: "None",
    },
  ];

  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";

  function downloadAsExcel() {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = {
      Sheets: {
        data: worksheet,
      },
      SheetNames: ["data"],
    };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    console.log(excelBuffer);
    saveAsExcel(excelBuffer, "data_template");
  }

  function saveAsExcel(buffer, filename) {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, filename + EXCEL_EXTENSION);
  }
  return (
    <>
      {/* Page content */}
      <Container className="pt-5" fluid>
        <Button onClick={downloadAsExcel}>download</Button>
      </Container>
    </>
  );
};

export default Tables;
