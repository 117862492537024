import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataService from "../../../../services/requestApi";
import CustomModal from "components/modal/CustomModal";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function AllDistList({ showData }) {
  const [dataLoader, setDataLoader] = useState(false);
  const [allVarificationData, setAllVarificationData] = useState([]);
  const [modal, setModel] = useState(false);
  const [showImage, setShowImage] = useState(null);

  console.log("showData::: ", showData);

  useEffect(() => {
    getFormDetails();
  }, [showData?._id]);

  const getFormDetails = async () => {
    if (showData?._id) {
      setDataLoader(true);
      try {
        const { data } = await DataService.GetCenterVarificationData(
          showData?._id
        );

        if (data.message == "Success") {
          setAllVarificationData(data.data);
        }
      } catch (error) {
        console.log("error::: ", error);
      }

      setDataLoader(false);
    }
  };

  const handelApprove = async (fill, value, centerID, verificationId) => {
    const getUser = sessionStorage.getItem("user");
    const dto = {
      keyName: fill,
      approvedBy: getUser,
      newValue: value?.remark,
      center: centerID,
    };
    console.log("dto::: ", dto);
    try {
      const { data } = await DataService.ApproveRejectForm(verificationId, dto);

      if (data?.status_code == 200) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getFormDetails();
  };

  const handelReject = async (fill, value, centerID, verificationId) => {
    const getUser = sessionStorage.getItem("user");
    const dto = {
      keyName: fill,
      approvedBy: getUser,
    };
    console.log("dto::: ", dto);
    try {
      const { data } = await DataService.ApproveRejectForm(verificationId, dto);

      if (data?.status_code == 200) {
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log("error::: ", error);
    }

    getFormDetails();
  };

  const renderData = (obj, fillObj, centerID, row) => {
    const elements = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "object" && value.isVerified == "true") {
          elements.push(
            <Col xl="3" key={key} className="border">
              <FormGroup className="mt-2">
                {!value.condition && (
                  <>
                    <Label for={key} style={{ textTransform: "capitalize" }}>
                      {key.replace(/_/g, " ")}
                    </Label>{" "}
                    :
                    <span className="mx-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="green"
                        class="bi bi-patch-check-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                      </svg>
                    </span>
                    {fillObj[key] && !value.furniture_and_devices_options && (
                      <>
                        <Input
                          name={key}
                          type="text"
                          value={fillObj[key]}
                          disabled
                          style={{ color: "black" }}
                        />
                      </>
                    )}
                    {value.input && (
                      <>
                        <Label className="mt-2">Input</Label>
                        <Input
                          name={key}
                          type="text"
                          value={value?.input}
                          disabled
                          style={{ color: "black" }}
                        />
                      </>
                    )}
                    {value.remark && (
                      <div>
                        <hr className="m-1" />
                        <Label className="mt-2">Remark</Label>
                        <Input
                          name={key}
                          type="text"
                          value={value?.remark}
                          disabled
                          style={{ color: "black" }}
                        />
                      </div>
                    )}
                    {value?.furniture_and_devices_options &&
                      value?.furniture_and_devices_options
                        ?.replace(/\[|\]/g, "")
                        ?.split(/\([^)]*\)\s*,?\s*/)
                        ?.filter((item) => item.trim() !== "")
                        ?.map((item) => (
                          <>
                            <Input
                              name={item}
                              type="text"
                              className="mt-2"
                              value={item}
                              disabled
                              style={{ color: "black" }}
                            />
                          </>
                        ))}
                  </>
                )}

                {value.condition && (
                  <>
                    <Label for={key} className="mt-2">
                      condition
                    </Label>
                    {value?.condition
                      ?.slice(1, -1)
                      ?.split(", ")
                      ?.map((item) => (
                        <>
                          <Input
                            name={item}
                            type="text"
                            className="mt-2"
                            value={item}
                            disabled
                            style={{ color: "black" }}
                          />
                        </>
                      ))}
                  </>
                )}
              </FormGroup>
            </Col>
          );
        } else if (typeof value === "object" && !Array.isArray(value)) {
          elements.push(
            <Col
              xl="3"
              key={key}
              className={`${
                value.isVerified ? "border border-danger" : "border"
              }`}
            >
              {value.isVerified ? (
                <FormGroup className="mb-2">
                  <Label
                    for={key}
                    className="mt-2"
                    style={{ textTransform: "capitalize" }}
                  >
                    {key.replace(/_/g, " ")}
                  </Label>{" "}
                  :
                  <span className="mx-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="red"
                      class="bi bi-patch-plus-fill"
                      viewBox="0 0 16 16"
                      style={{ transform: `rotate(45deg)` }}
                    >
                      <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0" />
                    </svg>
                  </span>
                  <hr className="m-1" />
                  {fillObj[key] && (
                    <Input
                      name={key}
                      type="text"
                      value={fillObj[key]}
                      // value={value?.isVerified}
                      disabled
                      style={{ color: "black" }}
                    />
                  )}
                  {value.remark && (
                    <div>
                      <Label for={key} className="mt-2">
                        Remark
                      </Label>
                      <Input
                        name={key}
                        type="text"
                        value={value.remark}
                        disabled
                        style={{ color: "black" }}
                      />
                    </div>
                  )}
                  <div
                    className="mt-2"
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() =>
                        handelApprove(key, value, row?.center._id, row._id)
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      color="danger"
                      onClick={() =>
                        handelReject(key, value, row?.center._id, row._id)
                      }
                    >
                      Reject
                    </Button>
                  </div>
                </FormGroup>
              ) : (
                <>
                  <Label
                    for={key}
                    className="mt-2"
                    style={{ textTransform: "capitalize" }}
                  >
                    {key.replace(/_/g, " ")}
                  </Label>
                  <hr className="m-1" />
                  {Object.entries(value).map(([key, value], i) => (
                    <FormGroup className="mb-2">
                      <Label for={key} className="mt-2">
                        {key?.replace(/_/g, " ")}
                      </Label>
                      <Input
                        name={key}
                        type="text"
                        value={value}
                        disabled
                        style={{ color: "black" }}
                      />
                    </FormGroup>
                  ))}
                </>
              )}
            </Col>
          );
        } else {
          if (key == "principal_photo") {
            elements.push(
              <Col xl="4" key={key} className="mt-2">
                <Label for={key} style={{ textTransform: "capitalize" }}>
                  {key.replace(/_/g, " ")}
                </Label>
                <br />

                <>
                  <img
                    src={value}
                    height={70}
                    width={70}
                    className="img-thumbnail"
                    onClick={() => {
                      setModel(!modal);
                      setShowImage(value);
                    }}
                  />
                </>
              </Col>
            );
          }
          if (key == "extra_remark_photos" || key == "school_photos") {
            elements.push(
              <Col xl="4" key={key} className="mt-2">
                <Label for={key} style={{ textTransform: "capitalize" }}>
                  {key.replace(/_/g, " ")}
                </Label>
                <br />
                {value.map((item) => (
                  <>
                    <img
                      src={item}
                      height={70}
                      width={70}
                      className="mx-2 img-thumbnail"
                      onClick={() => {
                        setModel(!modal);
                        setShowImage(item);
                      }}
                    />
                  </>
                ))}
              </Col>
            );
          }
        }
      }
    }
    return elements;
  };

  return (
    <div>
      <Container fluid>
        {dataLoader ? (
          "Loading..."
        ) : allVarificationData.length > 0 ? (
          allVarificationData?.map((row) => (
            <>
              <Card className="mt-4">
                <CardHeader>
                  {row?.user.role?.title} : {row?.user?.userId}
                </CardHeader>

                <CardBody>
                  <Row>
                    {renderData(row?.centerData, row?.center, row?._id, row)}
                  </Row>
                </CardBody>
              </Card>
            </>
          ))
        ) : (
          <>No data capture</>
        )}
      </Container>

      <CustomModal
        open={modal}
        toggleModal={() => setModel(!modal)}
        title="Photos"
      >
        <img
          src={showImage}
          height={150}
          width={150}
          className="mx-2 img-thumbnail"
        />
      </CustomModal>
    </div>
  );
}
