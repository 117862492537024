import React, { useEffect, useState } from "react";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
export default function PaginationData({
  showPrePage,
  onPaginationChange,
  total,
}) {
  const [counter, setCounter] = useState(1);
  const [numberOfButton, setNumberOfButton] = useState(0);
  useEffect(() => {
    const value = showPrePage * counter;
    onPaginationChange(value - showPrePage, value);
    setNumberOfButton(Math.ceil(total / showPrePage));
  }, [counter, total]);
  const onButtonClick = (type) => {
    if (type === "p") {
      if (counter === 1) {
        setCounter(1);
      } else {
        setCounter(counter - 1);
      }
    } else if (type === "n") {
      if (numberOfButton === counter) {
        setCounter(counter);
      } else {
        setCounter(counter + 1);
      }
    }
  };
  return (
    <>
      {/* <div class="mt-4 d-flex justify-content-end mr-4">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <a
                class="page-link"
                onClick={() => setCounter(1)}
                style={{ color: "#e5b661" }}
              >
                <KeyboardDoubleArrowLeftIcon style={{ fontSize: "15px" }} />
              </a>
            </li>
            <li class="page-item">
              <a
                class="page-link"
                onClick={() => onButtonClick("p")}
                style={{ color: "#e5b661" }}
              >
                <KeyboardArrowLeftIcon style={{ fontSize: "15px" }} />
              </a>
            </li>

            {new Array(numberOfButton)?.fill("")?.map((item, index) => (
              <>
                <li
                  class={`page-item ${index + 1 === counter ? "active" : null}`}
                >
                  {(index + 1 === counter ||
                    index + 1 === counter - 1 ||
                    index + 1 === counter + 1) && (
                    <button
                      class="page-link"
                      onClick={() => setCounter(index + 1)}
                      style={{
                        backgroundColor: `${
                          index + 1 === counter ? "#e5b661" : "white"
                        }`,
                        color: `${index + 1 === counter ? "white" : "#e5b661"}`,
                      }}
                    >
                      {index + 1}
                    </button>
                  )}
                </li>
              </>
            ))}
            <li class="page-item">
              <a
                class="page-link"
                onClick={() => onButtonClick("n")}
                style={{ color: "#e5b661" }}
              >
                <KeyboardArrowRightIcon style={{ fontSize: "15px" }} />
              </a>
            </li>
            <li class="page-item">
              <a
                class="page-link"
                onClick={() => setCounter(numberOfButton)}
                style={{ color: "#e5b661" }}
              >
                <KeyboardDoubleArrowRightIcon style={{ fontSize: "15px" }} />
              </a>
            </li>
          </ul>
        </nav>
      </div> */}
      <nav aria-label="...">
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem>
            <PaginationLink onClick={() => onButtonClick("p")}>
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>

          {new Array(numberOfButton)?.fill("")?.map((item, index) => (
            <PaginationItem
              className={`page-item ${index + 1 === counter ? "active" : null}`}
            >
              {(index + 1 === counter ||
                index + 1 === counter - 1 ||
                index + 1 === counter + 1) && (
                <PaginationLink
                  class="page-link"
                  onClick={() => setCounter(index + 1)}
                >
                  {index + 1}
                </PaginationLink>
              )}
            </PaginationItem>
          ))}

          <PaginationItem>
            <PaginationLink onClick={() => onButtonClick("n")}>
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    </>
  );
}
