import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
  CardBody,
} from "reactstrap";
// core components
import { Link } from "react-router-dom";
import xlsx from "xlsx";
import PDF from "../../../assets/img/theme/pdf.png";
import XLS from "../../../assets/img/theme/xls.png";
import CustomModal from "components/modal/CustomModal";
import { useState } from "react";
import CustomTable from "components/table/CustomTable";
import { useQuery } from "react-query";
import DataService from "../../../services/requestApi";
import SampleFile from "../../../assets/samplefile/importuser_sample.xlsx";
const DataImport = () => {
  // const { data, isLoading, error, isError } = useQuery(
  //   "getRoles",
  //   DataService.GetRoles()
  // );
  // console.log(data, isLoading, error);
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        console.log(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  return (
    <>
      <Container fluid className="pt-5">
        <Row>
          <Col lg="8">
            <div className="bread mb-3 d-flex flex-column">
              <h6 className="fullcalendar-title h2 d-inline-block mb-0  ml-lg-3">
                Data Import
              </h6>
              <Breadcrumb className="d-none d-md-inline-block">
                <BreadcrumbItem>
                  <Link to="/admin/index">
                    <i className="fas fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  Data Import
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-5">
              <CardHeader className="py-2 px-3 mb-3 bg-light">
                <h3 className="mb-0">Import Csv Data</h3>
              </CardHeader>
              <CardBody className="p-3">
                <Form>
                  <Row>
                    <Col xl="6">
                      <FormGroup className="mb-0">
                        <Label>Upload file</Label>
                        <div
                          className="border border-2 py-1 px-2 rounded"
                          style={{ width: 300 }}
                        >
                          <Input
                            type="file"
                            name="upload"
                            id="upload"
                            onChange={readUploadFile}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="6">
                      <div className="btn-container d-flex align-items-end h-100">
                        <Button>Upload</Button>
                        <Button>Import Uploaded Data</Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card className="shadow">
              <CardHeader className="py-2 px-3 mb-3 bg-light">
                <h3 className="mb-0">Sample file</h3>
              </CardHeader>
              <CardBody className="p-4">
                <Button>
                  <a href={SampleFile}>Download Sample file</a>
                </Button>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DataImport;
